.content-block + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block,
.slide + .content-block {
    margin-top: calc(50rem/16);
    margin-bottom: calc(50rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(70rem/16);
        margin-bottom: calc(70rem/16);
    }

    @media screen and (min-width: 992px){
        margin-top: calc(115rem/16);
        margin-bottom: calc(115rem/16);
    }
}

.content-block-small + .content-block-small,
.pimcore_area_content + .pimcore_area_content > .content-block--small,
.slide + .content-block-small {
    margin-top: calc(35rem/16);
    margin-bottom: calc(35rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(60rem/16);
        margin-bottom: calc(60rem/16);
    }

}

.content-block-tvt {
    margin-bottom: calc(50rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(70rem/16);
    }

    @media screen and (min-width: 992px){
        margin-bottom: calc(115rem/16);
    }
}

.content-block-jobs {
    margin-top: calc(40rem/16);
    margin-bottom: calc(40rem/16);

@media screen and (min-width: 768px) {
    margin-top: calc(100rem/16);
    margin-bottom: calc(100rem/16);
}
}

.content-block-jobs .slick-dots {
    display:none !important;
}