.expandable__toggle{
    cursor: pointer;
}

.expandable__content{
    height: auto;
    max-height: calc(3*1em*1.6);
    overflow: hidden;
    transition: max-height 500ms ease-in-out;
}

.expandable__content.is-expanded{
    height: fit-content;
    max-height: calc(1000rem/16);
}

.expandable__icon-arrow-down{
    font-size: calc(14rem/16);
}

.is-expanded .expandable__icon-arrow-down{
    transform: rotate(180deg);
}

.is-expanded .expandable__toggle-more{
    display: none;
    visibility: hidden;
}

.expandable__toggle-less{
    display: none;
    visibility: hidden;
}

.is-expanded .expandable__toggle-less{
    display: inline;
    visibility: visible;
}



