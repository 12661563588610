.blog-content-wrapper{
  padding-top: calc(80rem/16);

  @media screen and (min-width: 768px){
    padding-top: calc(150rem/16);
  }
}

.page-headline{
  font-family: var(--font-black);
  text-transform: uppercase;
}

.page-headline.page-headline--small{
  font-size: calc(35rem/16);

  margin: calc(15rem/16) 0 calc(10rem/16);

  @media screen and (min-width: 768px){
    margin: calc(100rem/16) 0 calc(40rem/16);
    letter-spacing: calc(22.5rem/16);
    font-size: calc(60rem/16);
  }
}

.page-headline.page-headline--medium{
  font-size: calc(40rem/16);
  letter-spacing: calc(15rem/16);

  margin: calc(40rem/16) 0 calc(30rem/16);

  @media screen and (min-width: 768px){
    font-size: calc(90rem/16);
    letter-spacing: calc(33rem/16);
    margin: calc(70rem/16) 0 calc(40rem/16);
  }
}

.page-headline.page-headline--big{
  margin: calc(40rem/16) 0 calc(50rem/16);

  @media screen and (min-width: 768px){
     font-size: calc(120rem/16);
    margin: calc(30rem/16) 0 calc(90rem/16);
  }

  @media screen and (min-width: 992px){
    margin: calc(70rem/16) 0 calc(90rem/16);
  }
}

.event-top-bar,
.blog-top-bar{
  padding: calc(20rem/16) 0;

  @media screen and (min-width: 768px){
    padding: calc(30rem/16) 0;
  }
}

.event-top-bar .link-with-icon,
.blog-top-bar .link-with-icon{
  @media screen and (max-width: 767px){
    font-size: calc(14rem/16);
  }
}


.blog-heading{
  margin-bottom: calc(50rem/16);
  
  @media screen and (min-width: 768px){
     margin-bottom: calc(100rem/16);
  }
}
.blog-heading__info{
  margin: calc(30rem/16) 0;
  font-size: calc(15rem/16);
}

/* Archiv */

.blog-archiv-filter{
  margin: calc(50rem/16) 0 calc(80rem/16);

  @media screen and (min-width: 768px){
    margin: calc(100rem/16) 0 calc(80rem/16);
  }
}