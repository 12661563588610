.footer{
  color: #fff;
  text-transform: uppercase;
  font-size: calc(14rem/16);
  letter-spacing: calc(5.25rem/16);
  position: relative;
  z-index: 1;
}

.footer-main{
  padding: 0 calc(20rem/16) calc(25rem/16);

  @media screen and (min-width: 768px){
    padding: calc(22rem/16) calc(20rem/16) calc(70rem/16);
  }
}

.footer-main__claim{
  font-size: calc(15rem/16);
  font-family: var(--font-bold);
  margin-top: calc(32rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);

    }
}

.footer-bottom{
  background-color: #282829;
  padding: calc(15rem/16) calc(20rem/16) calc(10rem/16);

  @media screen and (min-width: 768px){
    padding: calc(44rem/16) calc(20rem/16) calc(22rem/16);
  }
}

.footer-main__social{
  margin-top: calc(25rem/16);

  @media screen and (min-width: 768px){
    margin-top: calc(60rem/16);
  }
}

.footer-main__elements{
  margin-top: 30px;
  @media screen and (min-width: 768px){
    margin-top: 50px;
  }
}

.footer-social-item{
  width: calc(60rem/16);
  height: calc(60rem/16);
  border-radius: 50%;
  position: relative;
  background-color: var(--color-darkgrey);
  font-size: calc(25rem/16);
}

.footer-social-item .icon{
  margin-left: calc(2rem/16);
}

.footer-main__social__links .list-inline-item:not(:last-child){
  margin-right: calc(30rem/16);
    
    @media screen and (min-width: 768px){
        margin-right: calc(10rem/16);

    }
    
    @media screen and (min-width: 1200px){
        margin-right: calc(14rem/16);
    }
}

.footer-main__social__links a:hover .footer-social-item{
  background-color: var(--color-primary);
}


.footer-bottom__links .list-inline-item:not(:last-child){
  margin-right: calc(95rem/16);
  
  @media screen and (max-width: 767px){
    margin-right: calc(20rem/16);
  }
}

.footer-bottom__links a{
  @media screen and (max-width: 767px){
     padding: calc(10rem/16) 0;
    display: inline-block;
  }
}

.footer-bottom__links a:hover{
  color: var(--color-primary);
}


.footer-main__logo{
  width: calc(85rem/16);
}

.footer .newsletter-slide__form .form-control{
  border-bottom-color: #fff;
  color: #fff;
}


.footer-main__newsletter-text{
    font-size: calc(16rem/16);
    font-family: var(--font-black);
    line-height: calc(24/16);

    @media screen and (min-width: 768px){
       padding-left: calc(20rem/16);
        max-width: calc(310rem/16);
        padding-right: calc(15rem/16);
    }
    @media screen and (min-width: 1200px){
        padding-left: calc(58rem/16);
        max-width: calc(310rem/16);
        padding-right: calc(15rem/16);
    }
    
    @media screen and (max-width: 767px){
       margin-top: calc(30rem/16);
    }
}
.footer-main__input{
    max-width: calc(405rem/16);
}

/* footer b2c */
.footer-b2c .footer-bottom {
    padding: calc(40rem/16) 0 calc(10rem/16) calc(15rem/16);

    @media screen and (min-width: 768px){
        padding: calc(70rem/16) calc(20rem/16) calc(22rem/16);
    }

}

.footer-b2c .footer-main__logo{
    width: calc(200rem/16);
    @media screen and (min-width: 768px){
       width: calc(260rem/16);
    }
}

.footer-b2c .footer-b2c__title {
    text-transform: uppercase;
    font-family: var(--font-bold);

    margin-bottom: .25rem;

    font-size: calc(14rem/16);
    text-align: left;
    padding: calc(33rem/16) calc(36rem/16) calc(12rem/16) calc(36rem/16) ;
    border-top: 2px solid var(--color-default);
    position: relative;

    @media screen and (min-width: 768px){
        margin-bottom: 1rem;
        text-align: left;

        font-size: 1.125rem;
        padding: 0.5rem 1rem;
        border-top: none;
    }
}

.footer-b2c .footer__title-collapse:after {
    @media screen and (max-width: 767px) {
        content: var(--icon-play);
        font-family: iconfont;
        color: #fff;
        font-size: calc(8rem/16);
        transform: rotate(270deg);
        transition: .15s ease-in-out;
        letter-spacing: 0;
        display: inline-block;
        position: absolute;
        right: calc(36rem/16);
        top: 50%;
        margin-top: 3px;
    }
}

.footer-b2c .footer__title-collapse.collapsed:after {
    @media screen and (max-width: 767px) {
        transform: rotate(90deg);
    }
}
.footer-b2c .footer__title-collapse--direct.collapsed:after {
    @media screen and (max-width: 767px) {
        transform: rotate(0deg);
    }
}

.footer-b2c .footer-b2c__list {
    @media screen and (max-width: 767px) {
        padding-bottom: calc(12rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-left: calc(20rem/16);
        padding-right: calc(20rem/16);
    }
}

.footer-b2c__item {
    display: block;

    text-align: left;
    font-size: calc(14rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: .5rem;

        font-size: .875rem;
    }
}

.footer-b2c__item a {
    display: inline-block;
    transition: opacity 100ms ease-in;
}

.footer-b2c__item a:hover {
    opacity: 0.7;
}

.footer-b2c__item a:not(.nav-link) {
    padding: 0.5rem 1rem;
    @media screen and (min-width: 768px){
        padding: 0.5rem 1rem;
    }
}
.footer-payment{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 calc(36rem/16) 1rem calc(36rem/16);
    justify-content: flex-start;

    @media screen and (min-width: 768px){
        padding: 0 1rem;
    }
}
.footer-payment__icon {
    width: 5rem;
    max-height: 2.5rem;
    padding: 0.5rem;
    margin-right:.5rem;
    margin-bottom:.5rem;
}

.footer-b2c .desktop-no-collapse.collapse {
    @media screen and (min-width: 768px) {
        display: block;
    }
}

.footer-b2c .footer-main__social{
    margin-top: 0;
}
.footer-main__brand{
    @media screen and (max-width: 767px){
        padding: 0 36px;
    }
}