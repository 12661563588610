.wishlist-teaser__body {
    padding: calc(24rem /16) calc(20rem /16) calc(60rem /16) calc(20rem /16);
}

.wishlist-teaser__link {
    margin-top: calc(24rem /16);
}

.wishlist__item-label {/*is used twice: inside and outside of modal*/
    font-family: var(--font-black);
    text-transform: uppercase;
}

.wishlist__remove-icon {
    vertical-align: text-bottom;
}

.wishlist-teaser__remove-item-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}