.tvt-modell-teaser {
    position: relative;
    padding: calc(50rem/16) 0;
}

.tvt-modell-teaser .tvt-modell-teaser__delete .btn {
    color: var(--color-grey);
    font-size: calc(30rem/16);
    padding: calc(10rem/16);
    position: absolute;
    right: calc(20rem/16);
    top: calc(20rem/16);
    z-index: 10;
}



.tvt-modell-teaser .product-teaser__img.tvt-modell-teaser__img img {
    @media screen and (min-width: 1024px){
        width: calc(400rem/16);
        height: calc(262rem/16);
    }
}

/*Add modell*/

.tvt-add-modell-teaser {
    min-height: 489px;
    @media screen and (max-width: 768px){
        min-height: 404px;
        padding: calc(45rem/16) calc(15rem/16);
    }
}

.tvt-add-modell-teaser:hover .tvt-add-modell-teaser__icon {
    transform: scale(1.15);
}

.tvt-add-modell-teaser__icon {
    font-size: calc(60rem/16);
    margin-bottom: calc(10rem/16);
    transition: all 250ms ease;
}

.tvt-add-modell-teaser__hl {
    letter-spacing: calc(5rem/16);
    margin-top: calc(50rem/16)
}