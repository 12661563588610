.black-logo-text-teaser-row.has-bg-img{
    background-size: cover;
    background-repeat: no-repeat;
}

.black-logo-text-teaser-row-wrapper{
    position: relative;
    
    @media screen and (min-width: 768px){
       margin-top: calc(55rem/16);
    }
}

.black-logo-text-teaser-row-wrapper .slick-list{
    padding: calc(20rem/16) 0;
    margin-top: calc(20rem/16);
}

.has-brush .black-logo-text-teaser-row-wrapper:before{
    @media screen and (min-width: 768px){
        content: "";
        position: absolute;
        background-image: url(/static/img/brush.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 60%;
        width: auto;
        height: auto;
        top: 50%;
        left: 0;
        bottom: 0;
        right: 0;
        transform: translateY(-50%);
        min-height: 420px;
    }
}

.black-logo-text-teaser{
    background-color: var(--color-darkgrey);
    color: #fff;
    padding: calc(35rem/16) calc(25rem/16);
    transition: all 150ms ease;

    @media screen and (min-width: 768px){
        padding: calc(35rem/16);
        height: 100%;
    }

    @media screen and (min-width: 1200px){
        padding: calc(62rem/16);
    }
}
.black-logo-text-teaser:hover{
    background-color: var(--color-default);

}

.black-logo-text-teaser__hl{
    font-size: calc(14rem/16);
    letter-spacing: calc(1.75rem/16);
    margin-bottom: calc(20rem/16);
    
    margin-top: calc(20rem/16);
    
    @media screen and (min-width: 768px){
       margin-top: calc(40rem/16);
    }
}

.black-logo-text-teaser .link-with-icon{
    margin-top: calc(30rem/16);
    
    @media screen and (min-width: 768px){
       margin-top: calc(50rem/16);
    }
}

.black-logo-text-teaser__link{
    font-family: var(--font-bold);
    font-size: calc(15rem/16);
    letter-spacing: calc(1rem/16);
    margin-top: calc(30rem/16);
    display: inline-block;
}

.black-logo-text-teaser__link .icon{
    font-size: calc(11rem/16);
    vertical-align: calc(-1rem/16);
    color: var(--color-primary);
}

.black-logo-text-teaser__img{
    text-align: center;
}