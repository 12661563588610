.icon-bike-frame {
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url('/static/img/icon-bike-frame.svg');
}

.icon-bike-frame-low {
    width: calc(46rem/16);
    height: calc(32rem/16);
    background-position: calc(-62rem/16) 0;
}

.icon-bike-frame-mid {
    width: calc(46rem/16);
    height: calc(32rem/16);
    background-position: calc(-124rem/16) 0;
}

.icon-bike-frame-high {
    width: calc(46rem/16);
    height: calc(32rem/16);
    background-position: 0 0;
}