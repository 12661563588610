.recommendation-teaser-row__item{
    padding-top: calc(17rem/16);
}
.recommendation-teaser-row .row .col-md-3:nth-child(even){
    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }
}
.recommendation-teaser-row__btn{
    transition: opacity 200ms ease-in-out;
}
.recommendation-teaser-row__btn:not(.collapsed){
    opacity: 0;
    pointer-events: none;
    cursor: none;
}