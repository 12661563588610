.table>table{
  width: 100%;
}

.table thead th{
  border-top: none;
  border-bottom-color: var(--color-default);
}

.table td{
  border-bottom-color: var(--color-lightgrey);
  border: none;

}

.wysiwyg table{
  border: none;
}

.wysiwyg .table thead th{
  font-family: var(--font-black);
  text-transform: uppercase;
  line-height: calc(25/22);
  border: none;

  @media screen and (min-width: 768px){
    font-size: 22px;
    padding: 30px 15px;
  }
}


.wysiwyg .table tbody th{
  border: none;
}

.wysiwyg .table tbody tr:nth-child(odd){
  background-color: var(--color-lightgrey);
}

.wysiwyg .table th,
.wysiwyg .table td{
  vertical-align: middle;

  @media screen and (max-width: 767px){
     font-size: 14px;
     padding: 10px 5px;
  }
}

.table__title{
    font-size: calc(14rem/16);
    line-height: calc(16/14);
    letter-spacing: calc(1.75rem/16);
    font-family: var(--font-black);
    text-transform: uppercase;
}