.content-heading{
  margin-bottom: calc(35rem/16);
  @media screen and (min-width: 768px){
     margin-bottom: calc(50rem/16);
  }

  @media screen and (min-width: 992px){
    margin-bottom: calc(80rem/16);
  }
}

.section-headline{
  margin-bottom: calc(15rem/16);
  
  @media screen and (min-width: 768px){
     margin-bottom: calc(30rem/16);
  }
}

.content-heading .wysiwyg{
  max-width: calc(690rem/16);
  margin: 0 auto;
}