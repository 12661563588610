.tile-link{
    background-color: var(--color-grey-100);
    padding: calc(32rem/16) calc(16rem/16);
    display: flex;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: 768px){
        padding: calc(66rem/16) calc(16rem/16) calc(31rem/16) calc(16rem/16);
    }
}
.tile-link__title{
    font-size: calc(24rem/16);
    line-height: calc(32/31);
    letter-spacing: calc(2rem/16);
    opacity: 0.6;
    position: relative;
    overflow: hidden;
    transition: opacity 200ms ease-in-out;
    @media screen and (min-width: 768px){
        font-size: calc(31rem/16);
        line-height: calc(37/31);
        padding-bottom: calc(36rem/16);
        letter-spacing: calc(10rem/16);
    }
}
.tile-link__title.tile-link__title--sm{
    font-size: calc(16rem/16);
    letter-spacing: calc(1rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}
.tile-link__icon{
    color: var(--color-primary);
    font-size: calc(24rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(32rem/16);
    }
}
.tile-link .tile-link__title:after {
    content: '';
    position: absolute;
    background-color: var(--color-primary);
    bottom: 0;
    width: calc(31rem/16);
    height: calc(5rem/16);
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    transition: transform 250ms ease;
}

.tile-link:hover > .tile-link__title:after,
.tile-link.active > .tile-link__title:after{
    @media screen and (min-width: 768px){
        transform: translateX(-50%) translateY(0);
    }
}
.tile-link:hover > .tile-link__title,
.tile-link.active > .tile-link__title{
    opacity: 1;
}