.form-group {
  position: relative;
  @media screen and (max-width: 767px){
     margin-bottom: calc(20rem/16);
  }
}

.form-group__icon{
  position: absolute;
  right: calc(5rem/16);
  top: 50%;
  transform: translateY(-50%);
  font-size: calc(25rem/16);
}

.form-group__btn{
    position: absolute;
    right: calc(5rem/16);
    top: 50%;
    transform: translateY(-50%);
}


.form-group__icon--sm{
  font-size: 1rem;
}

.form-control,
.geometry-finder input{
  border: none;
  border-radius: 0;
  border-bottom: 2px solid var(--color-default);
  padding: calc(15rem/16) calc(5rem/16) calc(15rem/16);
  color: var(--color-default);
}

.form-control--md, .geometry-finder--md input {
  padding-top:calc(11rem/16);
  padding-bottom:calc(11rem/16);
}

.form-control.hasDatepicker {
    background: #fff;
}

.input-group .form-control{
  border: none;
}

.form-control:focus,
.geometry-finder input:focus{
  box-shadow: none;
  border-color: var(--color-default);
}

.form-control.form-control--sm{
  padding: calc(7rem/16) calc(5rem/16);
}
.form-control.form-control--xs{
    padding: calc(3rem/16) calc(5rem/16);
}

.form-control--simple{
  padding: .5rem .5rem 2rem .5rem;
  border: 2px solid var(--color-lightgrey);
}
.form-control--transparent,
.form-control--transparent.hasDatepicker, /* increase specificity to overwrite*/
.form-control--transparent:focus {
    background-color: transparent;
    border-color: currentColor;
    color: inherit;
}

.save-comment-btm-right {
  position: absolute;
  right:2px;
  bottom:2px;
  padding:.125rem .625rem .625rem .125rem;
  background:#fff;
  font-size: .9375rem;
}

.form-group label{
  font-family: var(--font-black);
  text-transform: uppercase;
  font-size: calc(16rem/16);
}

.form-group.form-group--number-with-unit label{
    display: flex;
    align-items: center;
    background: #FFF;
    padding: 0 calc(5rem/16);
}

.form-control.form-control--grey{
  border: 2px solid var(--color-lightgrey);
  border-radius: calc(1000rem/16);
  padding: calc(16rem/16);
  padding-left: calc(40rem/16);
}

.form-group--icon-wrapper .icon{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(15rem/16);
}

select.form-control:not([size]):not([multiple]){
    height: auto;
}
.form-group .select select.form-control:not([size]):not([multiple]){
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    cursor: pointer;
}
.form-group .select:after {
    font-family: "iconfont";
    content: var(--icon-arrow-down);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(15rem/16);
    font-size: calc(7rem/16);
    color: #000;
    cursor: pointer;
    pointer-events: none;
}

.has-error .form-control{
  border-bottom-color: var(--color-danger);
}

.parsley-errors-list{
  list-style: none;
  padding: 0;
  font-size: calc(15rem/16);
  position: absolute;
  color: var(--color-danger);
  line-height: calc(15rem/16);
  bottom: calc(2rem/16);
  right: calc(20rem/16);
  z-index: 1;
  letter-spacing: 0;
  text-transform: none;
}
.custom-checkbox .parsley-errors-list{
    position: relative;
    margin-top: calc(10rem/16);
    right: auto;
    width: fit-content;
}

.custom-radio--bordered .parsley-errors-list{
  bottom: calc(-20rem/16);
}

input:-webkit-autofill{
  background-color: #fff!important;
}

.form-section:not(.form-section--no-margin){
  margin-top: calc(35rem/16);

  @media screen and (min-width: 768px){
     margin-top: calc(60rem/16);
  }
}

.form-section__titles{
  letter-spacing: calc(3rem/16);
  font-size: calc(20rem/16);
  margin-bottom: calc(30rem/16);

  @media screen and (min-width: 768px){
    font-size: calc(23rem/16);
    letter-spacing: calc(4rem/16);
  }
}

.form__add-form-section{
  margin-top: calc(50rem/16);
  border: none;
  border-radius: 0;
  background-color: var(--color-grey-100);
  padding: calc(20rem/16) calc(30rem/16);
  letter-spacing: calc(5rem/16);
  font-family: var(--font-black);
  color: var(--color-grey);
  text-transform: uppercase;
  cursor: pointer;

  @media screen and (min-width: 768px){
    padding: calc(30rem/16) calc(60rem/16);
  }
}
.form__add-form-section:hover,
.form__add-form-section:focus{
  background-color: var(--color-lightgrey);
}

.form__add-form-section.hide{
  display: none;
}

.form__add-form-section .icon{
  font-size: calc(17rem/16);
  vertical-align: calc(-2rem/16);
}

.form-section--alternative{
  display: none;
}

.form-section--alternative.show{
  display: block;
}

.file-input--custom {
    border: 1px dashed var(--color-darkgrey);
    padding: .75rem 2rem;
    cursor: pointer;
    position: relative;
    margin-bottom: .625rem;
}
.file-input--custom .file-input__text {
    font-size: calc(12rem/16);
    padding: 0;
    color: var(--color-grey);
    cursor: pointer;
}
.file-input--custom .file-input__icon {
    font-size: 1rem;
    color: var(--color-lightgrey);
    position: absolute;
    top: -.625rem;
    right: 0;
}
.form-control--file-input {
  padding-bottom:0;
  border-bottom:0;
  padding-left:0;
}
.form-control--file-input.form-control--md {
  padding-top:calc(17rem/16);
}
.form-control--file-input.form-control--md.has-value + .floating-label {
  transform: translateY(calc(-125% - 3px)) scale(.7);
}

.form-control.form-control--transparent:disabled, .form-control.form-control--transparent:not(.hasDatepicker)[readonly] {
  background-color:transparent;
  opacity:.5;
}
.form-control.form-control--transparent:disabled~.floating-label, .form-control.form-control--transparent:not(.hasDatepicker)[readonly]~.floating-label {
  opacity:.5;
}
.form-control--switch {
    border-bottom:0;
    z-index: 1;
    position: relative;
}
.form-control--lg.form-control--switch.form-control{
    padding: 0;
}
.form-control--switch .form-check {
  padding-left:0;
  overflow:hidden;
}
.form-control--switch .form-check-input {
  position:absolute;
  font-size:10rem;
  width:calc(34rem/16);
  height:100%;
  top:0;
  left:0;
  opacity:0;
  margin:0;
    cursor: pointer;
}
.form-control--lg.form-control--switch .form-check-input{
    width: calc(68rem/16);
}
.form-control--switch .form-check-input:checked + .form-check-label:before {
  background:var(--color-primary);
  transform:translateX(calc(12rem/16));
}
.form-control--lg.form-control--switch .form-check-input:checked + .form-check-label:before{
    transform:translateX(calc(24rem/16));
}
.form-control--switch .form-check-label {
  padding-left:calc(34rem/16);
  font-size:calc(14rem/16);
  letter-spacing:calc(2rem/16);
  color:var(--color-grey);
    cursor: pointer;
  &:before {
    content:"";
    position:absolute;
    left:0;
    top:50%;
    margin-top:calc((6rem/16)*-1);
    background: var(--color-grey);
    width:calc(12rem/16);
    height:calc(12rem/16);
    border-radius:calc(12rem/16);
    z-index:1;
    transition:transform 300ms ease;
  }
  &:after {
    content:"";
    position:absolute;
    left:0;
    top:50%;
    margin-top:calc((5rem/16)*-1);
    background: #E9E9E9;
    width:calc(24rem/16);
    height:calc(10rem/16);
    border-radius:calc(5rem/16);
  }
}
.form-control--lg.form-control--switch .form-check-label{
    padding-left:calc(70rem/16);
    &:before {
        margin-top:calc((12rem/16)*-1);
        width:calc(24rem/16);
        height:calc(24rem/16);
        border-radius:calc(24rem/16);
    }
    &:after {
        margin-top:calc((10rem/16)*-1);
        width:calc(48rem/16);
        height:calc(20rem/16);
        border-radius:calc(10rem/16);
    }
}
.form-control--switch.form-control--md {
  /*
  padding-top:calc(19rem/16);
  padding-bottom:calc(3rem/16);
  */
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}