.detail-heading__title{
    font-size: calc(24rem/16);
    line-height: calc(29/24);
    letter-spacing: calc(3.75rem/16);
    margin-bottom: calc(31rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(32rem/16);
        line-height: calc(38/32);
        letter-spacing: calc(5rem/16);
    }
}