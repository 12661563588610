.testbike-teaser{
    background-color: #FFFFFF;
    border-radius: calc(10rem/16);
    padding: calc(6rem/16) calc(10rem/16);
}
.testbike-teaser__content{
    font-size: calc(14rem/16);
    line-height: calc(16/14);
    letter-spacing: normal;
    text-align: left;
}