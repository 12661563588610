.header-sujet{
  position: relative;
  height: 100dvh;
  width: 100vw;
  max-width: 100%;
}
.header-sujet__img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
}
.header-sujet__picture-desktop{
    display: none;
    @media screen and (min-width: 768px){
        display: block;
    }
}
.header-sujet__picture-mobile{
    display: block;
    @media screen and (min-width: 768px){
        display: none;
    }
}
.header-sujet__video{
    object-fit: cover;
}
.header-sujet__gradient{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000;
}


.header-sujet__hl{
  text-shadow: 0 0 40px rgba(0,0,0,.58);
  @media screen and (min-width: 768px){
     font-size: calc(90rem/16);
    line-height: calc(80/90);
  }
}

.header-sujet__preline{
  text-shadow: 0 0 10px #000000;
  letter-spacing: 3.56px;
}

.header-sujet__text{
  width: 100%;
  padding: 0 calc(20rem/16);
  animation-name: fadeIn;
  animation-duration: 0.7s;
  animation-delay: 0.3s;
  opacity: 0;
  animation-fill-mode: forwards;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.header-sujet__text.header-sujet__text--bottom-desktop{
    @media (min-width: 992px) {
        bottom: calc(20rem/16);
        top: auto;
        transform: translateX(-50%);
    }
}
.header-sujet__text.header-sujet__text--bottom-mobile{
    @media (max-width: 991px) {
        bottom: calc(20rem/16);
        top: auto;
        transform: translateX(-50%);
    }
}

.header-sujet__scroll-down{
  position: absolute;
  left: 50%;
  bottom: calc(30rem/16);
  transform: translateX(-50%);
  width: 28px;
  height: 50px;
  transition: all 250ms ease;
/*  animation-name: fadeIn;
  animation-duration: 0.7s;
  animation-delay: 0.5s;
  opacity: 0;
  animation-fill-mode: forwards*/;
}

.header-sujet__scroll-down.hide-it{
  opacity: 0;
}

.header-sujet__scroll-down .chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: scrollMove 3s ease-out infinite;
}

.header-sujet__scroll-down .chevron:first-child {
  animation: scrollMove 3s ease-out 1s infinite;
}

.header-sujet__scroll-down .chevron:nth-child(2) {
  animation: scrollMove 3s ease-out 2s infinite;
}

.header-sujet__scroll-down .chevron:before,
.header-sujet__scroll-down .chevron:after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: var(--color-primary);
}

.header-sujet__scroll-down .chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.header-sujet__scroll-down .chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes scrollMove {
  25% {
    opacity: 1;

  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-70%);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
  }
}

.header-sujet__video{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.header-sujet--video .video-js .vjs-tech {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
  width: auto;
  height: auto;
}