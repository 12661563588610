.product-teaser{
  text-align: center;
  height: 100%;
}

.product-teaser__body{
  position: relative;
  padding: 0 calc(20rem/16) calc(60rem/16);
  min-height: calc(110rem/16);
  margin-top: calc(-32rem/16);

  @media screen and (min-width: 768px){
    margin-top: calc(-18rem/16);
  }
}

.product-teaser__title,
.product-teaser__title .btn{
  width: 100%;
  transition: all 350ms ease;
    font-size: calc(20rem/16);

    @media screen and (min-width: 992px){
        font-size: calc(26rem/16);
    }
  
  @media screen and (min-width: 768px) and (max-width: 991px){
     font-size: calc(22rem/16);
  }
}


.product-teaser__category{
  font-size: calc(17rem/16);
  letter-spacing: calc(7rem/16);
  color: #d7d7d7;
  text-transform: uppercase;
  font-family: var(--font-black);
  line-height: calc(35/40);
  margin-top: calc(25rem/16);
  margin-bottom: calc(8rem/16);
  display: inline-block;
  position: relative;
  left: calc(2rem/16);
}

.slide.bg-lightgrey .product-teaser__category{
  color: #fff;
}

.product-teaser:not(.product-teaser--b2c) .product-teaser__img{
  position: relative;
  padding-bottom: calc(35rem/16);
}

.product-teaser:not(.product-teaser--b2c) .product-teaser__img:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 0;
  background-image: url(/static/img/shadow.png);
  background-size: contain;
  height: calc(45rem/16);
  background-repeat: no-repeat;
  width: 80%;
  transform: translateX(-50%);
  opacity: 0.22;
}

.product-teaser .product-teaser__img img{
  transition: all 250ms ease;
}

.product-teaser:not(.product-teaser--b2c) .product-teaser__img img {
    max-width: 100%;

  @media screen and (min-width: 768px){
    width: calc(380rem/16);
    height: calc(250rem/16);
  }

  @media screen and (min-width: 1024px){
    width: calc(480rem/16);
    height: calc(315rem/16);
  }
}

.product-teaser:hover .product-teaser__img img {
  transform: scale(1.1);

  @media screen and (min-width: 992px){
    transform: scale(1.15);
  }
}

.product-teaser__price{
  font-size: calc(22rem/16);
  line-height: calc(37/31);
  font-family: var(--font-black);
  transition: all 350ms ease;

  @media screen and (min-width: 992px){
    font-size: calc(31rem/16);
  }
  
  @media screen and (min-width: 768px){
    font-size: calc(20rem/16);
  }
}

.product-teaser--b2c{
  transition: all 350ms ease;
}

.product-teaser--b2c:hover{
  box-shadow: 0 4px 14px 0 rgba(0,0,0,0.1);
}

.product-teaser--bike .product-teaser__img{
  padding: 0 calc(20rem/16);
}

.product-teaser.product-teaser--b2c .product-teaser__img img{
  transform: scale(0.95);
}

.product-teaser--bike .product-teaser__img img{
  width: 100%;
}

.product-teaser.product-teaser--b2c:hover .product-teaser__img img{
  transform: scale(1);
}

.product-teaser--b2c .product-teaser__body{
  margin-top: calc(-25rem/16);
  
  @media screen and (max-width: 767px){
    padding: 0 calc(20rem/16) 0;
    min-height: calc(75rem/16);
  }
}

.product-teaser--b2c.product-teaser--merchandise .product-teaser__body{
  @media screen and (max-width: 767px){
     margin-top: calc(-30rem/16);
  }
}

.product-teaser--merchandise .product-teaser__img{
  text-align: center;
  position: relative;
}

.product-teaser--merchandise .product-teaser__img img{
  display: inline-block;
}

.btn.btn-product-teaser__cart {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: calc(70rem/16);
  height: calc(70rem/16);
  padding: 0;
  font-size: calc(26rem/16);

  @media screen and (min-width: 992px){
    opacity: 0;
    transition: all 250ms ease;
  }

}

.product-teaser:hover .btn-product-teaser__cart{
  opacity: 1;
}

.btn-product-teaser__cart>.icon{
  position: relative;
  pointer-events: none;
}

.btn-product-teaser__cart>.icon>.icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: calc(8rem/16);
  margin-top: calc(1rem/16);
  pointer-events: none;
}

.product-grid-row__slider .slick-slide{
  margin: 0 calc(15rem/16);
}

.product-grid-row__slider .slick-list{
  padding: calc(15rem/16) 0;
}

.btn.btn-product-teaser__not-available{
    padding: calc(2rem/16) calc(10rem/16);
    border-radius: 0;
    transition: opacity 0.2s;
    background-color: var(--color-lightgrey);
    color: #000;
    border-color: var(--color-lightgrey);
    opacity: 1;
  margin-top: calc(7rem/16);

  @media not all and (hover: none){
    opacity: 0;
  }

}

.product-teaser:hover .btn.btn-product-teaser__not-available{
    @media (hover:hover){
        opacity: 1;
    }
}
/*

.product-teaser__bottom {

  @media not all and (hover: none){
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

}*/
