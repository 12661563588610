
/* width */
.custom-scrollbar::-webkit-scrollbar {
    width: 3px;
    height: 4px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
    background: var(--color-light-grey);
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #e3e3e3;
    border-radius: 1000px;
}


.custom-scrollbar.custom-scrollbar--light::-webkit-scrollbar-thumb {
    background: #d2d2d2;

}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #cacaca;
}


.nav-tabs--scroll.custom-scrollbar{
    padding-bottom: calc(5rem/16);
}