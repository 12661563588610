
.product-item-small{
    font-size: calc(15rem/16);
    letter-spacing: calc(0.83rem/16);
    padding: calc(15rem/16) 0;
    border-top: 1px solid var(--color-default);
    border-bottom: 1px solid var(--color-default);
    margin-top: calc(30rem/16);
}

.product-item-small__body{
    padding-left: calc(10rem/16);

    padding-top: calc(20rem/16);

}

.product-item-small__title{
    font-size: calc(18rem/16);
    letter-spacing: calc(2rem/16);
}

.product-item-small__price{
    font-size: calc(18rem/16);
    color: var(--color-grey);
}