

.order-table__head{
    background-color: var(--color-grey-100);
    padding: calc(10rem/16) calc(10rem/16);
    text-transform: uppercase;
    font-size: calc(15rem/16);
    line-height: calc(16rem/16);

    @media screen and (min-width: 768px){
        padding: calc(15rem/16) calc(30rem/16);
    }

}

.delivery-state-open{
    color: #F5A623;
}

.delivery-state-complete{
    color: var(--color-success);
}

.order-table__head__price{
    font-size: calc(18rem/16);
}

.order-table__title{
    letter-spacing: calc(2rem/16);
}

.order-table__table{
    width: 100%;
    
    @media screen and (max-width: 767px){
       display: block;
        font-size: calc(16rem/16);
    }
}

.order-table__table tr{
    border-bottom: 1px solid var(--color-grey-100);
}

.order-table__table td{
    padding: calc(3rem/16) calc(10rem/16);
    letter-spacing: calc(1rem/16);

    @media screen and (min-width: 768px){
        padding: calc(30rem/16);
    }
    
    @media screen and (max-width: 767px){
       display: block;
    }
}

.order-table__table tr>td:first-child:not(.order-table__table__td-title){
    padding-top: calc(15rem/16);
}

.order-table__table tr>td:last-child:not(.order-table__table__td-price){
    padding-bottom: calc(15rem/16);
}

.order-table__table__td-title{
    @media screen and (min-width: 768px){
       width: 30%;
    }

    @media screen and (min-width: 992px){
        width: 25%;
    }
}

.order-table__table__td-amount{
    @media screen and (min-width: 768px){
        width: 15%;
    }
}

.order-table__table__td-link{
    @media screen and (min-width: 768px){
        width: auto;
    }
}
.order-table__table__td-price{
    @media screen and (min-width: 768px){
        width: auto;
    }
}
.order-table__table tr > td.order-table__content-sm{
    font-size: calc(14rem/16);
    line-height: calc(19/14);
    letter-spacing: 0;
    text-transform: uppercase;
}
.order-table__table tr > td.order-table__content{
    padding: calc(15rem/16) calc(31rem/16);
    @media screen and (min-width: 768px){
        padding: calc(31rem/16);
    }
}
.order-table__content-link{
    font-size: calc(18rem/16);
    line-height: calc(19/18);
}
