.product-main-text{
    padding: calc(20rem/16) 0 calc(40rem/16);

    @media screen and (min-width: 1200px){
        padding: calc(80rem/16) 0 calc(40rem/16);
    }
}

.product-main-text__title{
    font-size: calc(25rem/16);
    letter-spacing: calc(5rem/16);
    margin-bottom: calc(30rem/16);
    line-height: calc(30/25);

    @media screen and (min-width: 768px){
        font-size: calc(35rem/16);
        letter-spacing: calc(11rem/16);
    }
}


.show-all-content__content {
    position: relative;
    /*    max-height: 70px;*/
    overflow: hidden;
    -webkit-transition: max-height .25s ease-in-out, height .25s ease-in-out;
    -o-transition: max-height .25s ease-in-out, max-height .25s ease-in-out;
    transition: max-height .25s ease-in-out, max-height .25s ease-in-out;
}

.show .show-all-content__content{
    max-height: 100vh;
}

.show-all-content__max-height .show-all-content__content{
    max-height: calc(65rem/16);
}

.show-all-content__max-height .show-all-content-btn{
    display: block;
}

.show-all-content__max-height.show .show-all-content__content{
    max-height: 100%!important;
}

.show-all-content-btn__less{
    display: none;
}

.show-all-content__max-height.show .show-all-content-btn__less{
    display: block;
}

.show-all-content__max-height.show .show-all-content-btn__more{
    display: none;
}


.show-all-content__max-height:not(.show) .show-all-content__content:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(80rem/16);
    background: linear-gradient(180deg,hsla(0,0%,92%,0),rgba(234, 234, 234, 0.94));
}