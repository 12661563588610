.main-nav__subnav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-default);
    padding: calc(25rem / 16) 0 calc(55rem / 16);
    display: block;
    transition: all .25s ease-in-out;
    z-index: 3;

    @media screen and (min-width: 1300px) {
        top: calc(116rem / 16);
        visibility: hidden;
        opacity: 0;
        background-color: var(--color-darkgrey);
        padding: calc(88rem / 16) 0;
        pointer-events: none;
    }

    @media screen and (max-width: 1299px) {
        position: fixed;
        height: calc(100vh - 110rem / 16);
        transform: translateX(100%);
        overflow: hidden;
    }
}

.main-nav__subnav:not(:has(> .container > .main-nav__subnav-grid > .main-nav__subnav--layer-1 > li.is-open)) {
    @media screen and (max-width: 1299px) {
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.navbar-main .nav-item:hover .main-nav__subnav,
.navbar-main .nav-item.is-open .main-nav__subnav {
    @media screen and (min-width: 1300px) {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }
}

.nav-open .main-nav__subnav.is-open {
    @media screen and (max-width: 1299px) {
        transform: translateX(0);
    }
}

.main-nav__subnav .container {
    @media screen and (min-width: 1300px) {
        padding-left: calc(56rem / 16);
        padding-right: calc(56rem / 16);
        max-width: calc(1600rem/16);
    }
}

.main-nav__subnav-grid {
    height: 100%;
    width: 100%;

    @media screen and (min-width: 1300px) {
        display: grid;
        gap: calc(30rem/16);
        grid-template-columns: 1fr 2fr 2fr;
        grid-template-areas: 'subnav . teaser';
        min-height: 60vh;
    }
}

.main-nav__subnav--layer-1 {
    grid-area: subnav;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: calc(32rem / 16);
    padding-top: calc(30rem / 16);
    padding-bottom: calc(30rem / 16);

    @media screen and (min-width: 1300px) {
        position: relative;
        padding: 0;
    }
}

.main-nav__subnav-toggle-btn {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    color: var(--color-lightgrey);
    width: 100%;
    display: flex;
    justify-content: center;
    gap: calc(12rem / 16);
    align-items: center;
    font-family: var(--font-black);
    font-size: calc(20rem / 16);
    line-height: calc(24 / 20);
    letter-spacing: calc(4rem / 16);
    text-transform: uppercase;
    cursor: pointer;
    transition: color 0.15s ease-in-out;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: calc(100% - 12rem / 16);
        display: block;
        width: calc(32rem / 16);
        height: calc(2rem / 16);
        background-color: var(--color-primary);
        transform: scaleX(0) translateY(-50%);
        transform-origin: left;
        transition: transform 0.2s ease-in-out;
    }

    &:focus,
    &:hover {
        color: var(--color-primary);
    }

    &:focus {
        box-shadow: none;
        outline: none;
    }

    @media screen and (min-width: 1300px) {
        width: fit-content;
        font-size: calc(16rem / 16);
        line-height: calc(19 / 16);
    }
}

.is-open > .main-nav__subnav-toggle-btn:after {
    transform: scaleX(1) translateY(-50%);
}

.main-nav__btn-left-icons {
    display: flex;
    align-items: center;
    gap: calc(2rem / 16);
}

.main-nav__btn-left-icons .icon.icon-rocket {
    transform: rotate(25deg) translate(calc(2rem/16), calc(-2rem/16));
}

.main-nav__subnav-toggle-btn .icon:not(.main-nav__subnav-toggle-icon) {
    font-size: calc(24rem / 16);
    width: calc(24rem / 16);

    @media screen and (min-width: 1300px) {
        font-size: calc(20rem / 16);
        width: calc(20rem / 16);
    }
}

.main-nav__subnav-toggle-btn .main-nav__subnav-toggle-icon {
    color: var(--color-grey);
    font-size: calc(8rem / 16);
    transform: translateY(calc(1rem / 16)) rotate(45deg);
    transition: opacity 0.2s ease-in-out;
}

.is-open > .main-nav__subnav-toggle-btn .main-nav__subnav-toggle-icon {
    opacity: 0;
}

.main-nav__subnav--layer-2 {
    position: fixed;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    transform: translateX(100%);
    background-color: #111;
    padding-top: calc(30rem / 16);
    padding-bottom: calc(30rem / 16);
    z-index: 3;
    transition: transform 0.2s ease-in-out;

    @media screen and (min-width: 1300px) {
        position: absolute;
        width: 200%;
        top: 0;
        left: calc(100% + 30rem/16);
        transform: none;
        visibility: hidden;
        opacity: 0;
        background-color: var(--color-darkgrey);
        padding: 0;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    }

    @media screen and (max-width: 1299px) {
        height: calc(100vh - 100rem / 16);
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.is-open > .main-nav__subnav--layer-2 {
    transform: translateX(0);

    @media screen and (min-width: 1300px) {
        visibility: visible;
        opacity: 1;
    }
}

.main-nav__subnav-overview-link {
    font-family: var(--font-black);
    font-size: calc(16rem / 16);
    line-height: calc(19 / 16);
    letter-spacing: calc(2rem / 16);
    text-transform: uppercase;
    color: var(--color-lightgrey);
    display: flex;
    gap: calc(12rem / 16);
    justify-content: center;
    align-items: center;
    transition: color 0.2s ease-in-out;

    &:not(div):hover {
        color: var(--color-primary);
    }

    @media screen and (min-width: 1300px) {
        justify-content: flex-start;
        width: fit-content;
    }
}

.main-nav__subnav-overview-link-icon-right {
    font-size: calc(10rem / 16);
}

.main-nav__subnav--layer-2 > .main-nav__subnav-overview-link {
    font-size: calc(20rem / 16);
    line-height: calc(24 / 20);
    padding-left: 0;
}

.main-nav__subnav--layer-3 {
    margin-top: calc(16rem / 16);
    padding-top: calc(30rem / 16);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: calc(48rem / 16);
    position: relative;

    @media screen and (min-width: 1300px) {
        max-height: 59vh;
        border-top: var(--color-grey-dark) solid calc(1rem/16);
        position: static;
    }
}

.main-nav__subnav--layer-3 .main-nav__subnav-overview-link-icon-right {
    @media screen and (max-width: 1299px) {
        display: none;
    }
}

.main-nav__subnav--layer-4 {
    display: flex;
    flex-direction: column;
    gap: calc(16rem / 16);
}

.main-nav__subnav--layer-4 .main-nav__subnav-link {
    font-family: var(--font-default);
    font-size: calc(16rem / 16);
    line-height: calc(22 / 16);
    color: var(--color-lightgrey);
    position: relative;
    transition: padding-left 0.2s ease-in-out;

    &:hover {
        @media screen and (min-width: 1300px) {
            padding-left: calc(27rem / 16);
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: calc(-27rem / 16);
        transform: translateY(-50%) scaleX(0);
        transform-origin: left;
        width: calc(17rem / 16);
        height: calc(2rem / 16);
        background-color: var(--color-primary);
        transition: transform 0.2s ease-in-out;

        @media screen and (min-width: 1300px) {
            left: 0;
        }
    }

    &:hover:before {
        transform: translateY(-50%) scaleX(1);
    }

    @media screen and (min-width: 1300px) {
        padding-left: calc(10rem / 16);
        display: inline-block;
        height: 100%;
    }
}

.main-nav__subnav--layer-4 .main-nav__subnav-link-content {
    display: inline-flex;
    align-items: center;
    gap: calc(12rem / 16);

}

.main-nav__subnav-hover-img {
    position: absolute;
    top: 0;
    left: calc(100% + 30rem / 16);
    width: calc(66.67% - 10rem/16);
    opacity: 0;
    visibility: hidden;
    background-color: var(--color-darkgrey);
    animation: hideHoverImg 0.2s ease-in-out forwards;

    @media (max-width: 1299px) {
        display: none;
    }
}

.main-nav__subnav-toggle-btn + .main-nav__subnav-hover-img {
    width: calc(133.33% - (10rem/16));
    left: calc(100% + 200% + ((30rem/16) * 2));
    z-index: 3;
}

.main-nav__subnav-hover-img-text {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: calc(15rem/16) calc(10rem/16);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(180deg, rgba(33,33,33,0) 0%, #282829 100%);

    @media screen and (min-width: 768px){
        padding: calc(15rem/16) calc(30rem/16);
    }
}

.main-nav__subnav-hover-img-text .h3 {
    letter-spacing: calc(3.4rem/16);
    color: #fff;
    text-shadow: 4px 4px 10px rgba(0,0,0,0.58);
    font-size: calc(15rem/16);

    @media screen and (min-width: 768px){
        letter-spacing: calc(7rem/16);
        font-size: calc(19rem/16);
    }

    @media screen and (min-width: 1200px){
        letter-spacing: calc(8rem/16);
        font-size: calc(22rem/16);
    }

    @media screen and (min-width: 1350px){
        letter-spacing: calc(10rem/16);
        font-size: calc(36rem/16);
    }
}

.main-nav__subnav--layer-1 li:not(.is-open) .main-nav__subnav-toggle-btn:hover + .main-nav__subnav-hover-img,
.main-nav__subnav-overview-link:hover .main-nav__subnav-hover-img,
.main-nav__subnav-link:hover + .main-nav__subnav-hover-img {
    @media screen and (min-width: 1300px) {
        animation: showHoverImg 0.2s ease-in-out forwards;
    }
}

.main-nav__subnav-teaser-grid {
    grid-area: teaser;
    
    @media screen and (min-width: 768px) and (max-width: 1299px) {
        padding-left: calc(120rem/16);
        padding-right: calc(120rem/16);
    }
}

@keyframes showHoverImg {
    0% {
        visibility: hidden;
        opacity: 0;
    }
    1% {
        visibility: visible;
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
    }
}

@keyframes hideHoverImg {
    0% {
        visibility: visible;
        opacity: 1;
    }
    99% {
        visibility: visible;
        opacity: 0;
    }
    100% {
        visibility: hidden;
        opacity: 0;
    }
}