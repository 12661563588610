.pagination{
    color: var(--color-darkgrey);
    font-family: var(--font-black);
}

.pagination .page-link.page-link {
    margin:0;
    color: var(--color-darkgrey);
    background: transparent;
    border:0;
    opacity: .3;
}
.pagination .page-link:hover,
.pagination .page-link:focus,
.pagination .active > .page-link,
.pagination .page-link.active {
    opacity: 1;
}