.history{
    position: relative;
}
.history__img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center 50%;

    /*transition: transform 1000ms ease;*/
    @media screen and (min-width: 768px) {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 56vw;
        right: 0;
    }
    
    @media screen and (max-width: 767px){
        height: 37vh;
        width: 100vw;
    }
}

.img-left .history__img-wrapper{
    @media screen and (min-width: 768px){
        right: auto;
        left: 0;
    }
}

.history__img-wrapper{
    opacity: 0.5;

    @media screen and (min-width: 768px) {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 56vw;
        right: 0;
        overflow: hidden;
    }
    
    @media screen and (max-width: 767px){
        position: absolute;
        top: 0;
    }
}

.history:first-child .history__img-wrapper{
    opacity: 1;
}
.history__wrapper{
    @media screen and (max-width: 767px){
        position: absolute;
        top: 37vh;
    }
}

.history__wrapper>.row{ /* hack */
    @media screen and (max-width: 767px){
       margin: 0;
    }
}

.history__wrapper>.row>.col-xl-5{ /* hack */
    @media screen and (max-width: 767px){
        padding: 0;
    }
}

.history__content-wrapper {
    @media screen and (min-width: 768px) {
        height: 100vh;
        margin-top: 0;
        position: relative;
    }

    @media screen and (max-width: 767px){
       padding-top: calc(90rem/16);
    }

    @media screen and (max-width: 360px){
        padding-top: calc(69rem/16);

    }
}

.img-left .history__content-wrapper{
    display: flex;
    justify-content: flex-end;
}

.history__content {
    line-height: calc(24rem/16);
    transition: all 300ms ease;
    opacity: 1;


    @media screen and (min-width: 768px){
        width: calc(449rem/16);
        position: absolute;
        bottom: calc(80rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 1430px){
       padding-left: calc(20rem/16);
    }

    @media screen and (min-width: 768px) and (max-height: 800px){
        bottom: calc(30rem/16);
    }

    @media screen and (min-width: 768px) and (max-height: 590px){
        bottom: calc(20rem/16);
    }

}

.fade-out{
    animation-name: fadeOut;
    animation-duration: 1s;
    animation-delay: 0.1s;
    transition: all 900ms ease;
}

.fade-in{
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-delay: 0.5s;
    opacity: 0;
    transition: all 300ms ease;
    animation-fill-mode: forwards;
}


.fade-in-img{
    animation-name: fadeInImg;
    animation-duration: 1s;
    animation-delay: 0.5s;
    opacity: 0.5;
    transition: all 300ms ease;
    animation-fill-mode: forwards;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInImg {
    from {
        opacity: 0.5;
    }
    to {
        opacity: 1;
    }
}

.history__title {
    color: var(--color-primary);
    font-family: var(--font-black);
    text-transform: uppercase;
    font-size: calc(22rem/16);
    margin-bottom: calc(10rem/16);
    letter-spacing: calc(2rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(32rem/16);
        margin-bottom: calc(40rem/16);
        letter-spacing: 0.1rem;
        line-height: calc(34/32);
    }
    
    @media screen and (min-width: 768px) and (max-height: 800px){
       margin-bottom: calc(10rem/16);
    }

    @media screen and (min-width: 768px) and (max-height: 590px){
        font-size: calc(26rem/16);
    }
    
    @media screen and (max-width: 320px){
       font-size: calc(18rem/16);
    }

}
.history__text {
    color: #f8f8f8;
    font-family: var(--font-default);
    font-size: calc(14rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem/16);
    }
    
    @media screen and (max-width: 767px){
        line-height: calc(20/14);
        letter-spacing: calc(0.5rem/16);
    }

    @media screen and (min-width: 768px) and (max-height: 800px){
        font-size: calc(16rem/16);
    }

    @media screen and (min-width: 768px) and (max-height: 590px){
        font-size: calc(15rem/16);
        line-height: calc(21/15);
    }
    
    @media screen and (max-width: 320px){
       font-size: calc(13rem/16);
        line-height: 1.25;
    }
}

.col-no-padding {
    padding: 0;
}