/*.navbar--blog.is-sticky{
  @media screen and (min-width: 768px){
    background: linear-gradient(to bottom, rgba(255,255,255,0.55) 0%,rgba(255,255,255,0.55) 1%,rgba(255,255,255,0.45) 46%,rgba(255,255,255,0) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
  }
}*/
.navbar-anchor{
    padding: 0 calc(25rem/16);

}

.nav-open .navbar-anchor{
    display: none;
}

.navbar-anchor .nav-link{
    color: var(--color-default);
    pointer-events: auto;
    font-family: var(--font-black);
    text-transform: uppercase;
    font-size: calc(16rem/16);
    letter-spacing: calc(4.6rem/16);

    @media screen and (min-width: 768px){
        height: auto;
        display: flex;
        align-items: center;
        padding: 15px 28px;
    }

    @media screen and (min-width: 768px) and (max-width: 1199px){
        padding: 8px 15px;
        font-size: calc(14rem/16);
        letter-spacing: 1.5px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1399px){
        padding: 10px 21px;
        font-size: calc(16rem/16);
        letter-spacing: 2.5px;
    }
}

.navbar-white .navbar-anchor .navbar-anchor__list{
    background-color: transparent;
}

.navbar-anchor .navbar-anchor__list{
    background-color: #fff;
    padding: 0 calc(10rem/16);
    text-align: center;

}

.navbar-anchor .navbar-anchor__list:before, .navbar-anchor .navbar-anchor__list:after {
    @media screen and (max-width: 768px){
        content:"";
        margin:auto;
    }
}

.navbar-anchor .nav-link__text{
    padding: calc(5rem/16);
}



.navbar-anchor>.nav-item:not(:last-child){
    @media screen and (min-width: 768px){
        margin-right: calc(25rem/16);
    }

    @media screen and (min-width: 992px){
        margin-right: calc(45rem/16);
    }
}

.navbar-anchor .nav-item>.nav-link.active .nav-link__text:after{
    transform: translateY(0) translateX(-50%);
}

.navbar-anchor-inline{
    z-index: 10;
    background-color: #fff;
    @media screen and (min-width: 768px){
       padding: 5px 0;
    }

    @media screen and (min-width: 992px){
        padding: 29px 0;
    }
}

/*.navbar-anchor-inline.is-affix{
    @media screen and (max-width: 767px){
       top: auto!important;
        bottom: 0;
    }
}*/

.navbar-anchor-inline.is-affix:not(.no-menu) .nav-tabs{

    @media screen and (min-width: 768px) and (max-width: 1700px){
        justify-content: flex-start!important;
    }

    @media screen and (min-width: 992px) and (max-width: 1700px){
        padding-left: 100px;

    }

    @media screen and (min-width: 768px) and (max-width: 1700px){
        padding-left: 40px;

    }
}

.navbar-anchor-inline .nav-tabs .nav-item{
    @media screen and (max-width: 767px){
       margin-bottom:0;
    }
}
.navbar-anchor-inline .nav-tabs .nav-link{
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
        padding: calc(5rem/16) calc(8rem/16);
        letter-spacing: calc(3rem/16);
    }
}

/*--------------- mobile anchor nav ----------------*/
.navbar-anchor--mobile {
    @media screen and (max-width: 767px) {
        position: absolute;
        top: calc(77rem/16);
        left:calc(25rem/16);
        padding:0;
        width:calc(100% - (50rem/16));
        pointer-events:all;
        height:calc(38rem/16);
        overflow:hidden;
        box-shadow:0 4px 6px rgba(50, 50, 90, .11), 0 1px 3px rgba(0, 0, 0, .09);
    }
}
.navbar-anchor--mobile .navbar-anchor {
    @media screen and (max-width: 767px) {
        padding:0;
    }
}
.navbar-anchor--mobile .navbar-anchor__list {
    @media screen and (max-width: 767px) {
        display: flex;
        flex-flow:row nowrap;
        overflow-x:scroll;
        scroll-behavior:smooth;
        -webkit-overflow-scrolling:touch;
        white-space:nowrap;
        position:relative;
        height:calc(100rem/16);

    }
}
.navbar-anchor--mobile .nav-link__text {
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
        letter-spacing: calc(2rem/16);
        max-width:none;
        white-space:nowrap;
        padding-bottom: 0;
        overflow: visible;
    }
}
.navbar-anchor--mobile .list-inline-item:not(:last-child) {
    @media screen and (max-width: 767px) {
        margin-right:1.5rem;
    }
}
.navbar-anchor--mobile .nav-link {
    @media screen and (max-width: 767px) {
        padding: .5rem 0;
    }
}

.navbar-anchor--mobile .nav-item>.nav-link.active .nav-link__text:after {
    @media screen and (max-width: 767px) {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 2.1875rem;
        height: .25rem;
        background-color: #c73631;
        transform: translateY(100%) translateX(-50%);
        transition: all .25s ease;
    }
}
