body {
    color: var(--color-default);
    font-family: var(--font-default);
    line-height: calc(24/18);
    font-size: calc(18rem/16);

}

.font-default{
    font-family: var(--font-default)!important;
}

.font-bold{
    font-family: var(--font-bold)!important;
}

.font-black{
    font-family: var(--font-black)!important;
}

b,
strong,
.strong {
    font-family: var(--font-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    text-transform: uppercase;
    font-family: var(--font-black);
}

@media screen and (min-width: 768px){
    h1, .h1 {
        font-size: calc(45rem/16);
        line-height: calc(40/45);
        letter-spacing: calc(7.5rem/16);
    }
    h2, .h2 {
        font-size: calc(40rem/16);
        line-height: calc(55/40);
        letter-spacing: calc(7.5rem/16);
    }
    h3, .h3 {
        font-size: calc(26rem/16);
        line-height: calc(26/26);
    }

    h4, .h4 {
        font-size: calc(22rem/16);
        line-height: calc(21/22);
    }
    h5,.h5{
        letter-spacing: calc(3.56rem/16);
    }
}

h1, .h1 {
    font-size: calc(32rem/16);
    line-height: calc(45/45);
    letter-spacing: calc(2rem/16);
}
h2, .h2 {
    font-size: calc(28rem/16);
    line-height: calc(35/35);
    letter-spacing: calc(2rem/16);
}
h3, .h3 {
    font-size: calc(20rem/16);
    line-height: calc(20/20);
}
h4, .h4 {
    font-size: calc(18rem/16);
    line-height: calc(21/22);
}

h5, .h5 {
    font-size: calc(16rem/16);
    line-height: calc(24/16);
    letter-spacing: calc(2rem/16);
}


.fz10{
    font-size: calc(10rem/16);
}
.fz12{
    font-size: calc(12rem/16);
}

.fz14{
    font-size: calc(14rem/16);
}

.fz15{
    font-size: calc(15rem/16);
}
.fz16 {
    font-size: calc(16rem /16);
}
.fz18,
.fz18.fz18 {
    font-size: calc(18rem /16);
}
@media screen and (min-width: 768px) {
    .fz-md-18 {
        font-size: calc(18rem /16);
    }
}
.fz20{
    font-size: calc(20rem/16);
}
.fz30{
    font-size: calc(30rem/16);
}
.fz60 {
    font-size: calc(35rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(60rem/16);
    }
}
.fz100{
    font-size: calc(100rem/16);
}

