.loading-spinner {
    margin-left: auto;
    margin-right: auto;
    width: 70px;
    text-align: center;
    position: relative;
    z-index: 3;
}
.loading-spinner__item {
    width: 18px;
    height: 18px;
    background-color: var(--color-primary);

    border-radius: 100%;
    display: inline-block;
    animation: loading-spinner-rotate 1.4s infinite ease-in-out both;
}
.loading-spinner__item--1 {
    animation-delay: -0.32s;
}
.loading-spinner__item--2 {
    animation-delay: -0.16s;
}
@keyframes loading-spinner-rotate {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}
.loading-spinner--white .loading-spinner__item {
    background-color: #fff;
}

.ajax-loading{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    opacity: 0.5;
    z-index: 2;
}