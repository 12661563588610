.btn-filled {
    border: none;
}
.btn-filled.btn-success,
.btn-filled--success {
    background-color: var(--color-success);
    color: #fff;

    &:hover,
    &:focus,
    &:active {
        color: #fff;
        background-color: var(--color-success-dark);
    }
}
.btn-filled.btn-danger,
.btn-filled--danger {
    background-color: var(--color-primary);
    color: #fff;

    &:hover,
    &:focus,
    &:active {
        color: #fff;
        background-color: var(--color-danger-dark);
    }
}
.btn-filled.btn-default,
.btn-filled--default {
    background-color: var(--color-grey);
    color: #fff;

    &:hover,
    &:focus,
    &:active {
        color: #fff;
        background-color: var(--color-grey-dark);
    }
}
.btn-filled.btn-dark,
.btn-filled--dark {
    background-color: var(--color-darkgrey);
    color: #fff;

    &:hover,
    &:focus,
    &:active {
        color: #fff;
        background-color: var(--color-darkgrey);
    }
}
.btn-filled.btn-white,
.btn-filled--white {
    background-color: white;
    color: var(--color-primary);

    &:hover,
    &:focus,
    &:active {
        color: var(--color-primary);
        background-color: white;
    }
}