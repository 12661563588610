.product-grid{
  padding-bottom: calc(50rem/16);
  margin-top: calc(80rem/16);

  @media screen and (min-width: 768px){
    padding-bottom: calc(100rem/16);

    margin-top: calc(100rem/16);
  }
}

.product-grid.product-grid--b2c{
  margin-top: calc(20rem/16);
}

.product-grid-row .content-heading{
  margin-bottom: calc(20rem/16);
}