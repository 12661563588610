.content-teaser{
  background-color: #fff;
  transition: all 250ms ease;
}

.content-teaser:hover{
  transform: translateY(calc(-10rem/16));
}

.content-teaser__img{
  position: relative;
  overflow: hidden;
}

.content-teaser__body{
  padding: calc(25rem/16) calc(25rem/16) calc(35rem/16);
}


.bg-white .content-teaser{
  background-color: var(--color-lightgrey);
}
.bg-lightgrey .content-teaser{
    background-color: #fff;
}


.teaser-slide .card{
  transition: all 250ms ease;
}

.teaser-slide .card:hover{
  transform: translateY(calc(-10rem/16));

}