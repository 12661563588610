.account-order__item{
    background-color: var(--color-lightgrey);
    padding: calc(16rem/16) calc(16rem/16);
    font-size: calc(16rem/16);
    line-height: calc(19/14);
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    @media screen and (min-width: 768px){
        padding: calc(16rem/16) calc(32rem/16) calc(16rem/16) calc(16rem/16);
        font-size: calc(14rem/16);
    }
    @media screen and (min-width: 1200px){
        padding: calc(16rem/16) calc(32rem/16);
    }
}
.account-order__item:active,
.account-order__item:focus,
.account-order__item:focus-visible,
.account-order__item:hover{
    border: none;
    outline: none;
}
.account-order__item:not(:first-child){
    margin-top: calc(30rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(62rem/16);
    }
}
.account-order__item-price{
    font-size: calc(18rem/16);
    line-height: calc(19rem/16);
}
.account-order__filter{
    padding-bottom: calc(31rem/16);
    margin-bottom: calc(31rem/16);
    border-bottom: calc(1rem/16) solid var(--color-lightgrey);
}
.account-order__item.collapsed .account-order__item-icon{
    transform: rotate(270deg);
    color: var(--color-grey);
}
.account-order__item-icon{
    transform: rotate(90deg);
    color: var(--color-primary);
}