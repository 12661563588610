.cart-summary{
    padding-top: calc(20rem/16);
    border-top: 2px solid var(--color-default);
    letter-spacing: calc(1rem/16);
}

.cart-summary__total-price{
    font-size: calc(15rem/16);
    text-transform: uppercase;
    font-family: var(--font-black);
    letter-spacing: calc(2rem/16);
}

.cart-summary__total-price__price{
    font-size: calc(34rem/16);
    letter-spacing: calc(4.53rem/16);
}