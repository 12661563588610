.btn{
  border-radius: calc(1000rem/16) !important;
  border: calc(2rem/16) solid;
  color: var(--color-default);
  background: transparent;
  padding: calc(10rem/16) calc(25rem/16) !important;
  text-transform: uppercase;
  position: relative;
  font-family: var(--font-black);
  font-size: calc(16rem/16) !important;
  letter-spacing: calc(1rem/16);
  
  @media screen and (min-width: 768px){
    padding: calc(17rem/16) calc(35rem/16);
  }

  @media screen and (min-width: 1200px){
    padding: calc(17rem/16) calc(65rem/16);
  }
  
  @media screen and (max-width: 767px){
     white-space: normal;
  }
}

.btn__icon{
  vertical-align: -1px;
  margin-right: 5px;
  font-size: 13px;
}

.btn.btn-sm{
  padding: calc(10rem/16) calc(30rem/16);
  font-size: calc(14rem/16);
}
.btn.btn-md{
  padding: calc(14rem/16) calc(30rem/16);
  line-height:calc(20rem/16);
  font-size: calc(14rem/16);
}

.btn.btn-white,
.btn.btn-light{
  color: #fff;
}

.btn-white:hover,
.btn-light:hover{
  background: #fff;
  color: var(--color-default);
  border-color: #fff;
}

.btn-default:hover{
  background: var(--color-default);
  color: #fff;
}

.btn.btn-primary{
  background: var(--color-primary);
  color: #fff;
  border-color: transparent;
}

.btn.btn-primary:hover{
  border-color: var(--color-default);
  background: var(--color-default);
  color: var(--color-primary);
}

.btn.btn-icon{
  width: calc(40rem/16);
  height: calc(40rem/16);
  padding: calc(15rem/16);


  @media screen and (min-width: 768px){
    width: calc(45rem/16);
    height: calc(45rem/16);
  }

  @media screen and (min-width: 992px){
    width: calc(60rem/16);
    height: calc(60rem/16);
  }
}

.btn.btn-icon .icon{
  font-size: calc(18rem/16);
  margin-right: calc(8rem/16);
  @media screen and (min-width: 768px){
    font-size: calc(25rem/16);
  }
}

.btn.btn-icon--md{
    width: calc(48rem/16);
    height: calc(48rem/16);
    padding: calc(0rem/16);
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px){
        width: calc(60rem/16);
        height: calc(60rem/16);
    }
}

.btn.btn-icon--md .icon{
    font-size: calc(18rem/16);
    margin-right: calc(0rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(25rem/16);
    }
}

.btn.btn-icon-text{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(15rem/16);
}

.btn.btn-icon-text .icon{
  font-size: calc(18rem/16);
  margin-right: calc(8rem/16);
  @media screen and (min-width: 768px){
    font-size: calc(25rem/16);
  }
}
.btn.btn-icon-text.btn-icon-text--sm .icon{
    font-size: calc(16rem/16);
}


.btn.btn-no-style{
  border: none;
  padding: 0;
}
.btn.btn-no-radius{
    border-radius: 0;
}

.btn.btn-no-style:focus{
  outline: none;
  box-shadow: none;
}

.btn.btn-dark{
  background-color: var(--color-default);
  color: #fff;
  border-color: var(--color-default);
}

.btn.btn-dark:hover{
  background-color: #313131;
  border-color: #313131;
}

.btn.btn-grey {
    background-color: var(--color-grey-100);
    color: var(--color-default);
    border-color: var(--color-grey-100);
}

.btn.btn-grey:hover,
.btn.btn-grey:focus,
.btn.btn-grey:not(:disabled):not(.disabled):active {
    background-color: var(--color-default);
    color: var(--color-grey-100);
    border-color: var(--color-default);
}

.btn.btn-grey:focus {
    box-shadow: 0 0 0 .2rem rgba(224,231,227,.5);
}

/*Number Spinner*/
.btn.btn-number-spinner{
  width: calc(40rem/16);
  height: calc(40rem/16);
  padding: calc(5rem/16);

  background-color: var(--color-default);
  color: #fff;

  border-radius: 0;
}

.btn-number-spinner .icon {
  font-size: calc(13rem/16);
}
.number-spinner--sm{
    width: calc(90rem/16);
    flex-shrink: 0;
}
.number-spinner--sm .btn.btn-number-spinner{
    width: calc(30rem/16);
    height: calc(30rem/16);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.number-spinner--sm .btn.btn-number-spinner .icon{
    font-size: calc(5rem/16);
}
.number-spinner--sm .number-spinner__input{
    font-size: calc(14rem/16);
    font-family: var(--font-bold);
    padding: 0 calc(8rem/16);
}

.btn-cart .btn__icon {
  vertical-align: calc(-2rem/16);
  margin-right: calc(7rem/16);
  font-size: calc(20rem/16);
}

.btn.btn-social{
  border-radius: calc(30rem/16);
  font-size: calc(16rem/16);
  text-transform: uppercase;
  padding: calc(7rem/16) calc(40rem/16);
  border: none;

  @media screen and (min-width: 768px){
    padding: calc(7rem/16) calc(60rem/16);
  }
}

.btn-facebook{
  background-color: #3B5999;
  color: #fff;
}

.btn-facebook:hover{
  color: #fff;
  border: none;
}

.btn-google{
  background-color: #DC4F40;
  color: #fff;
}

.btn-google:hover{
  color: #fff;
  border: none;
}

.btn.btn-tooltip{
  background: var(--color-grey);
  width: calc(14rem/16);
  height: calc(14rem/16);
  font-size: calc(10rem/16);
  text-transform: none;
  pointer-events: auto;
}

.btn.btn-tooltip.text-white{
  color: #fff;
}

.btn-tooltip__i{
    letter-spacing: 0;
}

.btn.btn-link{
  border: none;
  padding: 0;
  text-decoration: none;
  letter-spacing: calc(6rem/16);
  text-transform: none;
}
.btn.btn-link--light{
    border: none;
    padding: 0;
    text-decoration: none;
    font-family: var(--font-default);
    font-size: calc(18rem/16);
    line-height: calc(24/18);
    letter-spacing: calc(1rem/16);
    text-transform: none;
    border-radius: 0;
}
.btn.btn-link--light:focus{
    outline: none;
    box-shadow: none;
}
/*todo add to stylelab*/
.btn-radius-md {
    border-radius: calc(10rem / 16);
}
.btn-radius-sm {
    border-radius: calc(6rem / 16);
}

.btn.disabled{
    opacity: 0.5;
    pointer-events: none;
}
.btn.btn-xs.btn-icon {
  width:calc(18rem/16);
  height:calc(18rem/16);
}
.btn.btn-no-style.btn-primary:active, .btn.btn-no-style.btn-primary:focus {
  box-shadow:none !important;
}
.btn.btn-no-style.btn-primary.btn-icon {
  background:none;
}
.btn.btn-no-style.btn-primary.btn-icon .icon {
  color:var(--color-primary);
}
.btn.btn-xs.btn-icon .icon {
  font-size:calc(14rem/16);
}
.btn-arrow {
  background-color:transparent;
  border-color:transparent;
  color:var(--color-primary);
}
.btn-xs:not(.btn-icon) {
  padding: calc(6rem/16) calc(15rem/16);
  font-size: calc(14rem/16);
}

.btn-circle{
    width: calc(45rem/16);
    height: calc(45rem/16);
    border-radius: 50%;
    background-color: #fff;
    color: var(--color-default);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: calc(14rem/16);
    line-height: 1;
    cursor: pointer;
    transition: all 150ms ease;

    @media screen and (min-width: 768px){
        width: calc(60rem/16);
        height: calc(60rem/16);
        font-size: calc(18rem/16);
    }
}

.btn-circle:focus{
    border: none;
    outline: none;
}

.btn-circle:hover{
    background-color: var(--color-default);
    color: #fff;
}

.btn-line-arrow{
    padding: 0;
    font-size: calc(16rem/16);
    font-family: var(--font-black);
    text-transform: uppercase;
    padding-bottom: calc(15rem/16);
    position: relative;
    overflow: hidden;
}

.btn-line-arrow:after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(4rem/16);
    transform: translateY(2px);
    background-color: var(--color-default);
    transition: transform 200ms ease;
}

.btn-line-arrow:hover:after{
    transform: translateY(0);

}

.btn-line-arrow .icon{
    margin-left: calc(15rem/16);
    font-size: calc(13rem/16);
    display: inline-block;
}

.btn-outline-white{
    color: #fff;
    border-color: #fff;
}

.btn-outline-white:hover{
    background-color: #fff;
    color: var(--color-default);
}

.btn.btn-icon-with-text{
    display: flex;
    align-items: center;
    gap: calc(12rem/16);
    font-family: var(--font-default);
    text-transform: none;
    border: none;
    padding: 0;
    font-size: calc(18rem/16);
    line-height: calc(20/18);
    letter-spacing: normal;
}
.btn.btn-icon-with-text:hover{
    color: var(--color-primary);
}
.btn.btn-icon-with-text .icon{
    font-size: calc(22rem/16);
    line-height: 1;
}
.btn.btn-icon-with-text .icon.icon-arrow-down,
.btn.btn-icon-with-text .icon.btn-icon-with-text__icon-sm{
    font-size: calc(12rem/16);
}