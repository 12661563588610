.embed-responsive-recommendation-img:before {
    padding-top:66.66%;

    @media screen and (min-width: 768px){
        padding-top: 91.65%;
    }
}

.embed-responsive-recommendation-product:before {
    padding-top: 61.8%;
}

.recommendation-slide{
    overflow: hidden;
    line-height: calc(24/18);
    @media screen and (min-width: 768px){
       max-height: calc(872rem/16);
    }
}

.recommendation-slide__body{
    background-color: var(--color-default);
    color: #fff;
    position: relative;
    padding-bottom: calc(20rem/16);
    @media screen and (min-width: 768px){
       height: 100%;
        padding: calc(75rem/16) 0;
        padding-bottom: calc(275rem/16);
    }
}

.recommendation-slide__body:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 875px 200px;
    border-color: transparent transparent var(--color-default) transparent;
    position: absolute;
    left: calc(1rem/16);
    top: 0;
    transform: translateX(-100%);
}

.recommendation-slide__body .btn{
    @media screen and (min-width: 768px) and (max-width: 1200px){
       padding: calc(17rem/16) calc(20rem/16);
    }
}
.recommendation-slide__product-img{
    padding: calc(10rem/16);
    background-color: #fff;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 767px){
        max-width: 80%;
        margin: 0 auto;
        margin-top: calc(-40rem/16);
    }
}

.recommendation-slide__product-img .embed-responsive{
    overflow: visible;
}

.recommendation-slide__title{
    font-size: calc(34rem/16);
    letter-spacing: calc(5rem/16);
    margin-top: calc(30rem/16);
}

.recommendation-slide__price{
    font-size: calc(50rem/16);
    font-family: var(--font-black);
    margin-top: calc(20rem/16);

    @media screen and (min-width: 1200px){
        margin-top: calc(40rem/16);
    }

}

.recommendation-slide__quote{
    font-size: calc(18rem/16);
    line-height: calc(33/22);
    margin-top: calc(30rem/16);
    position: relative;

    @media screen and (min-width: 768px){
        margin-top: calc(110rem/16);
    }
    @media screen and (min-width: 1200px){
        font-size: calc(22rem/16);
    }
}

.recommendation-slide__quote-content{
    position: relative;
    z-index: 1;
}

.recommendation-slide__quote-author{
    font-family: var(--font-black);
    font-size: calc(17rem/16);
    letter-spacing: calc(5rem/16);
    text-transform: uppercase;
    margin-top: calc(15rem/16);
    white-space: nowrap;

    @media screen and (min-width: 1200px){
        font-size: calc(20rem/16);
    }
}

.recommendation-slide__quote:before{
    content: '"';
    font-size: calc(300rem/16);
    color: #2d2d2d;
    position: absolute;
    left: calc(-20rem/16);
    top: calc(20rem/16);
    line-height: 0.5;
    display: block;
    font-family: var(--font-bold);
    font-style: italic;
    z-index: 0;

    @media screen and (min-width: 768px){
        left: calc(-155rem/16);
        font-size: calc(600rem/16);
    }
}

.recommendation-slide__col-offset{
    position: relative;
    
    @media screen and (min-width: 768px){
        left: calc(-90rem/16);
    }

    @media screen and (min-width: 1200px){
        left: calc(-120rem/16);
    }
}

.recommendation-slide-nav-item__img{
    position: relative;
    overflow: hidden;
}

.recommendation-slide-nav-item__img img{
    border-radius: 50%;
}

.recommendation-slide-nav-item__title{
    font-size: calc(11rem/16);
    letter-spacing: calc(3rem/16);
    line-height: calc(19/16);
    color: #fff;
    text-transform: uppercase;
    font-family: var(--font-black);
    margin-top: calc(10rem/16);
    text-align: center;
    text-shadow: 1px 1px 4px rgba(0,0,0,.5);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        letter-spacing: calc(6rem/16);
    }
}

.recommendation-slide__badge{
    width: calc(150rem/16);
    background-color: var(--color-primary);
    color: #fff;
    text-transform: uppercase;
    font-size: calc(9rem/16);
    letter-spacing: calc(1.82rem/16);
    line-height: calc(20/17);
    text-align: center;
    font-family: var(--font-black);
    position: absolute;
    padding: calc(4rem/16) 0;
    transform: rotate(45deg);
    top: calc(13rem/16);
    right: calc(-55rem/16);

    @media screen and (min-width: 768px){
        top: calc(30rem/16);
        right: calc(-38rem/16);
        font-size: calc(17rem/16);
    }
}

.recommendation-slide__product-img .recommendation-slide__badge{
    padding: calc(8rem/16) 0;
    width: calc(170rem/16);
    top: calc(23rem/16);
    right: calc(-48rem/16);
    
    @media screen and (max-width: 767px){
       font-size: calc(16rem/16);
    }
}

.recommendation-slider{
    position: relative;
    background-color: var(--color-darkgrey);

    @media screen and (max-width: 767px){
        padding-top: calc(170rem/16);
    }
}

.recommendation-slider__main{
    line-height: 0;
}

.recommendation-slider__nav{
    @media screen and (min-width: 768px){
       max-width: calc(700rem/16);
        width: 100%;
        margin: 0 auto;
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
    }

    @media screen and (max-width: 767px){
        position: absolute;
        top: calc(25rem/16);
        left: calc(25rem/16);
        right: calc(25rem/16);
    }

}

.recommendation-slider__nav .slick-slide{
    margin: 0 calc(11rem/16);
    opacity: 0.4;
    transition: opacity 250ms ease;
}

.recommendation-slider__nav .slick-slide.slick-current,
.recommendation-slider__nav .slick-slide:hover{
    opacity: 1;
}

.recommendation-slide-nav-item{
    cursor: pointer;
}

.recommendation-slider .slick-arrow{
    font-size: calc(6rem/16);
    color: #fff;
    width: calc(26rem/16);
    height: calc(26rem/16);
    border-radius: 50%;
    background-color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media screen and (min-width: 768px){
        margin-top: calc(-18rem/16);
        width: calc(62rem/16);
        height: calc(62rem/16);
        font-size: calc(15rem/16);
    }
}

.recommendation-slider .slick-arrow.slick-next {
    right: calc(-20rem/16);
    @media screen and (min-width: 768px){
        right: calc(-125rem/16);
    }
}

.recommendation-slider .slick-arrow.slick-prev {
    left: calc(-20rem/16);

    @media screen and (min-width: 768px){
        left: calc(-125rem/16);
    }
}