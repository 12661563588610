.navigation-teaser-small {
    height: fit-content;
}

.navigation-teaser-small .img-teaser__body {
    @media screen and (min-width: 768px){
        padding: calc(15rem/16) calc(20rem/16);
    }
}

.navigation-teaser-small .img-teaser__hl {
    @media screen and (min-width: 1200px){
        font-size: calc(22rem/16);
    }
}