.header-img{
    position: relative;
}

.header-img__badge{
    width: calc(120rem/16);
    height: calc(100rem/16);
    padding: calc(10rem/16);
    background-color: #fff;
    box-shadow: 0 16px 38px -11px rgba(0,0,0,.25);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    @media screen and (min-width: 768px){
        width: calc(190rem/16);
        height: calc(190rem/16);
        padding: calc(20rem/16);
        bottom: calc(-45rem/16);
    }
}

.header-img__badge:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 60px 0 60px;
    border-color: #ffffff transparent transparent transparent;
    bottom: 0;
    left: 0;
    position: absolute;
    transform: translateY(100%);
    @media screen and (min-width: 768px){
        border-width: 20px 95px 0 95px;
    }
}

.header-img__badge-text{
    padding: calc(5rem/16);
    font-size: calc(15rem/16);
    letter-spacing: calc(2rem/16);
    background-color: var(--color-primary);
    color: #fff;
    font-family: var(--font-black);
    text-transform: uppercase;
    position: absolute;
    bottom: calc(10rem/16);
    left: calc(-10rem/16);
    right: calc(-10rem/16);
    text-align: center;

    @media screen and (min-width: 768px){
        padding: calc(7rem/16) calc(10rem/16);
        bottom: calc(20rem/16);
        font-size: calc(20rem/16);
        letter-spacing: calc(6rem/16);
    }
}

.header-img__badge--grey .header-img__badge-text{
    background-color: var(--color-grey);
}

.header-img__badge-text:before{
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 0;
    border-color: transparent #8A3848 transparent transparent;
    position: absolute;
    bottom: calc(-10rem/16);
    left: 0;
}

.header-img__badge--grey .header-img__badge-text:before {
    border-color: transparent #4e4e4e transparent transparent;
}

.header-img__badge-text:after{
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 0;
    border-color: #8A3848 transparent transparent transparent;
    position: absolute;
    bottom: calc(-10rem/16);
    right: 0;
}

.header-img__badge--grey .header-img__badge-text:after {
    border-color: #4e4e4e transparent transparent transparent;
}
