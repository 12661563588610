/*GENERAL*/

.tvt-back-link {

}

.tvt-back-link .icon {

}

/*MODELL*/

.tvt-persons-tabs {
    display: block;
    font-family: var(--font-black);
    letter-spacing: calc(5rem/16);
    margin-bottom: calc(20rem/16);
}

.tvt-persons-tabs .nav-item+.nav-item {
    margin-top: calc(10rem/16);
}

.tvt-persons-tabs .nav-link {
    padding: calc(20rem/16) calc(25rem/16);
    transition: all 250ms ease-in-out;

}

.tvt-persons-tabs .nav-link.active,
.tvt-persons-tabs .nav-item:not(.tvt-persons-tabs__nav-item--add-person) .nav-link:hover {
    box-shadow: 0px 4px 14px -1px rgba(0,0,0,0.5);
}

.tvt-persons-tabs__icon {
    vertical-align: calc(-2rem/16);
    margin-right: calc(20rem/16);

    @media screen and (max-width: 768px){
        margin-right: calc(10rem/16);
    }

}

.tvt-persons-tabs__nav-item--add-person {
    position: relative;
}

.tvt-persons-tabs__nav-item--add-person .nav-link {
    opacity: 0.5;
}

.tvt-persons-tabs__nav-item--add-person:after {
    content: var(--icon-plus);
    font-family: "iconfont";
    font-size: calc(20rem/16);
    position: absolute;
    color: var(--color-primary);
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

/*DATEN*/

.tvt-package-radio__label {
    cursor: pointer;
    transition: all 250ms ease-in-out;
    text-align: center;
    background: var(--color-grey-100);
    color: var(--color-grey);
}

.tvt-package-radio:checked + .tvt-package-radio__label,
.tvt-package-radio__label:hover {
    box-shadow: 0px 2px 3px 1px rgba(0,0,0,.5);
    background: var(--color-lightgrey);
    color: var(--color-default);
    position: relative;
}

.tvt-package-radio:checked + .tvt-package-radio__label:after {
    content: var(--icon-check);
    font-family: iconfont;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.has-error .tvt-package-radio__label {
    border-bottom: 2px solid var(--color-danger);
}

.tvt-package-radio__hl {
    letter-spacing: calc(5rem/16);
}

.form-group .tvt-form__label--big,
.tvt-form__label--big{
    font-size: calc(24rem/16);
    line-height: calc(26/26);
    letter-spacing: calc(5rem/16);
    font-family: var(--font-black);
    text-transform: uppercase;
}

.tvt-btn-info {
    background: var(--color-primary);
    border-radius: 50%;
    width: calc(20rem/16);
    height: calc(20rem/16);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: calc(14rem/16);
    cursor: pointer;
    position: relative;
    top: calc(-3rem/16);
    margin-left: calc(3rem/16);
}

.tvt-btn-info span {
    color: #fff;
}

.tvt-form-tab-error span {
    color: var(--color-danger);
}