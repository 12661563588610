.ui-datepicker {
    border: 0;
    box-shadow: 0 0 30px 0 rgba(0,0,0,.15);
    background-color: #fff;
    z-index: 1150 !important; /* higher as modal */

    @media screen and (min-width: 768px) {
        width:350px;
    }
}
.ui-datepicker td {
    padding-left: 0;
    padding-right: 0;
}
.ui-datepicker td a,
.ui-datepicker td span {
    padding: .2em .4em;
}

.ui-datepicker.is-loading {
    /*opacity: .2;*/
    /*pointer-events: ;*/
}
/*.ui-datepicker table .ui-state-active {
    background: inherit;
    color: inherit;
}*/

.ui-datepicker table .is-loading > a,
.ui-datepicker table .is-loading > span {
    opacity: .2;
}
.ui-datepicker .ui-state-disabled {
    opacity: .4;
}
.ui-datepicker table .ui-state-active,
.ui-datepicker table .ui-state-hover,
.ui-datepicker td a:hover {
    background: var(--color-primary);
    color: #fff;
}

/* arrows */
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
    text-align: center;
    line-height: 1.8em;
    cursor: pointer;
    top: 4px;
    color: var(--color-primary);
}
.ui-datepicker-next:hover,
.ui-datepicker-prev:hover {
    text-decoration: none;
}

.ui-datepicker-prev:before {
    font-family: iconfont;
    content: var(--icon-arrow-left);
}

.ui-datepicker-next {
    transform:scaleX(-1);
}
.ui-datepicker-next:after {
    font-family: iconfont;
    content: var(--icon-arrow-left);
}