
.slick-arrow{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-grey);
  z-index: 5;
  font-size: calc(42rem/16);
  
  @media screen and (max-width: 767px){
     margin-top: calc(-16rem/16);
    font-size: calc(20rem/16);
  }
}

.product-slide-big__slider .slick-arrow{
  @media screen and (min-width: 768px){
     margin-top: calc(-30rem/16);
  }
  
  @media screen and (max-width: 767px){
     font-size: calc(27rem/16);
  }
}

.slick-arrow.slick-prev{
  left: calc(-8rem/16);

  @media screen and (min-width: 768px){
    left: calc(-40rem/16);
  }
}

.slick-arrow.slick-next{
  right: calc(-8rem/16);

  @media screen and (min-width: 768px){
    right: calc(-40rem/16);
  }
}

.slick-arrow.slick-prev.slick-disabled,
.slick-arrow.slick-next.slick-disabled{
  opacity: 0.3;
}


.img-gallery-slider .slick-dots{
  @media screen and (min-width: 768px){
    position: absolute;
    bottom: 0;
    right: calc(-10rem/16);
    transform: translateX(100%);
    max-width: 150px;
  }
  
  @media screen and (min-width: 992px){
     max-width: calc(300rem/16);
  }
}

.img-gallery-slider .slick-slide{
  @media screen and (min-width: 768px){
    margin: 0 15px;
  }
}



/* Teaser Slider */

.teaser-slider .slick-slide{
  margin: 0 15px;
}

.teaser-slider:not(.teaser-slider--events) .slick-track .slick-slide:nth-child(even) {
  @media screen and (min-width: 768px){
    margin-top: calc(40rem/16);
  }
}

.teaser-slider .slick-list{
  padding: calc(34rem/16) 0;
  margin: calc(-34rem/16) 0;
}

/* Slick dots */

.slider--hide-dots .slick-dots {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}

.slick-dots{
  list-style: none;
  padding-left: 0;
  text-align: center;
  margin-top: calc(15rem/16);

  @media screen and (min-width: 768px){
    margin-top: calc(30rem/16);
  }
}

.slick-dots>li{
  display: inline-block;
  margin: 0 calc(7rem/16);
  width: calc(20rem/16);
  height: calc(20rem/16);
}

.slick-dots>li button{
  background: none;
  color: transparent;
  background: var(--color-default);
  width: calc(20rem/16);
  height: calc(20rem/16);
  padding: 0;
  border-radius: 50%;
  line-height: 1;
  border: calc(2rem/16) solid #fff;
  cursor: pointer;
  transition: all 150ms ease;
}

.slick-dots>li button:hover{
  background: var(--color-primary);
}

.slick-dots>li.slick-active button{
  background: var(--color-primary);
  line-height: 1;
}


.img-gallery-slider .slick-dots>li button{
  width: calc(13rem/16);
  height: calc(13rem/16);
  background: var(--color-lightgrey);
}

.img-gallery-slider .slick-dots>li.slick-active button,
.img-gallery-slider .slick-dots>li button:hover{
  background: var(--color-primary);
}

.img-gallery-slider .slick-dots>li{
  margin: 0 calc(3rem/16);
  width: calc(13rem/16);
  height: calc(13rem/16);
}

/*Description Slider*/

.description-slider__container{
    width: 100%;
    @media screen and (min-width: 768px){
        margin-left: -6%;
    }
    @media screen and (min-width: 1400px){
        margin-left: calc(-295rem/16);
    }
}
.description-slider.slick-slider .slick-track{
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
}
.description-slider.slick-slider .slick-list{
    overflow: visible;
}
.description-slider .slick-slide{
    float: none;
    display: inline-block;
    height: auto;
    transition: transform 200ms ease-in-out;
    transform-origin: top;
    width: calc(808rem/16);
    max-width: 80vw;
    @media screen and (min-width: 768px){
        margin-left: calc(-55rem/16);
        width: calc(550rem/16);
    }
    @media screen and (min-width: 1200px){
        width: calc(808rem/16);
        margin-left: calc(-90rem/16);
    }
}
.description-slider .slick-dots{
    visibility: hidden;
    height: 0;
    margin: 0;
}
.description-slider .slick-arrow{
    font-size: calc(12rem/16);
    color:var(--color-default);
    background-color: #FFFFFF;
    border-radius: 50%;
    border: solid var(--color-default) calc(2rem/16);
    height: calc(40rem/16);
    width: calc(40rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 30%;
    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        height: calc(60rem/16);
        width: calc(60rem/16);
    }
}
.description-slider .slick-arrow.slick-prev{
    left: 10%;
    @media screen and (min-width: 1400px){
        left: calc(calc(295rem/16) + calc(130rem/16));
    }
}
.description-slider .slick-arrow.slick-next{
    right: 10%;
    @media screen and (min-width: 1400px){
        right: calc(-171rem/16);
    }
}
.description-slider .slick-arrow.slick-prev .icon,
.teaser-slide-three-col .slick-arrow.slick-prev .icon{
    margin-top: calc(0rem/16);
    margin-left: calc(-2rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(-2rem/16);
        margin-left: calc(-3rem/16);
    }
}
.description-slider .slick-arrow.slick-next .icon,
.teaser-slide-three-col .slick-arrow.slick-next .icon{
    margin-top: calc(0rem/16);
    margin-left: calc(2rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(2rem/16);
        margin-left: calc(4rem/16);
    }
}
.description-slider .slick-slide:not(.slick-current), .description-slider .slick-slide:not(.slick-center) {
    transform:scale(0.85);
    transform-origin: top;
    @media screen and (min-width: 768px){
        transform:scale(0.7);
    }
}
.description-slider .slick-slide.slick-current + .slick-slide + .slick-slide{
    transform:scale(0.85) translateX(calc(-175rem/16));
    @media screen and (min-width: 768px){
        transform:scale(0.7) translateX(calc(-175rem/16));
    }
}

.description-slider__item-content{
    margin-top: calc(31rem/16);
    opacity: 0;
    transition: opacity 800ms ease-in-out;
}
.description-slider .description-slider__item-img {
    transition: opacity 800ms ease-in-out;
    opacity: 0.4;
}
.description-slider .slick-slide.slick-current .description-slider__item-img,
.description-slider .slick-slide.slick-current .description-slider__item-content{
    opacity: 1;
}

.description-slider__background{
    font-family: var(--font-black);
    text-transform: uppercase;
    font-size: calc(76rem/16);
    line-height: 1;
    letter-spacing: calc(5rem/16);
    color: var(--color-grey-100);
    position: absolute;
    top: 55%;
    left: calc(10rem/16);
    z-index: -1;
    @media screen and (min-width: 768px){
        font-size: calc(100rem/16);
        letter-spacing: calc(15rem/16);
        top: 67%;
        left: calc(31rem/16);
    }
    @media screen and (min-width: 1200px){
        top: 60%;
        font-size: calc(300rem/16);
        letter-spacing: calc(30rem/16);
    }
}
.description-slider__title{
    font-size: calc(16em/16);
    line-height: calc(19/16);
    letter-spacing: calc(2rem/16);
    font-family: var(--font-black);
    text-transform: uppercase;
}

/*teaser slide three col*/
.teaser-slide-three-col{
    max-width: calc(1081rem/16);
}

.teaser-slide-three-col .slick-slider {
    @media screen and (max-width: 1599px) {
        padding-top: calc(84rem/16);
    }
}

.teaser-slide-three-col .slick-slide{
    height: auto;
}

.teaser-slide-three-col .slick-slide > div{
    height: 100%;
}

.teaser-slide-three-col .slick-arrow{
    font-size: calc(16rem/16);
    color:var(--color-default);
    background-color: #FFFFFF;
    border-radius: 50%;
    border: solid var(--color-default) calc(2rem/16);
    height: calc(60rem/16);
    width: calc(60rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(58rem/16);
    @media screen and (min-width: 768px) {
        top: calc(42rem/16);
    }
    @media screen and (min-width: 1600px){
        top: 48%;
    }
}
.teaser-slide-three-col .slick-arrow.slick-prev{
    left: 28%;
    @media screen and (min-width: 768px){
        left: 0;
    }
    @media screen and (min-width: 1600px){
        left: -8%;
    }
}
.teaser-slide-three-col .slick-arrow.slick-next{
    right: 28%;
    @media screen and (min-width: 768px){
        right: 0;
    }
    @media screen and (min-width: 1600px){
        right: -5%;
    }
}
.teaser-slide-three-col .slick-dots{
    margin: 0;
    height: 0;
    visibility: hidden;
}
.teaser-slide-three-col .slick-slide{
    margin: 0 calc(15rem/16);
}
.teaser-slide-three-col .slick-track{
    display: flex;
    @media screen and (min-width: 768px){
        margin: 0 calc(-15rem/16);
    }
}
.teaser-slide-three-col .slick-list{
    @media screen and (max-width: 767px){
        padding: 0 calc(20rem/16);
    }
}
.teaser-slide-three-col .slick-active{
    transition: opacity 200ms ease-in-out;
}
.teaser-slide-three-col .slick-active + .slick-active + .slick-active{
    opacity: 0.7;
}