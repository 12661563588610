.article-author{
  font-size: calc(15rem/16);
}

.article-author__img{
  border-radius: 50%;
}

.article-author__name{
  font-family: var(--font-bold);
}

.article-img-text{
  margin-bottom: calc(50rem/16);

  @media screen and (min-width: 768px) {
    margin-bottom: calc(70rem/16);
  }

  @media screen and (min-width: 992px){
    margin-bottom: calc(115rem/16);
  }
}

.article-img-text .article-author{
  margin: calc(20rem/16) 0;

  @media screen and (min-width: 992px){
    margin: calc(40rem/16) 0 calc(30rem/16);

  }
}

.article-img-text__body{
  padding: calc(20rem/16) 0 0;

  @media screen and (min-width: 768px){
    padding: calc(10rem/16) 0;
  }

  @media screen and (min-width: 992px){
    padding: calc(50rem/16) 0;
  }
}

.article-img-text__hl{
  display: inline-block;
  transition: all 150ms ease;
  @media screen and (min-width: 768px){
     font-size: calc(35rem/16);
    letter-spacing: calc(13rem/16);
  }
}

.article-img-text__hl:hover{
  color: var(--color-primary);
}


.article-img-text.text-md-right .link-with-icon{
  @media screen and (min-width: 768px){
    float: right;
  }
}


.list-article__load-more{
  position: relative;
}

.list-article__load-more .btn{
  font-size: calc(16rem/16);
  letter-spacing: calc(6rem/16);
  padding: calc(5rem/16);
  text-transform: none;
  margin-top: calc(30rem/16);
}

.list-article__load-more .btn:hover{
  color: var(--color-primary);
}

.list-article__load-more:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  height: calc(430rem/16);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
}