.geometry-finder__explanation{
  margin-bottom: calc(50rem/16);
}

.geometry-finder__hl{
  text-transform: none;
  font-size: calc(22rem/16);
  letter-spacing: calc(2rem/16);
  margin-bottom: calc(20rem/16);
  /*font-family: var(--font-bold);*/
}

.tab-content--geometry-finder{
  padding: calc(30rem/16);
}

.geometry-finder__hl-small{
  font-size: calc(20rem/16);
  font-family: var(--font-black);
  letter-spacing: calc(1.5rem/16);
}

.geometry-finder table thead th{
  font-size: calc(15rem/16);
}
.geometry-finder table thead th,
.geometry-finder table tbody td{
  padding: calc(5rem/16);
  
  @media screen and (min-width: 768px) and (max-width: 1050px){
     font-size: calc(15rem/16);
  }
}

.geometry-finder .hidden{
  display: none;
}

.geometry-filter .radio-inline {
  margin-right: calc(15rem/16);
  font-family: var(--font-default);
  text-transform: none;
  font-size: calc(17rem/16);
}

.geometry-finder .nav-tabs .nav-link {
  padding: calc(15rem/16) calc(20rem/16);
  font-size: calc(14rem/16);
  letter-spacing: calc(5rem/16);

  @media screen and (min-width: 1200px){
    font-size: calc(15rem/16);
  }
}

.result-group ul {
  display: block;
  list-style-type: none;
}

.geometry-result ul {
  position: relative;
  border: 1px solid #ccc;
  padding: 0 calc(15rem/16);
  margin-bottom: calc(35rem/16);
}

.geometry-result ul li {
  border-bottom: 0;
  padding: calc(3rem/16) calc(15rem/16) 0 calc(15rem/16);
  margin-top: calc(10rem/16);
}
.geometry-result ul li+li {
  margin-top: 0;
  border-bottom: calc(1rem/16) solid #dedede;
}

.result-group li {
  position: relative;
  display: block;
}

.buffer-bottom {
  margin-bottom: calc(29rem/16);
}

.result-group--headline {
  display: block;
  position: relative;
  margin: calc(7rem/16) 0 calc(12rem/16) 0;
  font-size: calc(24rem/16);
}

.geometry-result strong {
  display: block;
}

.geometry-result ul li+li:last-child {
  border-bottom: 0;
  margin-bottom: calc(10rem/16);
}

@media screen and (max-width: 767px){
   .geometry-finder .nav-tabs{
     height: 100%;
     display: block;
   }

  .geometry-finder .nav-tabs .nav-item:not(:last-child){
    margin: 0;
    margin-bottom: calc(10rem/16);
  }
}