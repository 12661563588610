.delivery-overview__title,
.delivery-overview__subtitle {
    font-family: "Kuunari-BlackCondensed", sans-serif;
    text-align: center;
    letter-spacing: 7.5px;
    font-weight: 500;
    text-transform: uppercase;
}
.delivery-overview__title {
    margin-bottom: 10px;
}
.delivery-overview__subtitle {
    letter-spacing: 4px;
    margin-top: 10px;
}
.deliver-overview__table thead th,
.delivery-overview__table1 tbody tr:first-child td,
.delivery-overview__table2 tbody tr:first-child td {
    font-family: "OpenSansCondensed-Light", sans-serif;
    font-weight: 700;
    border: 0;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    background: #282829;
    padding: 16px 18px;
    text-align: left;
}
.deliver-overview__table--sub td,
.deliver-overview__table--sub th,
.delivery-overview__table1 td,
.delivery-overview__table1 th,
.delivery-overview__table2 td,
.delivery-overview__table2 th {
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}
.deliver-overview__table--sub td,
.delivery-overview__table1 td,
.delivery-overview__table2 td {
    padding: 22px 18px;
    font-family: "OpenSansCondensed-Light", sans-serif;
}

.delivery-overview__table1 tr,
.delivery-overview__table2 tr,
.delivery-overview__table1 table,
.delivery-overview__table2 table {
    border: none;
    border-collapse: collapse;
}
.delivery-overview__header {
    font-family: "OpenSansCondensed-Light", sans-serif;
    font-size: 12px;
    vertical-align: top;
}
.delivery-overview__header .delivery-overview__date{
    text-align: center;
    vertical-align: top;
}
.delivery-overview__header .delivery-overview__logo {
    text-align: right;
    vertical-align: top;
}