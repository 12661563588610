.product-cart-item{
    padding: calc(30rem/16) 0;
}
.product-cart-item + .product-cart-item{
    border-top: 2px solid var(--color-lightgrey);
}
.product-cart-item__body{
    margin-top: calc(17rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(47rem/16);
    }
}

.product-cart-item__remove{
    font-family: var(--font-default);
    text-transform: none;
}

.product-cart-item__price{
    font-size: calc(34rem/16);
    line-height: 1;
}

.product-cart-item__single-price{
    font-size: calc(18rem/16);
    letter-spacing: calc(2rem/16);
    margin-right: calc(20rem/16);
}

.product-cart-item__price-text{
    font-size: calc(16rem/16);
    margin-bottom: calc(5rem/16);
    line-height: calc(18/16);
}

.product-cart-item__remove .icon{
    vertical-align: calc(-2rem/16);
}

.product-cart-item__hl{
    letter-spacing: calc(2rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(24rem/16);
        line-height: calc(24/24);
    }
}

.product-cart-item__timer{
    font-family: var(--font-black);
    letter-spacing: calc(2rem/16);
    margin-top: calc(10rem/16);
}

.product-cart-item .btn.btn-number-spinner{
    width: calc(30rem/16);
    height: calc(30rem/16);
    padding: 0;
}

.product-cart-item .btn-number-spinner .icon {
    font-size: calc(8rem/16);
    vertical-align: calc(2rem/16);
}

.product-cart-item .number-spinner__input.form-control{
    padding: 0 calc(5rem/16);
    width: calc(30rem/16);
    flex: 0;
}

.product-cart-item .alert{
    @media screen and (max-width: 767px){
       padding: calc(5rem/16);
        font-size: calc(16rem/16);
    }
}

.product-cart-item__detail-link{
    @media screen and (max-width: 767px){
       font-size: calc(16rem/16);
    }
}

.product-cart-item__feature-key{
    font-size: calc(14rem/16);
    line-height: calc(16/14);
    text-transform: uppercase;
}
.product-cart-item__feature-value{
    font-size: calc(14rem/16);
    line-height: calc(19/14);
    font-family: var(--font-bold);
    text-transform: uppercase;
}
.product-cart-item__price{
    font-size: calc(18rem/16);
    line-height: calc(20/18);
    letter-spacing: calc(1.13rem/16);
    font-family: var(--font-black);
}
.product-cart-item .alert {
    padding: calc(17rem / 16) calc(31rem / 16);
    font-size: calc(14rem / 16);
    line-height: calc(16/14);
}
.product-cart-item .alert p{
    margin-bottom: 0;
}
.product-cart-item__bottom{
    font-size: calc(16rem/16);
    line-height: calc(20/16);
    letter-spacing: calc(-0.2rem/16);
    display: flex;
    flex-wrap: wrap;
    gap: calc(17rem/16);
    @media screen and (min-width: 768px){
        justify-content: space-between;
    }
}
.product-cart-item__bottom .btn{
    font-size: calc(16rem/16);
    line-height: calc(20/16);
    letter-spacing: calc(-0.2rem/16);
}
.product-cart-item__buttons{
    display: flex;
    align-items: center;
    gap: calc(17rem/16);
    flex-wrap: wrap;
}