.custom-radio.custom-radio--bordered{
    padding: 0;
    max-height: calc(40rem/16);
    position: relative;
}

.custom-radio--bordered label{
    padding: calc(10rem/16) calc(10rem/16) calc(10rem/16);
    color: var(--color-darkgrey);
    font-size: calc(18rem/16);
    font-family: var(--font-black);
    text-transform: uppercase;
    line-height: 1;

    @media screen and (min-width: 768px){
        padding: calc(10rem/16) calc(38rem/16);
    }
}

.custom-radio--bordered .custom-radio__text{
    position: relative;
}

.custom-radio__border {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid;
}

.custom-radio--bordered .custom-radio__input:checked ~ .custom-radio__border,
.custom-radio--bordered label:hover .custom-radio__border{
    background-color: var(--color-darkgrey);
}

.custom-radio--bordered .custom-radio__input:checked ~ .custom-radio__text,
.custom-radio--bordered label:hover .custom-radio__text{
    color: #fff;
}