.history__years {
    color: white;
    position: absolute;
    top: 40vh;
    z-index: 9;
    transition: all 200ms ease;

    @media screen and (min-width: 768px) {
        top: calc(168rem/16);
        left: 0;
        right: 0;
    }

    @media screen and (min-width: 768px) and (max-width: 1430px){
        max-width: calc(1430rem/16); /* 1400px breite + 30px padding */
        width: 100%;
        padding-right: calc(15rem/16);
        padding-left: calc(15rem/16);
    }

}

.history__years.hide{
    opacity: 0;
    visibility: hidden;
}

.history__years-current {
    display: flex;
    overflow: hidden;
    position: relative;
    max-width: 100vw;
    transition: all 200ms ease;
}

.history__years-current>li:first-child{
    margin-left: calc(15rem/16);
    @media screen and (min-width: 1430px){
        margin-left: calc((100vw - 1430px)/2);
    }
}

.is-empty .history__years-current-item-text{
    width: calc(625rem/16);
}

.history__years-current-item-text{

    font-family: var(--font-black);
    font-size: calc(75rem/16);
    text-transform: uppercase;
    letter-spacing: calc(27rem/16);
    line-height: 1.2;
    -webkit-text-stroke: calc(1rem/16) #c6c6c6;
    color: transparent;
    transition: all 250ms ease;

    @media screen and (min-width: 768px){
        width: calc(400rem/16);
        font-size: calc(115rem/16);
        letter-spacing: calc(40rem/16);
        line-height: 1;
    }
    @media screen and (min-width: 992px){
        width: calc(480rem/16);
        font-size: calc(130rem/16);
        letter-spacing: calc(46rem/16);
    }
    @media screen and (min-width: 1100px){
        width: calc(520rem/16);
        font-size: calc(135rem/16);
        letter-spacing: calc(52rem/16);
    }
    @media screen and (min-width: 1280px){
        width: calc(578rem/16);
        font-size: calc(145rem/16);
        letter-spacing: 4.95rem;
        padding-left: calc(15rem/16);
    }
    @media screen and (min-width: 1400px){
        width: calc(625rem/16);
        font-size: calc(160rem/16);
        letter-spacing: calc(78rem/16);
    }

    @media screen and (min-width: 768px) and (max-height: 630px){
        width: calc(255rem/16);
        font-size: calc(80rem/16);
        letter-spacing: calc(25rem/16);
    }

    @media screen and (max-width: 767px){
       width: calc(225rem/16);
    }
    
}

.is-custom-content .history__years-current-item-text{
    font-size: calc(32rem/16);
    letter-spacing: calc(4rem/16);
    line-height: 1.1;
    width: calc(220rem/16);

    @media screen and (min-width: 768px){
        width: 650px;
        font-size: 70px;
        letter-spacing: 10px;
    }
    @media screen and (min-width: 1100px){
        width: 650px;
        font-size: 80px;
        letter-spacing: 12px;
    }
}

.history__years-current-items.active .history__years-current-item-text{
    color: #c6c6c6;
    -webkit-text-stroke: unset;
}

ul.history__years-list {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    line-height: 1.6;
    max-width: calc(300rem/16);
    position: relative;
    padding: 0 calc(8rem/16);

}

.history__years-arrow, .history__years-item {
    font-size: calc(14rem/16);
}
.history__years-item {
    flex: 1 0 15%;
    max-width: 15%;
}

.history__years-item:last-child{
    flex: 1 0 12%;
    max-width: 12%;
}

.is-custom-content .history__years-item{
    flex: none;
    max-width: none;
    white-space: nowrap;
    margin-right: calc(15rem/16);
}
.history__years-link{
    color: #c6c6c6;
    font-size: calc(14rem/16);
    letter-spacing: calc(1rem/16);
    transition: all 150ms ease;
}

.history__years-link:hover,
.history__years-link:active,
.history__years-link.active {
    font-weight: 700;
    color: #fff;
}


.history__years-arrow{
    background-color: transparent;
    border: none;
    color: #c6c6c6;
    font-size: calc(7rem/16);
    height: calc(20rem/16);
}

.history__years-arrow:not(.disable){
    cursor: pointer;
}

.history__years-arrow:not(.disable):hover,
.history__years-arrow:focus{
    outline: none;
    color: #fff;
}
.history__years-arrow.disable{
    opacity: 0.4;
}

.h-auto{
    height: auto!important;
}

.history-main.has-fade-in{
    opacity: 0;
    transition: all 2500ms ease;
    transition-delay: 350ms;

}

.history-main.has-fade-in.show{
    opacity: 1;
}

.history-main.has-zoom{
    opacity: 0;

    transition: all 2000ms ease;
    transition-delay: 350ms;
    transform: scale(1.05);
    transform-origin: top;
}

.history-main.has-zoom.show{
    opacity: 1;
    transform: scale(1);
}

.intro-animation{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    background: #121112;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 900ms ease;
    visibility: visible;
    opacity: 1;
    pointer-events: none;
    @media screen and (max-width: 767px){
        padding: 20px;
    }
}

.intro-animation.hide{
    opacity: 0;
}

.intro-animation__text{
    opacity: 0;
    transform: scale(0.9);
    animation: scaleUp 2800ms;
    animation-fill-mode: forwards;
    animation-delay: 500ms;
    
    font-family: var(--font-black);
    font-size: calc(40rem/16);
    letter-spacing: calc(4rem/16);
    text-transform: uppercase;
    padding: calc(0rem/16) calc(30rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(90rem/16);
        letter-spacing: calc(7.5rem/16);
    }
    
    @media screen and (max-width: 767px){
       width: 100%;
        text-align: center;
    }
    
}

@keyframes scaleUp {
    0% {
        opacity: 0;
    }
    10%{
        transform: scale(0.9);

    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.overflow-hidden{
    overflow: hidden;
}

/*
.intro-video{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    background: #121112;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 350ms ease;
}

.intro-video__video{
    max-width: 100%;
}

.intro-video.hide{
    opacity: 0;
    visibility: hidden;
}
*/
