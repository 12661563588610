/* benefits */
.job-benefits-item .wysiwyg>ol:not(:last-child), .job-benefits-item .wysiwyg>p:not(:last-child), .job-benefits-item .wysiwyg>table:not(:last-child), .job-benefits-item .wysiwyg>ul:not(:last-child) {
    margin-bottom:1rem;
}
.job-benefits__headline {
    margin-bottom: 3rem;
    @media screen and (min-width: 768px){
        margin-bottom: 6.125rem;
    }
}
.job-benefits-item__icon {
    color: var(--color-primary);
    font-size: calc(30rem/16);
    margin-right: calc(10rem/16);
}
.job-benefits-item__title{
    font-size: calc(20rem/16);
    font-family: var(--font-black);
    text-transform: uppercase;
    letter-spacing: calc(1rem/16);
}
/* offers */

.job-offer__title{
    font-family: var(--font-black);
    text-transform: uppercase;
    font-size: calc(28rem/16);
    line-height: calc(35/35);
    margin-bottom: 2rem;
    letter-spacing: 4px !important;
    @media screen and (min-width: 768px){
        max-width: 33vw;
        font-size: calc(40rem/16);
        line-height: calc(55/40);
        margin-bottom: 3.5rem;
    }
}

.job-offer-teaser {
    position: relative;
    background-color: var(--color-lightgrey);
    padding: 1rem;
    @media screen and (min-width: 768px){
        padding: 2.25rem 2rem;
    }
}

.job-offer-teaser__department {
    text-transform: uppercase;
    color: var(--color-primary);
    letter-spacing: calc(2rem/16);
    font-family: var(--font-bold);
    font-size: calc(14rem/16);
    margin-bottom: .5rem;
}

.job-offer-teaser__title {
    margin-bottom: 1rem;
    @media screen and (min-width: 768px){
        margin-bottom: 2.25rem;
    }
}

.job-offer-teaser__employment {
    font-size: calc(14rem/16);
}

.job-offer-teaser__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    transition: right 0.2s ease-in;
    @media screen and (min-width: 768px){
        right: 3.875rem;
    }
}
.job-offer-teaser__icon .icon{
    color: var(--color-primary);
    font-size: calc(14rem/16);
}

.job-offer-teaser:hover .job-offer-teaser__icon {
    right: 0.75rem;
    @media screen and (min-width: 768px){
        right: 3.5rem;
    }
}

/* application steps */

.job-application-step {
    text-align: center;
    position: relative;
    min-height: 14rem;
}

.job-application-step__number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(200rem/16);
    line-height: 1;
    font-family: var(--font-black);
    color: var(--color-lightgrey);
    z-index: -1;
}

.job-application-step__icon {
    font-size: calc(62rem/16);
    color: var(--color-primary);
    position: absolute;
    top: calc(80rem/16);
    left: 50%;
    transform: translateX(-50%);
}

.job-application-step__title {
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-50%);
    line-height: 1;
}
/* apply now teaser */

.job-apply-now-teaser {
    padding: 1rem;
    @media screen and (min-width: 768px){
        padding: 2.5rem;
    }
}

.job-apply-now-teaser:not(.has-bg-img) {
    background-color: var(--color-primary);
    color: #fff;
}
.job-apply-now-teaser.has-bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.78);
}

.job-apply-now-teaser:hover .job-apply-now-teaser__icon {
    transform: translateX(.5rem);
}

.job-apply-now-teaser__icon {
    transition: transform 0.2s ease-in;
    font-size: calc(14rem/16);
}

.job-apply-now-teaser__pretitle {
    text-transform: uppercase;
    letter-spacing: calc(4rem/16);
    font-family: var(--font-bold);
    font-size: calc(14rem/16);
    margin-bottom: .5rem;
    color: #fff;
}

.job-apply-now-teaser.has-bg-img .job-apply-now-teaser__pretitle {
    color: var(--color-primary);
}

/* job description */
.job-description-item {
    position: relative;
}
.job-description-item .wysiwyg {
    font-size: 1rem;
}
.job-detail-headline .wysiwyg{
    color: var(--color-grey);
    max-width: 100%;
    font-size: calc(14rem/16);
}
.job-description-item .wysiwyg ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.job-description-item .wysiwyg ul li {
    padding-left: 1.5rem;
    padding-bottom: 1rem;
}

.job-description-item .wysiwyg ul li:before {
    position: absolute;
    left: 0;
    content: "•";
    padding-right:1rem;
    color: var(--color-primary);
}

/* job detail title */

.job-detail-headline {
    margin: 0 0 2rem 0;
    @media screen and (min-width: 768px){
        margin-top: 5rem;
    }
}
.job-detail-headline__title{
    @media screen and (min-width: 768px){
        font-size: calc(60rem/16);
    }
}
.job-detail-headline__additional-info {
    font-size: 1rem;
    font-family: var(--font-default);
    letter-spacing: 0;
    text-transform: none;
}
.job-detail-headline__img {
    border-radius: 50%;
    overflow: hidden;
    display: block;
    position: relative;
    margin-right: 2rem;
    max-width: calc(125rem/16);
    @media screen and (min-width: 768px) {
        max-width: calc(150rem/16);
    }
}
.job-detail-headline__contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.job-detail-headline__contact .job-detail-headline__icon {
    font-size: 1rem;
    color: var(--color-primary);
}
.job-detail-headline__contact .job-detail-headline__icon.icon-phone-1{
    font-size: calc(21rem/16);
}
.job-detail-headline__info {
    color: var(--color-grey);
    margin-left: 1rem;
    font-size: calc(14rem/16);
}

/* job application form */

.job-application-form__submit {
    width: 100%;
    border-radius: 0;
    margin-top: 1.5rem;
    background-color: var(--color-primary);
    padding: 2rem!important;
}
.job-application-form label {
    text-transform: none;
    letter-spacing: 3px;
}
.job-application-form__submit:hover .job-apply-now-teaser__icon {
    transform: translateX(.5rem);
}

.job-application-form__label {
    letter-spacing: 0!important;
    text-transform: uppercase!important;
    font-size: 1.125rem!important;
    margin: 1.5rem 0!important;
    @media screen and (min-width: 768px){
        font-size: calc(22rem/16)!important;
    }
}

.job-application-form__label--left {
    letter-spacing: 0!important;
    text-transform: uppercase!important;
    font-size: 1.125rem!important;
    margin: 1.5rem 0!important;
    @media screen and (min-width: 768px){
        font-size: calc(22rem/16)!important;
        margin: 0!important;
    }
}

.job-application-form #birthdate-calendar-button {
    border: 0;
    padding: 5px;
    border-radius: 0;
}
.job-application-form #birthdate-calendar-button:hover {
    background-color: transparent;
    color: var(--color-primary);
}

.job-application-form .job-application-form__birthday__label {
    position: absolute;
    z-index: 5;
    left: 0;
    font-size: 10px;
    top: -0.25rem;
}
.job-application-form .job-application-form__birthday {
    border-bottom: 2px solid black;
}