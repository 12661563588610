.content-accordion__header-link{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-family: var(--font-black);
    font-size: calc(16rem/16);
    line-height: calc(17/16);
    letter-spacing: calc(2rem/16);
    padding: calc(22rem/16) calc(20rem/16);

    @media screen and (min-width: 768px){
        letter-spacing: calc(4rem/16);
        padding: calc(32rem/16) calc(30rem/16);

    }
}

.content-accordion .card{
    border-radius: 0;
    border: 0;
    border-top: 1px solid var(--color-default);
}

.content-accordion .card-header{
    background-color: transparent;
    border-radius: 0;
    border: 0;
}

.content-accordion__body{
    padding: 0 calc(20rem/16) calc(20rem/16);

    @media screen and (min-width: 768px){
        padding: 0 calc(30rem/16) calc(30rem/16);
    }
}

.content-accordion__header-toggle-icon{
    font-size: calc(13rem/16);
}