.video-js .vjs-big-play-button{
  width: calc(100rem/16);
  height: calc(100rem/16);
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.33);
  border: none;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-big-play-button .vjs-icon-play:before{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: auto;
  height: auto;
  font-size: calc(50rem/16);
}

.video-js .vjs-big-play-button:focus, .video-js:hover .vjs-big-play-button{
  background-color: var(--color-primary);
  color: #fff;
}

.video-btn{
  border: none;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.19);
  width: 100px;
  height: 100px;
  color: #fff;
  font-size: 34px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  cursor: pointer;
  transition: all 150ms ease;
}

.video-btn .icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.content-video:hover .video-btn{
  background-color: var(--color-primary);
}

.video-js{
  width: 100%;
}