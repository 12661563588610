.calendar {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

@media screen and (min-width: 768px) {
    padding: 0 20px;
}
}
.calendar__head {
    padding: 10px 15px;
    margin-bottom: calc(30rem/16);

@media screen and (min-width: 768px) {
    padding: 15px 20px;
}
}
.calendar__head__title {
    display: none;
    color: var(--color-lightgrey);
    font-family: var(--font-black);
    letter-spacing: calc(5rem/16);
    text-transform: uppercase;
    transition: all 250ms ease-in-out;

    @media screen and (min-width: 768px) {
       display: flex;
    }
}

.calendar__head__title.is-active {
    color: var(--color-default);
    display: flex;
}

.calendar__head__control {
    font-size: 20px;
    position: relative;
    top: 4px;

@media screen and (min-width: 768px) {
    font-size: 15px;
}
}
.calendar__table {
    width: 100%;
    max-width: 100%;
    table-layout: fixed;
}
.calendar__table__head {
    color: var(--color-grey);
}
.calendar__table__head th {
    padding: 5px 10px;
    font-family: var(--font-black);
    letter-spacing: calc(5rem/16);
}
.calendar__table__day {
    position: relative;
    background: #fff;
    border: 1px solid #fff;

    padding: 2.5px;
}

.calendar__table__day .calendar__table__day__bg {
    background: var(--color-grey-100);
    transition: all 250ms ease-in-out;
}

.calendar__table__day--placeholder .calendar__table__day__bg {
    background: #fbfbfb;
}
.calendar__table__day__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    font-family: var(--font-black);
    font-size: calc(20rem/16);
    letter-spacing: calc(5rem/16);
    position: relative;

    @media screen and (min-width: 768px) {
        font-size: calc(35rem/16);
        letter-spacing: calc(10rem/16);
    }
}

.calendar__table__day:not([class$="disabled"]) .calendar__event-container {
    font-size: calc(14rem/16);
    line-height: 1.1;
    text-align: right;
    letter-spacing: calc(3rem/16);
}

/* disabled days */
.calendar__table__day--disabled {
    opacity: .4;
}
.calendar__table__day--disabled:after {
    content: none;
}

/* available days */

.calendar__table__day.is-available:not(.calendar__table__day--disabled) {
    cursor: pointer;
}
.calendar__table__day.is-available:not(.calendar__table__day--disabled):after {
    content: '';
    position: absolute;
    left: calc(7.5rem/16);
    top: calc(7.5rem/16);
    height: calc(5rem/16);
    width: calc(5em/16);
    background: #7ed321;
    border-radius: 50%;

    @media screen and (min-width: 768px) {
        left: calc(10rem/16);
        top: calc(10rem/16);
        height: calc(7.5rem/16);
        width: calc(7.5em/16);
    }
}

.calendar__table__day.is-available.is-available--red:not(.calendar__table__day--disabled):after {
    background: var(--color-danger);

}
.calendar__table__day.is-available:not(.calendar__table__day--disabled):hover .calendar__table__day__bg,
.calendar__table__day.is-selected:not([class$="disabled"]) .calendar__table__day__bg{
    box-shadow:  0px 4px 14px -1px rgba(0,0,0,0.5);
}

@media screen and (max-width: 767px) {
    .calendar__detail {
        padding: 15px 15px 0 25px;
        position: relative;
        display: none;
        text-align: center;
        margin-bottom: 10px;
        font-size: 20px;
    }
    .calendar__detail.is-open {
        display: block;
    }
    .calendar__detail:before {
        content: '';
        position: absolute;
        bottom: 10px;
        left: 10px;
        top: 10px;
        width: 3px;
    }
}

/*available days overlay*/

.calendar__table__day__content__overlay {
    padding: 0 calc(15rem/16) calc(15rem/16) calc(25rem/16);
    text-align: center;
    z-index: 1;
    transition: all 250ms ease-in-out;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (min-width: 767px) {
        position: absolute;
        left: -5rem;
        right: -5rem;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
        box-shadow:  0px 4px 14px -1px rgba(0,0,0,0.5);
        display: block;
        padding: 2rem 1rem 1rem 1rem;
        background: var(--color-grey-100);
        min-height: calc(105rem/16);
    }
}

.calendar__table__day__content__overlay .btn{
    white-space: normal;
    width: 100%;
    font-size: calc(12rem/16);
}

.dashboard-calendar .calendar__table__day__content__overlay {
    @media screen and (min-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: calc(-50rem/16);
        bottom: calc(-50rem/16);
        left: calc(-50rem/16);
        right: calc(-50rem/16);
    }
}

.calendar__table__day__content__overlay.show {
    display: flex;
    align-items: flex-start;

    @media screen and (min-width: 767px) {
        opacity: 1;
        visibility: visible;
    }
}

.calendar__table__day__content__overlay:before {
    @media screen and (min-width: 767px) {
        content: '';
        position: absolute;
        left: calc(10rem/16);
        top: calc(10rem/16);
        height: calc(7.5rem/16);
        width: calc(7.5em/16);
        background: #7ed321;
        border-radius: 50%;
    }
}

.calendar__table__day__content__overlay .custom-checkbox__text {
    font-family: var(--font-black);
    letter-spacing: calc(3rem/16);
    text-align: left;
}

.calendar__table__day__content__overlay .custom-checkbox__box {
    width: calc(16rem/16);
    height: calc(16rem/16);
    margin-top: calc(4rem/16);
    margin-right: calc(8rem/16);
}

.calendar__table__day__content__overlay .custom-checkbox__box:before {
    font-size: calc(10rem/16);
}

/*animation*/
.successfully-saved {
    position: relative;
}
.successfully-saved:after {
    content: 'saved';
    position: absolute;
    top: 0;
    right: -25px;
    color: #7ed321;
    opacity: 0;
    animation-name: successfullySaved;
    animation-duration: 1s;
    z-index: 1;
    font-family: var(--font-black);
}

@keyframes successfullySaved {
    0% {
        opacity: 0;
        transform: translateX(0px);
    }
    75% {
        opacity: 1;
        transform: translateX(20px);
    }
    100% {
        transform: translateX(20px);
    }
}
