.slide.scene-img-text-slide{
  @media screen and (max-width: 767px){
     padding: 30px 10px;
  }
}

.img-text-slide--light,
.bg-white.img-text-slide--light{
  color: #fff;
}

.img-text-slide--dark{
  color: var(--color-default);
}


.img-text-slide__hl{
  letter-spacing: calc(5rem/16);
  line-height: calc(40/40);
  @media screen and (min-width: 768px){
     font-size: calc(34rem/16);
  }

  @media screen and (min-width: 767px){
    letter-spacing: calc(7rem/16);
  }

  @media screen and (min-width: 992px){
    letter-spacing: calc(15rem/16);
  }
}

.img-text-slide__pl{
  font-family: var(--font-black);
  text-transform: uppercase;
  letter-spacing: calc(3.56rem/16);
  margin-bottom: calc(5rem/16);
}

.img-text-slide__text{
  margin-top: calc(20rem/16);

  @media screen and (min-width: 768px){
    margin-top: calc(25rem/16);
  }

  @media screen and (min-width: 1200px){
     margin-top: calc(40rem/16);
  }
}

.img-text-slide--light .img-text-slide__text,
.img-text-slide--light .img-text-slide__hl{
  text-shadow: 0 0 10px rgba(0,0,0,.6);
}

.img-text-slide__content{
  margin-top: calc(15rem/16);
}

.scene-img-text-slide .img-text-slide__content{
  margin-top: calc(10rem/16);

  @media screen and (min-width: 768px){
    margin-top: calc(20rem/16);
  }

  @media screen and (min-width: 1200px){
    margin-top: calc(80rem/16);
  }
}

.scene-img-text-slide .btn:hover{
  background-color: var(--color-default);
  color: #fff;
}



/** Scene Img Text Slide */

.scene-img-text-slide .img-text-slide__img,
.scene-img-text-slide .img-text-slide__video{
  position: relative;
  @media screen and (min-width: 768px){
    margin-left: calc(54rem/16);
  }
}

.scene-tabbing-slide .scene-img-text-slide{
  background-color: var(--color-default);
}

.scene-tabbing-slide .scene-img-text-slide .img-text-slide__hl{
  @media screen and (min-width: 768px){
    margin-left: calc(-100rem/16);
  }
}

.scene-tabbing-slide .btn{
  color: #fff;

}

.scene-tabbing-slide  .img-text-slide .btn:hover{
  background-color: #fff;
  color: var(--color-default);
  border-color: #fff;
}



.scene-img-text-slide .img-text-slide__brush:before {
  content: "";
  position: absolute;
  background-image: url(/static/img/brush.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 148.3%;
  height: 132%;
  top: calc(-25rem/16);
  left: -12.5%;
  bottom: 0;
  right: 0;
}


@media screen and (min-width: 768px){
  .scene-tab-animation .img-text-slide__left {
    transform: translateX(-40%);
    transition: all 900ms ease;
    transition-delay: 250ms;
    opacity: 0;
  }

  .scene-tab-animation .img-text-slide__right {
    transform: translateX(40%);
    transition: all 800ms ease;
    transition-delay: 650ms;
    opacity: 0;
  }

  .scene-tab-animation .start-animation .img-text-slide__left {
    transform: translateX(0);
    opacity: 1;
  }

  .scene-tab-animation .start-animation .img-text-slide__right {
    transform: translateX(0);
    opacity: 1;
  }
}


.img-text-slide__sl{
  letter-spacing: calc(3.56rem/16);
  font-family: var(--font-black);
  margin-top: calc(5rem/16);
}

.product-condition__text{
  text-transform: uppercase;
  font-size: calc(24rem/16);
  letter-spacing: calc(3rem/16);
  font-family: var(--font-black);
}

.product-condition__stars>.icon{
  color: #FFD000;
  margin-right: calc(2rem/16);
  vertical-align: calc(-3rem/16);
}

.product-condition__stars>.icon.disabled{
  color: var(--color-grey);
}

.img-text-slide__price{
  font-size: calc(38rem/16);
  font-family: var(--font-black);
  
  @media screen and (min-width: 768px){
     font-size: calc(50rem/16);
  }
}

.img-text-slide__img{
  position: relative;
}
.img-text-slide__img + .img-text-slide__img {
  margin-top:calc(20rem/16);
}

.product-slide-big .img-text-slide__img{
  @media screen and (min-width: 768px){
     margin-left: calc(20rem/16);
  }
}

.img-text-slide__badge{
  font-family: var(--font-black);
  font-size: calc(20rem/16);
  letter-spacing: calc(3rem/16);
}

.img-text-slide__badge {
  font-family: Kuunari-BlackCondensed,sans-serif;
  font-size:calc(20rem/16);
  letter-spacing: calc(3rem/16);
  position: absolute;
  top: calc(12rem/16);
  left: calc(5rem/16);
  color: #fff;
  padding: calc(5rem/16) calc(10rem/16);
}

.img-text-slide__badge:before {
  content: '';
  position: absolute;
  background-image: url(/static/img/brush.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: calc(120rem/16);
  height: calc(80rem/16);
  z-index: 0;
  top: calc(-11rem/16);
  left: 0;
}

.img-text-slide__badge__text{
  position: relative;
  text-shadow: 1px 1px 3px #3f3f3f;
}
@media screen and (min-width: 767px){
  .img-text-slide .slick-dots {
    display:none !important;
  }
}