.container.container-product-detail-body{
    max-width: calc(538rem/16);
    margin-left: 0;
    margin-right: auto;
}

.product-detail__main{
    position: relative;
    padding: calc(90rem/16) 0 calc(25rem/16);

    @media screen and (min-width: 1200px){
        padding-top: calc(125rem/16);
    }
    @media screen and (max-width: 767px){
       padding: calc(65rem/16) 0 calc(25rem/16);
    }
}

.product-detail__main:after{
    content: '';
    position: absolute;
    width: 90%;
    right: -25%;
    top: 0;
    bottom: 0;
    background: linear-gradient(0deg, #e0e7e3 0%, #eff3f1 100%);
    z-index: -1;
    transform: skewX(-10deg);
}

.product-detail__main:before{
    content: '';
    position: absolute;
    width: 58%;
    left: -23%;
    bottom: 0;
    top: 0;
    z-index: -1;
    background: linear-gradient(180deg, #e0e7e3 0%, #eff3f1 100%);
    transform: skewX(-10deg);
    @media screen and (min-width: 768px){
        width: 43%;
        left: -8%;
    }
}

.product-detail__body{
    @media screen and (min-width: 768px){
        padding-left: calc(15rem/16);
    }

    @media screen and (min-width: 1200px){
        padding-left: calc(58rem/16);
        padding-top: calc(60rem/16);
    }
}
.product-detail__title{
    font-size: calc(35rem/16);
    @media screen and (min-width: 768px){
       font-size: calc(60rem/16);
    }
}

.product-detail__info-text{
    font-family: var(--font-black);
    font-size: calc(16rem/16);
    letter-spacing: calc(2rem/16);
    text-transform: uppercase;
}

.product-detail__facts .product-main__fact-point{
    width: calc(6rem/16);
    height: calc(6rem/16);
}

.product-detail__facts .product-main__fact-point:not(:last-child){
    margin-right: calc(2rem/16);
}

.product-detail__facts-list{
    margin-top: calc(-2rem/16);
}


.product-detail .product-main__color{
    position: relative;
    border-radius: 50%;
}


.product-detail .product-main__color__left {
    width: calc(15rem / 16);
    height: calc(31rem / 16);
}

.product-detail .product-main__color__right {
    width: calc(16rem / 16);
    height: calc(31rem / 16);
}

.product-detail .product-main__color__one{
    width: calc(31rem / 16);
    height: calc(31rem / 16);
}

.product-detail__btns{
    padding-top: calc(15rem/16);
    border-top: 1px solid var(--color-lightgrey);
}


.product-detail__btns .btn{
    padding: calc(16rem/16) calc(10rem/16);
}

.product-detail__bottom{
    font-size: calc(18rem/16);
    padding: calc(32rem/16) 0;
    position: relative;

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}

.product-detail__bottom .container:after{
    content: '';
    position: absolute;
    bottom: calc(-32rem/16);
    left: calc(-100rem/16);
    right: calc(-100rem/16);
    height: calc(1rem/16);
    background-color: var(--color-lightgrey);
}


.product-detail__bottom-value{
    font-size: calc(18rem/16);
    line-height: 1;
    text-transform: uppercase;
    font-family: var(--font-black);
    letter-spacing: calc(3.3rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(26rem/16);
    }
}

.product-detail__three-sixty{
    position: absolute;
    bottom: calc(25rem/16);
    left: 50%;
    transform: translateX(-50%);
}

.product-detail__bottom-info{
    font-size: calc(19rem/16);
    margin-left: calc(5rem/16);
}

.product-detail__currency-switch{
    display: none;
}