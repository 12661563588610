.twitter-typeahead{
    width: 100%;
    pointer-events: auto;
}

.tt-menu{
    width: 100%;
    color: var(--color-default);
    margin-top: calc(10rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(110rem/16);
    }
    
    @media screen and (max-width: 767px){
        position: fixed!important;
        right: 0;
        padding: 0 calc(15rem/16);
        font-size: calc(16rem/16);
        min-height: calc(365rem/16);
        height: 100%;
        overflow-y: auto;
    }
}
.dealer-map__typeahead .tt-menu{
    margin-top: 0;
    background-color: #FFF;
    padding: calc(20rem/16);
    box-shadow: calc(2rem/16) calc(5rem/16) calc(35rem/16) calc(5rem/16) rgb(0 0 0 / 15%);

    @media screen and (max-width: 767px){
        position: absolute!important;
        right: 0;
        padding: calc(15rem/16);
        font-size: calc(16rem/16);
        min-height: calc(150rem/16);
        height: 100%;
        overflow-y: auto;
    }
}

.tt-dataset-heading__title{
    text-transform: uppercase;
    font-size: calc(15rem/16);
    letter-spacing: calc(2rem/16);
    color: var(--color-grey);
    font-family: var(--font-black);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        letter-spacing: calc(6rem/16);
    }
}

.tt-dataset-heading__title .icon{
    vertical-align: calc(-1rem/16);
    margin-right: calc(5rem/16);
}

.tt-dataset-heading{
    border-bottom: calc(1rem/16) solid var(--color-grey);
    padding-bottom: calc(5rem/16);
    margin-bottom: calc(10rem/16);
    margin-top: calc(18rem/16);
}

.tt-suggestion{
    text-transform: uppercase;
    padding: calc(2rem/16) 0;
    margin-bottom: calc(3rem/16);
    margin-left: calc(27rem/16);
    cursor: pointer;
}
.tt-suggestion:hover{
    color: var(--color-primary);
}

.tt-suggestion mark{
    background-color: transparent;
    padding-right: 0;
}

.tt-dataset-heading__link{
    @media screen and (min-width: 768px){
        letter-spacing: calc(1rem/16);
    }
}

.input-group>.twitter-typeahead{
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    

}


.detail-search .tt-menu{
    padding: calc(30rem/16);
    background-color: #fff;
    margin-top: 0;
    box-shadow: rgb(217, 217, 217) 0px 2px 10px 1px;

    @media screen and (max-width: 767px){
        position: absolute!important;
    }

}

.detail-search .tt-suggestion{
    @media screen and (max-width: 767px){
       margin-left: 0;
    }
}