.description-with-content__citation{
    font-size: calc(24rem/16);
    line-height: 1;
    letter-spacing: calc(3.33rem/16);
    font-family: var(--font-black);
    text-transform: uppercase;
}
.description-with-content__citation-label{
    position: relative;
    padding-left: calc(25rem/16);
    font-size: calc(16rem/16);
    line-height: calc(24/16);
}
.description-with-content__citation-label:before{
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: calc(15em/16);
    height: calc(1rem/16);
    background-color: var(--color-default);
}
.description-with-content__arrow{
    margin-left: calc(15rem/16);
    font-size: calc(11rem/16);

    /*padding: calc(20rem/16);
    font-size: calc(13rem/16);*/
}