.shop-overlay{
    background-color: #fff;
    padding: calc(30rem/16);

    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;

    z-index: 1000;

    transform: translateX(100%);
    transition: transform 350ms ease;

    @media screen and (min-width: 768px){
       width: calc(472rem/16);
    }

    @media screen and (max-width: 767px){
        left: 0;
    }
}

.shop-overlay.is-open{
    transform: translateX(0);
}

.shop-overlay__close{
    font-size: calc(20rem/16);
    color: var(--color-grey);
    
    margin-bottom: calc(20rem/16);
}

.shop-overlay__message{
    font-family: var(--font-black);
    letter-spacing: calc(2rem/16);
}
.shop-overlay__message .icon{
    font-size: calc(30rem/16);
}

.shop-overlay__message+.shop-overlay__message{
    margin-top: calc(20rem/16);
}

.shop-overlay__sum-table{
    color: var(--color-grey);
    letter-spacing: calc(1rem/16);
}

.shop-overlay__total-price{
    font-family: var(--font-black);
    font-size: calc(15rem/16);
    letter-spacing: calc(2rem/16);
    text-transform: uppercase;
}

.shop-overlay__total-price__price{
    font-size: calc(34rem/16);
    letter-spacing: calc(4.53rem/16);
}

.shop-overlay__section+.shop-overlay__section{
    margin-top: calc(35rem/16);
    
    @media screen and (min-width: 768px){
       margin-top: calc(60rem/16);
    }
}

.shop-overlay__title{
    font-size: calc(24rem/16);
    letter-spacing: calc(3.2rem/16);
    font-family: var(--font-black);
    text-transform: uppercase;
    padding-bottom: calc(25rem/16);
    display: block;
    border-bottom: 1px solid;
}

.shop-overlay__article-info{
    padding: calc(25rem/16) 0;
}

.shop-overlay--checkout .shop-overlay__sum-table{
    border-top: 1px solid var(--color-lightgrey);
}

.shop-overlay__step{
    letter-spacing: calc(1rem/16);
    font-family: var(--font-bold);
    
    padding: calc(15rem/16) 0;
    border-bottom: 1px solid var(--color-lightgrey);
    
    @media screen and (min-width: 768px){
        padding: calc(30rem/16) 0;
    }
}

.shop-overlay__step .icon{
    vertical-align: calc(-2rem/16);
}

.shop-overlay--checkout .shop-overlay__close .icon{
    vertical-align: calc(-2rem/16);
}

.shop-overlay-checkout-toggle{
    background-color: #fff;
    box-shadow: 0 4px 14px 0 rgba(0,0,0,0.3);
    padding: calc(20rem/16) calc(30rem/16);
    text-transform: uppercase;
    font-family: var(--font-black);
    color: var(--color-primary);
    font-size: calc(16rem/16);
    letter-spacing: calc(2rem/16);
    border: none;
    cursor: pointer;

    @media screen and (max-width: 767px){
       width: 100%;
    }
}

.shop-overlay-checkout-toggle .icon{
    vertical-align: calc(-2rem/16);
    pointer-events: none;
    transform: rotate(45deg);
}

.shop-overlay-checkout-toggle-affix{
    position: relative;
    z-index: 5;
    @media screen and (max-width: 767px){
        width: 100%;
    }
    @media screen and (min-width: 768px){
        right: 0;
        left: auto!important;
        z-index: 5;
        position: absolute;
        top: calc(90rem/16);
    }

    @media screen and (min-width: 992px){
        top: calc(125rem/16);
    }

}