.account-appointment__item{
    background-color: var(--color-lightgrey);
    padding: calc(16rem/16) calc(20rem/16) calc(16rem/16) calc(31rem/16);
    font-size: calc(18rem/16);
    line-height: calc(22/18);
    border: none;
    letter-spacing: calc(1rem/16);
    cursor: pointer;
}
.account-appointment__item:active,
.account-appointment__item:focus,
.account-appointment__item:focus-visible,
.account-appointment__item:hover{
    border: none;
    outline: none;
}
.account-appointment__item:not(:first-child){
    margin-top: calc(15rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(31rem/16);
    }
}
.account-appointment__item.collapsed .account-appointment__item-icon{
    transform: rotate(270deg);
    color: var(--color-grey);
}
.account-appointment__item-icon{
    transform: rotate(90deg);
    color: var(--color-primary);
    font-size: calc(12rem/16);
}
.account-appointment__description{
    font-size: calc(14rem/16);
    line-height: calc(19/14);
    letter-spacing: 0;
}