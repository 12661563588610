.payment-box__item{
    font-family: var(--font-black);
    letter-spacing: calc(5rem/16);
    text-transform: uppercase;
}

.payment-box__item+.payment-box__item{
    margin-left: calc(40rem/16);
}

.payment-box__item .icon{
    font-size: calc(27rem/16);
}

.payment-box__info-text{
    font-size: calc(13rem/16);
    line-height: calc(14/13);
    margin-top: calc(10rem/16);
}


.payment-box__input{
    opacity: 0;
    position: absolute;
    overflow: hidden;
    clip: rect(0,0,0,0);
}

.payment-box__label{
    background-color: var(--color-grey-100);
    padding: calc(50rem/16) calc(30rem/16);
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: calc(2rem/16) solid transparent;
    position: relative;

    @media screen and (min-width: 768px){
        height: calc(145rem/16);
    }
}

.payment-box__input:checked ~ .payment-box__label,
.payment-box__label:hover,
.active .payment-box__label {
    border-color: var(--color-primary);
}

.payment-form .parsley-errors-list{
    bottom: calc(-30rem/16);
}

.payment-box__check{
    display: none;
    position: absolute;
    bottom: calc(20rem/16);
    right: calc(20rem/16);
}
.payment-box__input:checked ~ .payment-box__label .payment-box__check,
.active .payment-box__label .payment-box__check{
    display: block;
}
