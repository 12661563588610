
.floating-label {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-60%);
  transition: transform 200ms 200ms ease-in-out;
  transform-origin: left top;
  pointer-events: none;
  z-index: 3;
  background-color: #fff;
  padding: calc(2rem/16) 0;
  font-family: var(--font-black);
  text-transform: uppercase;
  font-size: calc(16rem/16);
}
.form-control ~ .floating-label {
    padding-left: calc(5rem/16);
}
.floating-label--transparent {
    background-color: transparent;
}

textarea + .floating-label {
    top:calc(30rem/16);
    transform: translateY(-50%);
}
textarea.form-control--md + .floating-label {
    top:calc(24rem/16);
}

.form-control:focus ~ .floating-label,
select:not(.form-control--with-empty-option) ~ .floating-label,
.has-value ~ .floating-label,
.form-control.has-value ~ .floating-label {
  transform: translateY(-156%) scale(.7);
  transition: transform 200ms ease-in-out;
}
.form-control--md:focus ~ .floating-label,
.form-control--md.has-value ~ .floating-label {
  transform: translateY(-125%) scale(.7);
  transition: transform 200ms ease-in-out;
}

.form-control:-webkit-autofill ~ .form-control-label,
.form-control:-webkit-autofill:focus ~ .form-control-label {
  transform: translateY(-156%) scale(.7);
}
.form-control--md:-webkit-autofill ~ .form-control-label,
.form-control--md:-webkit-autofill:focus ~ .form-control-label {
  transform: translateY(-125%) scale(.7);
}

.form-control.form-control--sm:focus ~ .floating-label,
select.form-control--sm:not(.form-control--with-empty-option) ~ .floating-label,
.form-control--sm.has-value ~ .floating-label,
.form-control.form-control--sm.has-value ~ .floating-label {
    transform: translateY(-130%) scale(.7);
    transition: transform 200ms ease-in-out;
}

.form-control.form-control--sm:-webkit-autofill ~ .form-control-label,
.form-control.form-control--sm:-webkit-autofill:focus ~ .form-control-label {
    transform: translateY(-130%) scale(.7);
}

.form-control--transparent ~ .floating-label {
    background-color: transparent;
}