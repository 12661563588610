.content-teaser-simple__body{
    padding: calc(15rem/16) calc(20rem/16);
    font-size: calc(16rem/16);
    line-height: calc(22/16)
}
.content-teaser-simple__title{
    font-size: calc(24rem/16);
    line-height: calc(31/24);
    letter-spacing: calc(5rem/16);
    color: var(--color-darkgrey);
    @media screen and (min-width: 768px){
        font-size: calc(35rem/16);
        line-height: calc(40/35);
    }
}
.content-teaser-simple__link{
    font-size: calc(16rem/16);
    line-height: calc(22/16);
}
.content-teaser-simple__icon{
    font-size: calc(11rem/16);
    vertical-align: calc(1rem/16);
}