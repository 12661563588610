.range-slider {
    height: calc(4rem/16);
    border-radius: 0;
    border: 0;
    box-shadow: none;
    background: var(--color-default);
    padding: 0 calc(3rem/16);
    width: 100%;
}

.range-slider .noUi-handle {
    width: calc(20rem/16);
    height: calc(20rem/16);
    background: var(--color-primary);
    border-radius: 100%;
    box-shadow: none;
    border: 0;
    top: calc(-8rem/16);
    left: calc(-12rem/16);
    cursor: pointer;
}

.range-slider .noUi-handle:focus {
    outline: none;
}
.range-slider .noUi-connect {
    background: var(--color-default);
    box-shadow: none;
}
.range-slider .noUi-handle:before {
    background: transparent;
    height: auto;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: #fff;
}

.range-slider .noUi-handle-lower:before {
    transform: translateY(-50%) translateX(-50%) scaleX(-1);
}
.range-slider .noUi-handle:after {
    content: attr(data-after);
    font-family: var(--font-black);
    position: absolute;
    top: calc(-25rem/16);
    left: 0;
    background: none;
}