.configuration-teaser{
    background-color: var(--color-default);
    color: #FFFFFF;
    padding: calc(31rem/16);
    height: 100%;
    display: flex !important;
    flex-direction: column;
    gap: calc(24rem/16);
    @media screen and (min-width: 768px){
        padding: calc(48rem/16) calc(48rem/16) calc(31rem/16) calc(48rem/16);
    }
    @media screen and (max-width: 767px){
        width: calc(305rem/16) !important;
    }
    @media screen and (min-width: 768px) and (max-width: 991px){
        width: calc(400rem/16) !important;
    }
}
.configuration-teaser__title{
    font-size: calc(20rem/16);
    line-height: calc(20/24);
    letter-spacing: calc(2.5rem/16);
    font-family: var(--font-black);
    text-transform: uppercase;
    @media screen and (min-width: 768px){
        font-size: calc(24rem/16);
        line-height: calc(24/24);
        letter-spacing: calc(3.33rem/16);
    }
}
.configuration-teaser__subtitle{
    font-size: calc(14rem/16);
    line-height: calc(22/14);
    color: #FFF;
    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        line-height: calc(24/16);
    }
}

.configuration-teaser__price-switch {
    display: flex;
    gap: calc(12rem/16);
    margin-top: calc(8rem/16);
    padding-bottom: calc(16rem/16);
    justify-content: flex-end;
    align-items: baseline;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% + 62rem/16);
        height: calc(2rem/16);
        background-color: #FFF;
        transform: translateX(calc(-31rem/16));

    }
    @media (min-width: 768px) {
        margin-top: calc(16rem/16);

        &:after {
            width: calc(100% + 96rem / 16);
            transform: translateX(calc(-48rem / 16));
        }
    }
}

.configuration-teaser__item{
    display: flex;
    align-items: center;
    gap: calc(17rem/16);
}
.configuration-teaser__item-icon{
    font-size: calc(34rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(44rem/16);
    }
}
.configuration-teaser__item-title{
    font-size: calc(14rem/16);
    line-height: calc(16/14);
    letter-spacing: calc(1.75rem/16);
    text-transform: uppercase;
    font-family: var(--font-black);
}

.btn.configuration-teaser__primary-btn:hover{
    background-color: #FFFFFF;
}
.btn.configuration-teaser__primary-btn{
    padding: calc(17rem/16);
}
.btn.configuration-teaser__outline-btn{
    border-color: #FFFFFF;
    color: #FFFFFF;
    padding: calc(17rem/16);
    width: 100%;
    margin-top: auto;
}