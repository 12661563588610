.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}.custom-radio:after {
     clear: both;
 }
.custom-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: relative;
    float: left;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: calc(1rem/16) solid #c4c6c7;
    background: #ffffff;
    width: calc(16rem/16);
    height: calc(16rem/16);
    margin-top: .1em;
    border-radius: 50%;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 37.5%;
    height: 37.5%;
    border-radius: 50%;
    background: var(--color-primary);
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-primary);
}
.has-error .custom-radio__box {
    border-color: var(--color-danger)
}
.custom-radio__text {
    display: block;
    overflow: hidden;
}

/*Custom Image Radio*/
.custom-radio--image .custom-radio__box{
    margin-top: calc(-7rem/16);
    margin-left: 50%;
    transform: translateX(-50%);
}
.custom-radio__lightbox{
    position: relative;
    cursor: pointer;
}
.custom-radio__lightbox .custom-radio__lightbox-item:before{
    content: var(--icon-search);
    font-family: iconfont;
    background-color: rgba(0,0,0,0.3);
    width: 50%;
    height: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: calc(15rem/16);
    z-index: 1;
    opacity: 0;
    transition: opacity 200ms ease-in-out, background-color 200ms ease-in-out;
    top: calc(-6rem/16);
    right: calc(-6rem/16);
    border-radius: 50%;
}
.custom-radio__lightbox:hover .custom-radio__lightbox-item:before{
    opacity: 1;
}
.custom-radio__lightbox:hover .custom-radio__lightbox-item:hover:before{
    background-color: rgba(0,0,0,0.6);
}