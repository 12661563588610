.nav-tabs{
  border: none;
  @media screen and (min-width: 768px){
    height: calc(99rem/16);
  }
}

.nav-tabs--height-auto{
  @media screen and (min-width: 768px){
     height: auto;
  }
}

.nav-tabs__icon{
  vertical-align: calc(-1.5rem/16);
}

.nav-tabs.nav-tabs--primary{
  background-color: var(--color-default);
}

.nav-tabs.nav-tabs--white{
  background-color: #fff;
}


.nav-tabs .nav-item:not(:last-child){
  margin-right: calc(5rem/16);
}

.nav-tabs .nav-link{
  background-color: var(--color-darkgrey);
  color: #fff;
  border: none;
  font-size: calc(16rem/16);
  line-height: calc(22/16);
  letter-spacing: calc(6rem/16);
  text-align: center;
  font-family: var(--font-black);
  text-transform: uppercase;
  border-radius: 0;
  padding: calc(23rem/16) calc(20rem/16);
    cursor: pointer;

  @media screen and (min-width: 768px) and (max-width: 1200px){
    font-size: calc(14rem/16);
    padding: calc(23rem/16) calc(20rem/16);
  }

  @media screen and (min-width: 1200px){
    padding: calc(23rem/16) calc(40rem/16);
  }
}

.nav-tabs.nav-justified .nav-link{
  padding-left: 0;
  padding-right: 0;
}

.nav-tabs.nav-tabs--white .nav-link{
  color: var(--color-default);
  background-color: #fff;
}
.nav-tabs.nav-tabs--white.bg-transparent .nav-link{
    background: transparent;
}

.nav-tabs.nav-tabs--grey .nav-link{
  color: var(--color-default);
  background-color: var(--color-lightgrey);
}

.nav-tabs.nav-tabs--primary .nav-link.active{
  color: #fff;
  background-color: var(--color-primary);
}


.nav-tabs .nav-link>span{
  position: relative;
  padding: calc(13rem/16) 0;
  overflow: hidden;
  display: block;
}

.nav-tabs .nav-link>span:after {
  content: '';
  position: absolute;
  background-color: var(--color-primary);
  bottom: 0;
  width: calc(35rem/16);
  height: calc(4rem/16);
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  transition: transform 250ms ease;
}

.nav-tabs .nav-link:focus{
    outline: none;
}

.nav-tabs .nav-link:hover>span:after,
.nav-tabs .nav-link:focus-visible>span:after,
.nav-tabs.nav-tabs--white .nav-link.active>span:after,
.nav-tabs.nav-tabs--grey .nav-link.active>span:after{
  transform: translateX(-50%) translateY(0);
}


/* nav-tab--blog */

.nav-tabs.nav-tabs--blog{
  @media screen and (min-width: 768px) and (max-width: 991px){
    margin-bottom: calc(30rem/16);
    height: auto;
  }
}
.nav-tabs--blog.nav-tabs.nav-tabs--white .nav-link{
  @media screen and (min-width: 768px) and (max-width: 991px){
     padding: calc(20rem/16) calc(10rem/16);
    font-size: calc(13rem/16);
  }
}

.nav-tabs.nav-tabs--blog .nav-link{
  padding: calc(20rem/16);
}

/* nav-tab--big */

.nav-tabs.nav-tabs--big .nav-link{
  padding: calc(5rem/16) calc(8rem/16);
  background-color: var(--color-grey-100);
  color: var(--color-darkgrey);
  border: 1px solid transparent;
  opacity: 0.6;
  transition: all 250ms ease;
  font-size: calc(18rem/16);

  @media screen and (min-width: 1200px){
    padding: calc(16rem/16) calc(40rem/16);
  }
  
  @media screen and (min-width: 768px){
    padding: calc(10rem/16) calc(30rem/16);
  }

  @media screen and (max-width: 767px){
    font-size: calc(14rem/16);
    letter-spacing: calc(4rem/16);
  }
}

.nav-tabs.nav-tabs--big .nav-link.active{
  border-color: var(--color-primary);
  opacity: 1;
}

.nav-tabs.nav-tabs--big .nav-link.active .icon{
  color: var(--color-primary);
}

.nav-tabs.nav-tabs--big .nav-link:hover{
  opacity: 1;
}

.nav-tabs.nav-tabs--big  .nav-item:not(:last-child){
  margin-right: calc(2rem/16);

  @media screen and (min-width: 768px){
    margin-right: calc(35rem/16);
  }
  
  @media screen and (max-width: 767px){
     margin-bottom: calc(2rem/16);
  }
}

/*
.nav-tabs.nav-tabs--big .nav-link.active:hover>span:after{
  display: none;
}
*/
