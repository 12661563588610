.dealer-map{
  position: relative;

  @media screen and (min-width: 768px){
    flex: 1 1 0;
    display: flex;
    min-height: 0;
    overflow: hidden;
  }
}

.dealer-map__list-container {

  @media screen and (min-width: 768px){
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 50%;
    padding-top: calc(150rem/16);
  }
  position: relative;

  background-color: #fff;
  padding-top: calc(100rem/16);
}

.dealer-map__map-container{
  height: 100vh;

  @media screen and (min-width: 768px){
    flex: 1 1 0;
    position: relative;
  }

  @media screen and (max-width: 767px){
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(100%);
    z-index: 15;
    transition: all 250ms ease;
  }
}

.dealer-map__map-container.is-open{
  transform: translateX(0);
}

.dealer-map__map-loading{
    display: flex;
    align-items: center;
    justify-content: center;
}

.dealer-map__top-bar{
  @media screen and (max-width: 767px){
    padding: 13px 15px;
    background-color: var(--color-lightgrey);
    z-index: 1;
    position: relative;
    font-family: var(--font-black);
  }
}

.dealer-map__close .icon{
  font-size: calc(13rem/16);
  margin-right: calc(5rem/16);
}

.dealer-map__canvas{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.dealer-map .checkbox-list{
  @media screen and (min-width: 768px){
     max-width: 22rem;
  }
}

.dealer-map .custom-image-checkbox__image {
    width: calc(136rem/16);
    height: calc(146rem/16);

    @media screen and (min-width: 768px){
        width: calc(146rem/16);
        height: calc(156rem/16);
    }
}

.dealer-map__list{
  max-width: calc(550rem/16);
  width: 100%;
}

.dealer-map__list__hl{
    font-size: calc(18rem/16);
    line-height: calc(20/18);
    letter-spacing: calc(2rem/16);
}

.dealer-map__list-icon{
    font-size: calc(12rem/16);
    transition: transform 200ms ease-in-out;
}
.dealer-map__list-collapse[aria-expanded="false"] .dealer-map__list-icon{
    transform: rotate(0);
}

.dealer-list__list-container{
    padding: calc(48rem/16) 0;
    background-color: var(--color-grey-100);
}

.dealer-list>li{
  border-bottom: 2px solid var(--color-lightgrey);
}

/*
.dealer-list li:nth-child(odd) .dealer-list-item,
.dealer-list li:nth-child(odd) .dealer-list-item__body .card-body{
  background-color: var(--color-lightgrey);
}
*/

.dealer-list-item{
  font-size: calc(16rem/16);
}
.dealer-list-item__title{
  font-family: var(--font-black);
  text-transform: uppercase;
  letter-spacing: calc(0.6rem/16);
}
.dealer-list-item__description{
    display: flex;
    @media screen and (max-width: 767px){
        font-size: calc(14rem/16);
        line-height: calc(16/14);
        display: block;
    }
}

.dealer-list-item__head {
  display: block;
  padding: calc(15rem/16) 0;
  text-transform: uppercase;
  font-size: calc(16rem/16);
}

.dealer-list-item__head.collapsed:hover .dealer-list-item__title,
.dealer-list-item__head .dealer-list-item__title{
  color: var(--color-primary);
}

.dealer-list-item__head.collapsed .dealer-list-item__title{
  color: var(--color-default);
}

.dealer-list-item__body{
  font-size: calc(15rem/16);
  @media screen and (min-width: 768px){
     font-size: calc(18rem/16);
  }
}

.dealer-list-item__body .card-body{
  border-radius: 0;
  border: none;
  display: block;
  padding: 15px 5px;
    background-color: var(--color-grey-100);
}


.dealer-list-item__body a:not(.link-with-icon):hover{
  color: var(--color-primary);
}

.dealer-list-item__premium, .dealer-list-item__specialist, .dealer-list-item__bodyscanner, .dealer-list-item__delivery{
    color: var(--color-grey);
  font-size: calc(18rem/16);
  margin-top: calc(2rem/16);
}

.dealer-list-item__premium{
    color: var(--color-primary);
}

.dealer-list-item__contact-icon {
  font-size: calc(22rem/16);
}

.dealer-list-item__contact-icon.icon-phone{
  font-size: calc(19rem/16);
}

.list-dealer-item-categories__circle{
  width: calc(22rem/16);
  height: calc(22rem/16);
  border-radius: 50%;
  background-color: var(--color-lightgrey);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: calc(3rem/16);
  font-size: calc(20rem/16);

  @media screen and (min-width: 768px){
    width: calc(26rem/16);
    height: calc(26rem/16);
    font-size: calc(22rem/16);
  }
}

.dealer-list-item__size{
  width: calc(22rem/16);
  height: calc(22rem/16);
  border-radius: 50%;
  background-color: var(--color-lightgrey);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: var(--font-black);
  font-size: calc(15rem/16);

  @media screen and (min-width: 768px){
    width: calc(26rem/16);
    height: calc(26rem/16);
  }
}

.dealer-list-item__show-more{
  font-family: var(--font-bold);
  letter-spacing: calc(2.6rem/16);
  font-size: calc(15rem/16);
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-top: calc(20rem/16);

  @media screen and (min-width: 768px){
    letter-spacing: calc(5.6rem/16);
  }
}
.dealer-list-item__show-more:focus{
    outline: none;
    border: none;
}

.dealer-list-item .show-all-content__max-height:not(.show) .show-all-content__content:after{
  display: none;
}

.dealer-list-item .show-all-content__max-height.show .show-all-content__content{
  max-height: 100%!important;
}

.dealer-list-item .show-all-content__max-height.show .show-all-content-btn__less{
  display: inline-block;
}

.dealer-list-item__show-more .icon{
  font-size: calc(9rem/16);
}

.dealer-list-item .show-all-content__max-height.show .dealer-list-item__show-more .icon{
  transform: rotate(180deg);
}

.dealer-list-item .btn.btn-outline{
  @media screen and (max-width: 1199px){
     padding: calc(17rem/16) calc(25rem/16);
  }
}

.dealer-list-item .list-inline-item:not(:last-child){
  @media screen and (max-width: 767px){
     margin-right: calc(3rem/16);
  }
}

.btn.btn-geolocation{
  text-transform: none;
  font-family: var(--font-default);
  letter-spacing: 0;
  font-size: calc(15rem/16);
  margin-top: calc(5rem/16);
}

.btn-geolocation .icon{
  vertical-align: calc(-2rem/16);
}

.geo-location__address-name{
  display: block;
  color: var(--color-default);
  font-family: var(--font-black);
  letter-spacing: 0.4px;
  font-size: calc(15rem/16);
  vertical-align: calc(-4rem/16);

  @media screen and (min-width: 768px){
    margin-left: calc(12rem/16);
    display: inline-block;
  }
}


.dealer-list-item__distance{
  font-family: var(--font-black);
  background-color: var(--color-lightgrey);
  font-size: calc(11rem/16);
  letter-spacing: calc(1rem/16);
  padding: calc(3rem/16) calc(5rem/16);
  text-transform: none;
  vertical-align: calc(2rem/16);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  @media screen and (min-width: 768px){
    font-size: calc(12rem/16);
  }
}


