.grid-teaser {
    position: relative;
}

.grid-teaser::before {
    content: '';
    background: linear-gradient(180deg, rgba(33, 33, 33, 0) 0%, #282829 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.grid-teaser__body{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: calc(12rem/16) calc(14rem/16);
    z-index: 1;

    @media screen and (min-width: 1200px){
        padding: calc(16rem/16) calc(20rem/16);
    }
}

.grid-teaser__img--fixed{
    height: auto;

    @media screen and (min-width: 768px){
        height: calc(364rem/16);
    }

    @media screen and (min-width: 992px){
        height: calc(414rem/16);
    }

    @media screen and (min-width: 1200px){
        height: calc(514rem/16);
    }
}

.grid-teaser__title {
    font-size: calc(22rem / 16);
    letter-spacing: calc(5rem / 16);
    color: #fff;
    text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.58);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
        letter-spacing: calc(5rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(18rem / 16);
        letter-spacing: calc(8rem / 16);
    }

    @media screen and (min-width: 1350px) {
        font-size: calc(24rem / 16);
    }
}

.grid-teaser__pretitle{
    color: var(--color-primary);
    font-size: calc(16rem/16);
}

.grid-teaser__img picture,
.grid-teaser__img--fixed picture,
.grid-teaser__img img,
.grid-teaser__img--fixed img
{
    transform: translateX(-50%) scale(1);
    transition: transform 0.3s;
    transform-origin: center;
}

.grid-teaser:hover .grid-teaser__img picture,
.grid-teaser:hover .grid-teaser__img--fixed picture,
.grid-teaser:hover .grid-teaser__img img,
.grid-teaser:hover .grid-teaser__img--fixed img
{
    transform: translateX(-50%) scale(1.1);
}

/* red & grey grid teaser*/
.grid-teaser-color {
    color: #FFF;
    height: 100%;
    padding: calc(22rem/16) calc(14rem/16);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;

    @media screen and (min-width: 768px) {
        padding: calc(25rem/16) calc(16rem/16);
    }

    @media screen and (min-width: 1200px) {
        padding: calc(35rem/16);
    }
}

.grid-teaser-color--red {
    background-color: var(--color-primary);
}

.grid-teaser-color--grey {
    background-color: var(--color-darkgrey);
}

.grid-teaser-color__title{
    font-family: var(--font-black);
    font-size: calc(24rem/16);
    text-transform: uppercase;
    letter-spacing: 3px;
    line-height: 1;
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 1200px) {
        font-size: calc(28rem/16);
        letter-spacing: 5px;
    }
}

.grid-teaser-color__subline{
    font-family: var(--font-black);
    font-size: calc(17rem/16);
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.1;

    @media screen and (min-width: 1200px) {
        font-size: calc(18rem/16);
        letter-spacing: 1px;
    }
}

.grid-teaser-color__content{
    margin: calc(15rem/10) 0 calc(30rem/10);

    @media screen and (min-width: 768px){
        margin: auto 0;
    }
}

.btn.grid-teaser-color__btn{
    position: static;
    @media screen and (min-width: 768px) and (max-width: 1200px){
        padding: calc(12rem/16) calc(20rem/16);
    }
}

/* spacing */
.teaser-grid__margin-top.vertical-gutter--30{
    margin-top: 0;
}