.img-slide{
  position: relative;
  color: #fff;
  overflow: hidden;
}

.img-slide:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, rgba(0,0,0,0.6) 0%, rgba(17,17,17,0) 100%);
  z-index: 1;
}

.img-slide__body{
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  z-index: 2;
}

.img-slide__body .btn{
  margin-top: calc(10rem/16);
  text-shadow: none;
  @media screen and (min-width: 768px){
     margin-top: 2%;
  }
}

.img-slide .wysiwyg{
  max-width: calc(950rem/16);
  width: 100%;
  margin: 0 auto;
}
.img-slide__title{
    font-size: calc(24rem/16);
    line-height: calc(29/24);
    letter-spacing: calc(3.75rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(32rem/16);
        line-height: calc(35/32);
        letter-spacing: calc(5rem/16);
    }
}