/*.navbar--blog.is-sticky{
  @media screen and (min-width: 768px){
    background: linear-gradient(to bottom, rgba(255,255,255,0.55) 0%,rgba(255,255,255,0.55) 1%,rgba(255,255,255,0.45) 46%,rgba(255,255,255,0) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
  }
}*/
.navbar-blog{
  padding: 0 calc(25rem/16);
}

.nav-open .navbar-blog{
  display: none;
}

.navbar-dark .navbar-blog .navbar-nav .nav-link{
  color: var(--color-default);
  pointer-events: auto;
  font-family: var(--font-black);
  text-transform: uppercase;
  font-size: calc(16rem/16);
  letter-spacing: calc(4.6rem/16);

  @media screen and (min-width: 768px){
    height: auto;
    display: flex;
align-items: center;
}

}

.navbar-blog .navbar-nav{
  background-color: #fff;
  padding: 0 calc(10rem/16);
}

.navbar .navbar-blog .nav-link__text{
  padding: calc(5rem/16);
}


.navbar-blog .navbar-nav>.nav-item:not(:last-child){
  @media screen and (min-width: 768px){
    margin-right: calc(25rem/16);
  }

  @media screen and (min-width: 992px){
    margin-right: calc(45rem/16);
  }
}