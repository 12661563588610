.quote{
  position: relative;

  margin: calc(50rem/16) 0;
}

.quote:before{
  content: '"';
  font-size: calc(550rem/16);
  color: var(--color-lightgrey);
  position: absolute;
  left: calc(-56rem/16);
  top: calc(30rem/16);
  line-height: 0.5;
  display: block;
  font-family: var(--font-bold);
  font-style: italic;
  z-index: 0;

  @media screen and (min-width: 768px){
    top: calc(20rem/16);
  }
}

.bg-lightgrey .quote:before{
  color: #fff;

}

.quote__text{
  font-size: calc(28rem/16);
  line-height: calc(50/36);
  position: relative;
  z-index: 1;

  @media screen and (min-width: 768px){
    font-size: calc(36rem/16);
    line-height: calc(56/36);
  }
}

.quote__author{
  font-family: var(--font-bold);
  font-size: calc(20rem/16);
  margin-top: calc(10rem/16);
  position: relative;
  z-index: 2;
  @media screen and (min-width: 768px){
     margin-top: calc(20rem/16);
  }
}