.breadcrumb{
  margin-bottom: 0;
  background-color: transparent;
}

.breadcrumb-wrapper{
  background-color: #656565;
  color: #fff;
}

.breadcrumb-item{
  opacity: 0.7;
}

.breadcrumb-item.active{
  color: #fff;
  opacity: 1;
}

.breadcrumb-item:hover{
  opacity: 1;
}
.breadcrumb-item+.breadcrumb-item:before{
  color: #fff;
}