.accordion .card{
  @media screen and (max-width: 767px){
    border: none;
  }
}

.accordion .card:first-child{
  @media screen and (max-width: 767px){
    border-top: 1px solid rgba(0,0,0,.125);
  }
}


.accordion .card-header{
  padding: 0;
}
.accordion .card-title{
  background-color: var(--color-primary);
  color: #fff;
  border: none;
  margin-bottom: 0;
  display: block;
  width: 100%;
  padding: calc(20rem/16);
  text-transform: uppercase;
  font-family: var(--font-black);
  letter-spacing: calc(6rem/16);
  font-size: calc(16rem/16);
  position: relative;
  @media screen and (max-width: 767px){
     padding-bottom: calc(30rem/16);
  }
}

.accordion .card-title:focus{
  outline: none;
  box-shadow: none;
}

.accordion .card-title.collapsed{
  background-color: var(--color-darkgrey);
}


.accordion.accordion--white .card-title{
  background-color: #fff;
  color: var(--color-darkgrey);
  padding: calc(40rem/16) calc(20rem/16);
}

.accordion.accordion--white .card-title.collapsed{
  background-color: #fff;
  color: var(--color-darkgrey);
}

.accordion.accordion--white .card-title.collapsed .card-title__hl{
  color: var(--color-default);
}


.accordion.accordion--white .card-title__hl:after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: calc(4rem/16);
  width: calc(35rem/16);
  background-color: var(--color-primary);
  
  @media screen and (max-width: 767px){
     display: none;
  }
}

.accordion.accordion--white .card-title.collapsed .card-title__hl:after{
  display: none;
}


.accordion .card-arrow-down{
  position: absolute;
  width: calc(19rem/16);
  height: calc(5rem/16);
  opacity: 1;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
  bottom: calc(12rem/16);
}

.tabbing-slide .accordion .card-arrow-down{
  bottom: calc(18rem/16);

}

.accordion .card-arrow-down:after,
.accordion .card-arrow-down:before {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #fff;
}

.tabbing-slide .accordion .card-arrow-down:after,
.tabbing-slide .accordion .card-arrow-down:before{
  background: var(--color-primary);
}

.tabbing-slide .accordion .card-title.collapsed .card-arrow-down:after,
.tabbing-slide .accordion .card-title.collapsed .card-arrow-down:before{
  background: var(--color-default);
}

.accordion .card-arrow-down:before {
  left: 0;
  transform: skew(0deg,30deg);
}

.accordion .card-arrow-down:after {
  right: 0;
  width: 50%;
  transform: skew(0deg,-30deg);
}

.accordion .card-title.collapsed .card-arrow-down{
  transform: translateX(-50%) rotate(0deg);
}

.accordion .card-title.collapsed .card-arrow-down:after,
.accordion .card-title.collapsed .card-arrow-down:before{
  background: var(--color-primary);
}
