.newsletter-signup{

    padding: clamp(calc(25rem/16), 4vw, calc(67rem/16)) clamp(calc(20rem/16), 6vw, calc(105rem/16));
}
.newsletter-signup__subtitle{
    font-size: calc(15rem/16);
    line-height: 1;
    letter-spacing: calc(6.67rem/16);
    color: var(--color-primary);
    font-family: var(--font-bold);
    margin-bottom: calc(10rem/16);
    @media screen and (min-width: 768px){
        margin-bottom: 0;
    }
}
.newsletter-signup__form{

    width: 100%;
    gap: calc(30rem/16);
    @media screen and (min-width: 768px){
        display: flex;
    }
}