/* ordered list of all media queries (for mqpacker)*/
@media screen and (max-width: 767px) { }
@media screen and (min-width: 768px) { }
@media screen and (max-width: 991px) { }
@media screen and (min-width: 992px) { }
@media screen and (min-width: 1100px) { }
@media screen and (min-width: 1200px) { }
@media screen and (min-width: 1280px) { }
@media screen and (min-width: 1300px) { }
@media screen and (min-width: 1400px) { }
@media screen and (min-width: 1600px) { }