.bike-item{
    background-color: var(--color-grey-100);
    @media screen and (max-width: 767px){
        padding: calc(16rem/16);
    }
}
.bike-item + .bike-item{
    margin-top: calc(15rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(31rem/16);
    }
}
.bike-item__content{
    padding: 0 calc(31rem/16);
    width: 100%;
}
.bike-item__img{
    width: 100%;
    max-width: calc(89rem/16);
}