.newsletter-slide{
    position: relative;
}

.newsletter-slide--light{
    color: #fff;
}

.newsletter-slide__body{

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    
    @media screen and (max-width: 767px){
        left: calc(20rem/16);
        right: calc(20rem/16);
    }

    @media screen and (min-width: 768px){
        max-width: calc(630rem/16);
        width: 100%;
    }
}

.newsletter-slide__text{
    text-transform: uppercase;
    letter-spacing: calc(5rem/16);
    font-size: calc(15rem/16);
    font-family: var(--font-bold);
}

.newsletter-slide .newsletter-slide__form{
    margin-top: calc(40rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(85rem/16);
    }
}

.newsletter-slide--light .newsletter-slide__form .form-control{
    color: #fff;
    border-bottom-color: #fff;
}

.newsletter-slide__form .floating-label{
    background-color: transparent;
    letter-spacing: calc(4rem/16);
}

.newsletter-slide__form .form-control{
    background-color: transparent;
}