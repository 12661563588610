.tv-wrapper{
  position: relative;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
}

.tv-wrapper__logo-sujet{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.tv-wrapper__logo-sujet__logo{
  position: absolute;
  top: calc(78rem/16);
  left:  calc(-97rem/16);
}

.tv-wrapper__logo-sujet__brush{
  width: calc(290rem/16);
}
.tv-wrapper__videos {
    height: calc(100% - 4rem);
    overflow: hidden;
}
.tv-wrapper__video{
  height: auto;
  width: auto;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
    position: relative;
}
.tv-wrapper__videos .pimcore_tag_video{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.tv-wrapper__image{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 64px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.tv-wrapper__news{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: var(--color-default);
    overflow: hidden;
}

.tv-wrapper__news__logo{
  width: calc(64rem/16);
  height: calc(64rem/16);
  background-color: var(--color-primary);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
  float: left;
  padding: 0 10px;
}

.tv-wrapper__news__logo img{
  width: calc(50rem/16);
}

.tv-wrapper__news__item{
  display: inline-block;
  align-items: center;

  background-color: var(--color-default);
  color: var(--color-primary);

  font-size: calc(26rem/16);
  line-height: calc(36/26);

  text-transform: uppercase;
  font-family: var(--font-black);
  height: calc(64rem/16);
  letter-spacing: calc(1rem/16);
}

.tv-wrapper__news__item:first-child .tv-wrapper__news__part.news-part__title{
  padding-left: 0;
}

.tv-wrapper__news__part{
  padding: calc(13rem/16) calc(25rem/16);
  height: calc(64rem/16);
  display: inline-block;
  align-items: center;
  transform: skew(-20deg);
}

.tv-wrapper__news__part>span{
  transform: skew(20deg);
  display: block;
}

.tv-wrapper__news__part.news-part__title{
  padding-left: calc(250rem/16);
}

.tv-wrapper__news__part.news-part__news{
  background-color: var(--color-grey);
  color: #fff;
}

.tv-wrapper__news__marquee{
  white-space: nowrap;
  animation: marquee 35s linear infinite;
  display: inline-block;
    will-change: transform;
    transform: translateZ(0);
}

@keyframes marquee {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(-100%);
  }
}


.tv-wrapper__news__marquee-copy{
  white-space: nowrap;
  animation: marquee-copy 22s linear infinite;
  display: flex;
  animation-delay: 11s;
  margin-left: calc(20rem/16);
}


@keyframes marquee-copy {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-200%);
  }
}

.tv-wrapper__play{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 5;
  background: var(--color-primary);
  color: #fff;
  cursor: pointer;
}

.tv-wrapper__play .icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 42px;
  margin-left: 2px;
}