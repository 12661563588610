.bottom-tab-nav{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: var(--color-grey-100);
    z-index: 15;
    padding: calc(10rem/16) 0;
    box-shadow: 0 calc(-10rem/16) calc(50rem/16) 0 rgba(0,0,0,0.1);
}
.bottom-tab-nav__content{
    display: flex;
    background: #FFF;
    border-radius: calc(30rem/16);
    border: solid var(--color-lightgrey) 2px;
}
.bottom-tab-nav__button{
    border-radius: calc(30rem/16);
    width: 50%;
    padding: calc(10rem/16) calc(10rem/16);
    text-align: center;
    font-size: calc(16rem/16);
    line-height: calc(17/16);
    text-transform: uppercase;
    font-family: var(--font-black);
    border: none;
    background: #FFF;
}
.bottom-tab-nav__button.active{
    border: 2px solid #000;
}
.bottom-tab-nav__button:focus{
    outline: none;
}