.feature-poi__title{
    font-size: calc(16rem/16);
    line-height: calc(19/16);
    letter-spacing: calc(2rem/16);
    text-transform: uppercase;
    font-family: var(--font-black);
}
.feature-poi__item{
    height: 100%;
}

/*slider*/
.feature-poi{
    @media screen and (min-width: 768px){
        height: 100%;
    }
}
.feature-poi .slick-arrow{
    font-size: calc(12rem/16);
    color:var(--color-default);
    background-color: #FFFFFF;
    border-radius: 50%;
    border: solid var(--color-default) calc(2rem/16);
    height: calc(40rem/16);
    width: calc(40rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 100%;
    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        height: calc(60rem/16);
        width: calc(60rem/16);
    }
}
.feature-poi .slick-arrow.slick-prev{
    left: 0;
}
.feature-poi .slick-arrow.slick-prev .icon{
    margin-top: calc(0rem/16);
    margin-left: calc(-2rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(-2rem/16);
        margin-left: calc(-3rem/16);
    }
}
.feature-poi .slick-arrow.slick-next .icon{
    margin-top: calc(0rem/16);
    margin-left: calc(2rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(2rem/16);
        margin-left: calc(4rem/16);
    }
}
.feature-poi .slick-arrow.slick-next{
    left: calc(55rem/16);
    @media screen and (min-width: 768px){
        left: calc(75rem/16);
    }
}
.feature-poi .slick-dots{
    visibility: hidden;
    height: 0;
    margin: 0;
}
.feature-poi .slick-slide{
    margin: 0 calc(10rem/16);
}
.feature-poi .slick-track{
    margin: 0 calc(-10rem/16);
}

/*overlay*/
@media screen and (max-width: 767px){
    .feature-poi__overlay{
        position: fixed;
        background-color: var(--color-grey-100);
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        overflow-y: scroll;
        z-index: 10;
        padding: calc(50rem/16) calc(30rem/16);
        transition: transform 200ms ease-in-out;
        transform: translateX(-110%);
    }
    .feature-poi__overlay.is-open{
        transform: translateX(0%);
    }
    .feature-poi__overlay-close{
        position: absolute;
        top: calc(10rem/16);
        right: calc(10rem/16);
    }
}

.feature-poi__overlay{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.feature-poi__overlay .ajax-loading{
    background-color: transparent;
    @media screen and (min-width: 768px){
        top: calc(-20rem/16);
        bottom: calc(-20rem/16);
        left: calc(-20rem/16);
        right: calc(-20rem/16);
    }

}

.feature-poi__arrows{
    display: flex;
}

.feature-poi__arrow-btn{
    width: calc(60rem/16);
    height: calc(60rem/16);
    border: 2px solid var(--color-default);
    border-radius: 50%;
    background-color: #fff;
    color: var(--color-default);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.feature-poi__arrow-btn:disabled{
    opacity: 0.4;
    cursor: auto;
}

.feature-poi__arrow-btn--prev .icon{
    position: relative;
    top: -1px;
    left: -1px;
}

.feature-poi__arrow-btn--next .icon{
    position: relative;
    top: 1px;
}

.feature-poi-slider__overlay-close {
    position: absolute;
    top: calc(-30rem/16);
    right: calc(-10rem/16);
}