/* https://matomo.org/faq/heatmap-session-recording/faq_24426/ */
.matomoHsr .modal,
.matomoHsr .modal-backdrop,
.matomoHsr .overlay,
.matomoHsr .dropdown.show .dropdown-menu,
.matomoHsr .navbar-search{
    display:none !important;
}

.matomoHsr .header-sujet,
.matomoHsr .configuration-canvas{
    max-height: 650px!important;
}

.matomoHsr .product-hotspots,
.matomoHsr .product-hotspots img{
    max-height: 960px!important;
}

.matomoHsr .tabbing-overlay-slide{
    min-height: 100%!important;
}

.matomoHsr .main-area,
.matomoHsr .min-vh-100{
    height: 100%!important;
    min-height: 100%!important;
}

