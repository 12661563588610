@font-face {
    font-family: "iconfont-tech";
    src: url('/cache-buster-1742800893765/static/build/icons-tech/font/iconfont-tech.eot?#iefix') format('eot'),
    url('/cache-buster-1742800893765/static/build/icons-tech/font/iconfont-tech.woff') format('woff'),
    url('/cache-buster-1742800893765/static/build/icons-tech/font/iconfont-tech.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon-tech {
    display: inline-block;
    font-family: "iconfont-tech";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-tech-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-tech-2x { font-size: 2em; }
.icon-tech-3x { font-size: 3em; }
.icon-tech-4x { font-size: 4em; }
.icon-tech-5x { font-size: 5em; }
.icon-tech-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-tech-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-tech-ul > li {
    position: relative;
}
.icon-tech-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-tech-li.icon-tech-lg {
    left: -1.85714286em;
}

.icon-tech-rotate-90 {
    transform: rotate(90deg);
}
.icon-tech-rotate-180 {
    transform: rotate(180deg);
}
.icon-tech-rotate-270 {
    transform: rotate(270deg);
}
.icon-tech-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-tech-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-tech-rotate-90,
:root .icon-tech-rotate-180,
:root .icon-tech-rotate-270,
:root .icon-tech-flip-horizontal,
:root .icon-tech-flip-vertical {
  filter: none;
}

.icon-tech-icon_4BS:before { content: "\EA01" }
.icon-tech-icon_adjustable-wheelbase:before { content: "\EA02" }
.icon-tech-icon_advanced-cockpit:before { content: "\EA03" }
.icon-tech-icon_advanced-internal-cabel-routing:before { content: "\EA04" }
.icon-tech-icon_advanced-internal-routing:before { content: "\EA05" }
.icon-tech-icon_air-forming:before { content: "\EA06" }
.icon-tech-icon_air_condition:before { content: "\EA07" }
.icon-tech-icon_asymmetric-chainstays:before { content: "\EA08" }
.icon-tech-icon_b-plus:before { content: "\EA09" }
.icon-tech-icon_balanced-frame:before { content: "\EA0A" }
.icon-tech-icon_balloon-bike:before { content: "\EA0B" }
.icon-tech-icon_belt_drive:before { content: "\EA0C" }
.icon-tech-icon_boost:before { content: "\EA0D" }
.icon-tech-icon_bremsen:before { content: "\EA0E" }
.icon-tech-icon_capsuled-motor:before { content: "\EA0F" }
.icon-tech-icon_carbon-chain-catcher:before { content: "\EA10" }
.icon-tech-icon_carbon-integrated-carrier:before { content: "\EA11" }
.icon-tech-icon_center-cable-guide:before { content: "\EA12" }
.icon-tech-icon_chainstay_shield:before { content: "\EA13" }
.icon-tech-icon_comfort-concept:before { content: "\EA14" }
.icon-tech-icon_conical-srew-connection:before { content: "\EA15" }
.icon-tech-icon_daempfer:before { content: "\EA16" }
.icon-tech-icon_damen-herren:before { content: "\EA17" }
.icon-tech-icon_double-bearing:before { content: "\EA18" }
.icon-tech-icon_double-fusion-technology:before { content: "\EA19" }
.icon-tech-icon_e-bike:before { content: "\EA1A" }
.icon-tech-icon_electronic-shifting-ready:before { content: "\EA1B" }
.icon-tech-icon_eps-molded:before { content: "\EA1C" }
.icon-tech-icon_feder-saetze:before { content: "\EA1D" }
.icon-tech-icon_flex-link:before { content: "\EA1E" }
.icon-tech-icon_full-internal-cable-routing:before { content: "\EA1F" }
.icon-tech-icon_gabel:before { content: "\EA20" }
.icon-tech-icon_girls-edition:before { content: "\EA21" }
.icon-tech-icon_gps-anti-theft:before { content: "\EA22" }
.icon-tech-icon_high-pressure-molded-carbon:before { content: "\EA23" }
.icon-tech-icon_hollow-carbon-dropout:before { content: "\EA24" }
.icon-tech-icon_hot-melt-nano-carbon:before { content: "\EA25" }
.icon-tech-icon_hydroforming:before { content: "\EA26" }
.icon-tech-icon_i-cone-plus:before { content: "\EA27" }
.icon-tech-icon_i-cone:before { content: "\EA28" }
.icon-tech-icon_integrated-carbon-dropout:before { content: "\EA29" }
.icon-tech-icon_integrated-chain-catcher:before { content: "\EA2A" }
.icon-tech-icon_internal-battery:before { content: "\EA2B" }
.icon-tech-icon_internal-dropper-post-cable-routing:before { content: "\EA2C" }
.icon-tech-icon_isgg05:before { content: "\EA2D" }
.icon-tech-icon_kamm-tail-design:before { content: "\EA2E" }
.icon-tech-icon_kettenschutz:before { content: "\EA2F" }
.icon-tech-icon_kinderanhaenger:before { content: "\EA30" }
.icon-tech-icon_laser-cutting:before { content: "\EA31" }
.icon-tech-icon_laufraeder:before { content: "\EA32" }
.icon-tech-icon_lichtanlage:before { content: "\EA33" }
.icon-tech-icon_linkage_shield:before { content: "\EA34" }
.icon-tech-icon_maximum-power-transfer:before { content: "\EA35" }
.icon-tech-icon_metric-damper:before { content: "\EA36" }
.icon-tech-icon_power-transfer:before { content: "\EA37" }
.icon-tech-icon_raptor-dropout:before { content: "\EA38" }
.icon-tech-icon_robot-welding:before { content: "\EA39" }
.icon-tech-icon_rock-guard:before { content: "\EA3A" }
.icon-tech-icon_rollentainer:before { content: "\EA3B" }
.icon-tech-icon_rta-15:before { content: "\EA3C" }
.icon-tech-icon_rta:before { content: "\EA3D" }
.icon-tech-icon_sattelstuetze:before { content: "\EA3E" }
.icon-tech-icon_schaltgruppe:before { content: "\EA3F" }
.icon-tech-icon_scheibenbremse:before { content: "\EA40" }
.icon-tech-icon_schutzbleche:before { content: "\EA41" }
.icon-tech-icon_scs:before { content: "\EA42" }
.icon-tech-icon_seitenstaender:before { content: "\EA43" }
.icon-tech-icon_sitzposition:before { content: "\EA44" }
.icon-tech-icon_sl-alu:before { content: "\EA45" }
.icon-tech-icon_slide-in-battery:before { content: "\EA46" }
.icon-tech-icon_smart-headset:before { content: "\EA47" }
.icon-tech-icon_smart-thru-axle:before { content: "\EA48" }
.icon-tech-icon_specific-tubes:before { content: "\EA49" }
.icon-tech-icon_speedaxle-sqr:before { content: "\EA4A" }
.icon-tech-icon_steering-stop:before { content: "\EA4B" }
.icon-tech-icon_stone-guard:before { content: "\EA4C" }
.icon-tech-icon_storage_box:before { content: "\EA4D" }
.icon-tech-icon_t-axle:before { content: "\EA4E" }
.icon-tech-icon_tapered-gabelschaft:before { content: "\EA4F" }
.icon-tech-icon_top-performance-aluminum:before { content: "\EA50" }
.icon-tech-icon_triple-fusion:before { content: "\EA51" }
.icon-tech-icon_ud-carbon:before { content: "\EA52" }
.icon-tech-icon_vibrex:before { content: "\EA53" }
.icon-tech-icon_xi-geometry:before { content: "\EA54" }
.icon-tech-icon_xi-kinematic:before { content: "\EA55" }
.icon-tech-icon_zubehoer:before { content: "\EA56" }


:root {
--icon-icon_4BS: "\EA01";
    --icon-icon_adjustable-wheelbase: "\EA02";
    --icon-icon_advanced-cockpit: "\EA03";
    --icon-icon_advanced-internal-cabel-routing: "\EA04";
    --icon-icon_advanced-internal-routing: "\EA05";
    --icon-icon_air-forming: "\EA06";
    --icon-icon_air_condition: "\EA07";
    --icon-icon_asymmetric-chainstays: "\EA08";
    --icon-icon_b-plus: "\EA09";
    --icon-icon_balanced-frame: "\EA0A";
    --icon-icon_balloon-bike: "\EA0B";
    --icon-icon_belt_drive: "\EA0C";
    --icon-icon_boost: "\EA0D";
    --icon-icon_bremsen: "\EA0E";
    --icon-icon_capsuled-motor: "\EA0F";
    --icon-icon_carbon-chain-catcher: "\EA10";
    --icon-icon_carbon-integrated-carrier: "\EA11";
    --icon-icon_center-cable-guide: "\EA12";
    --icon-icon_chainstay_shield: "\EA13";
    --icon-icon_comfort-concept: "\EA14";
    --icon-icon_conical-srew-connection: "\EA15";
    --icon-icon_daempfer: "\EA16";
    --icon-icon_damen-herren: "\EA17";
    --icon-icon_double-bearing: "\EA18";
    --icon-icon_double-fusion-technology: "\EA19";
    --icon-icon_e-bike: "\EA1A";
    --icon-icon_electronic-shifting-ready: "\EA1B";
    --icon-icon_eps-molded: "\EA1C";
    --icon-icon_feder-saetze: "\EA1D";
    --icon-icon_flex-link: "\EA1E";
    --icon-icon_full-internal-cable-routing: "\EA1F";
    --icon-icon_gabel: "\EA20";
    --icon-icon_girls-edition: "\EA21";
    --icon-icon_gps-anti-theft: "\EA22";
    --icon-icon_high-pressure-molded-carbon: "\EA23";
    --icon-icon_hollow-carbon-dropout: "\EA24";
    --icon-icon_hot-melt-nano-carbon: "\EA25";
    --icon-icon_hydroforming: "\EA26";
    --icon-icon_i-cone-plus: "\EA27";
    --icon-icon_i-cone: "\EA28";
    --icon-icon_integrated-carbon-dropout: "\EA29";
    --icon-icon_integrated-chain-catcher: "\EA2A";
    --icon-icon_internal-battery: "\EA2B";
    --icon-icon_internal-dropper-post-cable-routing: "\EA2C";
    --icon-icon_isgg05: "\EA2D";
    --icon-icon_kamm-tail-design: "\EA2E";
    --icon-icon_kettenschutz: "\EA2F";
    --icon-icon_kinderanhaenger: "\EA30";
    --icon-icon_laser-cutting: "\EA31";
    --icon-icon_laufraeder: "\EA32";
    --icon-icon_lichtanlage: "\EA33";
    --icon-icon_linkage_shield: "\EA34";
    --icon-icon_maximum-power-transfer: "\EA35";
    --icon-icon_metric-damper: "\EA36";
    --icon-icon_power-transfer: "\EA37";
    --icon-icon_raptor-dropout: "\EA38";
    --icon-icon_robot-welding: "\EA39";
    --icon-icon_rock-guard: "\EA3A";
    --icon-icon_rollentainer: "\EA3B";
    --icon-icon_rta-15: "\EA3C";
    --icon-icon_rta: "\EA3D";
    --icon-icon_sattelstuetze: "\EA3E";
    --icon-icon_schaltgruppe: "\EA3F";
    --icon-icon_scheibenbremse: "\EA40";
    --icon-icon_schutzbleche: "\EA41";
    --icon-icon_scs: "\EA42";
    --icon-icon_seitenstaender: "\EA43";
    --icon-icon_sitzposition: "\EA44";
    --icon-icon_sl-alu: "\EA45";
    --icon-icon_slide-in-battery: "\EA46";
    --icon-icon_smart-headset: "\EA47";
    --icon-icon_smart-thru-axle: "\EA48";
    --icon-icon_specific-tubes: "\EA49";
    --icon-icon_speedaxle-sqr: "\EA4A";
    --icon-icon_steering-stop: "\EA4B";
    --icon-icon_stone-guard: "\EA4C";
    --icon-icon_storage_box: "\EA4D";
    --icon-icon_t-axle: "\EA4E";
    --icon-icon_tapered-gabelschaft: "\EA4F";
    --icon-icon_top-performance-aluminum: "\EA50";
    --icon-icon_triple-fusion: "\EA51";
    --icon-icon_ud-carbon: "\EA52";
    --icon-icon_vibrex: "\EA53";
    --icon-icon_xi-geometry: "\EA54";
    --icon-icon_xi-kinematic: "\EA55";
    --icon-icon_zubehoer: "\EA56";
    
}