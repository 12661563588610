.badge{
  background-color: #fff;
  color: var(--color-primary);
  text-transform: uppercase;
  font-family: var(--font-black);
  font-size: calc(13rem/16);
  font-weight: normal;
  letter-spacing: calc(4.5rem/16);
  text-shadow: none;
  text-align: center;
  height: calc(30rem/16);
  width: calc(250rem/16);

  transform: rotate(-38deg);
  position: absolute;
  top: calc(20rem/16);
  left: calc(-80rem/16);
  z-index: 2;
  padding: calc(8rem/16) calc(20rem/16);
}
