.slide {
    padding-top: calc(35rem/16);
    padding-bottom: calc(35rem/16);
    position: relative;

    @media screen and (min-width: 768px){
        padding-top: calc(70rem/16);
        padding-bottom: calc(70rem/16);
    }

    @media screen and (min-width: 992px){
        padding-top: calc(115rem/16);
        padding-bottom: calc(115rem/16);
    }
}

.slide--bg{
    background-size: cover;
    background-repeat: no-repeat;
}

.slide--no-bg-img{
    background-image: none!important;
}

.slide--pt-sm {
    padding-top: calc(20rem/16);

    @media screen and (min-width: 768px){
        padding-top: calc(50rem/16);
    }

    @media screen and (min-width: 992px){
        padding-top: calc(72rem/16);
    }
}