.navigation-teaser-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: 'big big small'
        'big big small-2';
    column-gap: calc(30rem/16);
}

.navigation-teaser-grid__big {
    grid-area: big;
}

.navigation-teaser-grid__small {
    grid-area: small;
}

.navigation-teaser-grid__small-2 {
    grid-area: small-2;
}