.social-media-login{
    display: block;
    margin-top: calc(20rem/16);
    letter-spacing: calc(3.9rem/16);
    font-size: calc(16rem/16);
    text-transform: none;
}

.checkout-sidebar__item+.checkout-sidebar__item{
    margin-top: calc(40rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(60rem/16);
    }
}
.checkout-sidebar__title{
    font-family: var(--font-black);
    text-transform: uppercase;
    letter-spacing: calc(4rem/16);
    font-size: calc(22rem/16);
    margin-bottom: calc(12rem/16);
}

.checkout-sidebar__title .icon{
    font-size: calc(15rem/16);
    margin-left: calc(5rem/16);
}

.checkout-sidebar__box{
    line-height: calc(24/18);
    background-color: var(--color-grey-100);
    padding: calc(15rem/16) calc(30rem/16);
}

.checkout-sidebar__box--payment img.payment-box__item {
    width:34%;
    @media screen and (min-width: 768px){
        width:auto;
    }
    height:2rem;
    margin-top:1rem;
    margin-right:1rem;
}

.checkout-sidebar__box--payment .payment-box__item + .payment-box__item {
    margin-left:0;
}

.checkout-product-items .product-cart-item{
    padding: calc(20rem/16) 0;
}

.checkout-product-items .product-cart-item:first-child{
    border-top: 1px solid var(--color-lightgrey);
}

.checkout-summary .cart-summary{
    border: none;
    padding: 0;
    margin: 0;
}

.checkout-summary{
    border-top: 1px solid var(--color-default);
    padding-top: calc(30rem/16);
}

.success-slide{
    text-align: center;
    padding: calc(70rem/16) 0;

    @media screen and (min-width: 768px){
       padding: calc(300rem/16) 0;
    }
}

.success-slide__title{
    font-size: calc(35rem/16);
    letter-spacing: calc(10rem/16);
    margin-bottom: calc(20rem/16);
    font-family: var(--font-black);
    text-transform: uppercase;

    @media screen and (min-width: 768px){
       font-size: calc(60rem/16);
        letter-spacing: calc(22rem/16);
        margin-bottom: calc(30rem/16);
    }
}

.success-slide__icon{
    color: var(--color-primary);
    font-size: calc(40rem/16);
    margin-bottom: calc(10rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(80rem/16);
        margin-bottom: calc(20rem/16);
    }
}