/*grid*/
.geo-poi__grid{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-column-gap: calc(30rem/16);
    grid-row-gap: calc(30rem/16);
    grid-template-areas:
            "geometryimage"
            "geometrytable"
            "geometrycaption";
    @media screen and (min-width: 768px){
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        grid-row-gap: calc(62rem/16);
        grid-template-areas:
            "geometrytable geometryimage"
            "geometrytable geometrycaption";
    }
}
.geo-poi__grid-table{
    grid-area: geometrytable;
    position: relative;
}
.geo-poi__grid-img{
    grid-area: geometryimage;
}

/*table*/
.geo-poi__table{
    font-size: calc(16rem/16);
    line-height: calc(20/16);
    letter-spacing: calc(-0.2rem/16);
}
.geo-poi__table th, 
.geo-poi__table td{
    padding: calc(14rem/16);
}
.geo-poi__table tbody th, 
.geo-poi__table tbody td{
    background-color: var(--color-grey-100);
}
.geo-poi__table th:first-child, 
.geo-poi__table td:first-child {
    padding: calc(14rem/16) calc(14rem/16) calc(14rem/16) calc(31rem/16);
}
.geo-poi__table th:last-child, 
.geo-poi__table td:last-child{
    padding: calc(14rem/16) calc(31rem/16) calc(14rem/16) calc(14rem/16);
}
@media screen and (max-width: 767px){
    .geo-poi__table td{
        padding: calc(14rem/16) calc(31rem/16) calc(14rem/16) calc(14rem/16);
    }
}
.geo-poi__table th{
    text-transform: uppercase;
    font-family: var(--font-black);
    letter-spacing: calc(1.75rem/16);
    font-size: calc(14rem/16);
    line-height: calc(16/14);
    vertical-align: middle;
}
.geo-poi__table th.geo-poi__table-bikesize{
    font-family: var(--font-bold);
    letter-spacing: calc(-0.2rem/16);
    font-size: calc(16rem/16);
    line-height: calc(20/16);
}
.geo-poi__table td{
    border-top: 1px solid #dee2e6;
}

.geo-poi__table .geo-poi__table-row.is-active th, 
.geo-poi__table .geo-poi__table-row.is-active td{
    background-color: var(--color-lightgrey);
}
.geo-poi__table-row.is-active th{
    color: var(--color-primary);
}
.geo-poi__table-row{
    cursor: pointer;
}

.geo-poi__table-scroll-right{
    position: absolute;
    top: calc(16rem/16);
    right: calc(5rem/16);
    font-size: calc(20rem/16);
    color: var(--color-primary);
    animation: arrowPulse 1s infinite;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}
.geo-poi__table-scroll-left{
    position: absolute;
    top: calc(16rem/16);
    left: calc(5rem/16);
    font-size: calc(20rem/16);
    color: var(--color-primary);
    animation: arrowPulseLeft 1s infinite;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}
.geo-poi__table-scroll-left.is-active,
.geo-poi__table-scroll-right.is-active{
    opacity: 1;
}
.geo-poi__table-scroll-left,
.geo-poi__table-scroll-right{
    @media screen and (min-width: 768px) {
        display: none;
    }
}

/*Hotspot*/
.geo-poi__hotspot{
    background: var(--color-default);
    color: #FFFFFF;
    border-radius: 50%;
    font-size: calc(13rem/16);
    font-family: var(--font-bold);
    position: absolute;
    width: calc(15rem/16);
    height: calc(15rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: translate(-50%, -50%);
    @media screen and (min-width: 768px){
        width: calc(30rem/16);
        height: calc(30rem/16);
        font-size: calc(25rem/16);
    }
}
.geo-poi__hotspot.is-active{
    background: var(--color-primary);
}

/*mobile tab nav*/
@media screen and (min-width: 768px){
    .geo-poi__tab-nav{
        display: none;
        visibility: hidden;
    }
}
@media screen and (max-width: 767px){
    .geo-poi__tab-nav{
        display: flex;
        background: var(--color-default);
        gap: calc(20rem/16);
        padding: calc(14rem/16) calc(20rem/16);
    }
    .geo-poi__tab-nav-item{
        color: #FFFFFF;
        position: relative;
        padding: 0 calc(10rem/16);
        z-index: 1;
        font-size: calc(16rem/16);
        line-height: calc(20/16);
        letter-spacing: calc(-0.2rem/16);
        font-family: var(--font-bold);
        cursor: pointer;
        border: none;
        background: none;
    }
    .geo-poi__tab-nav-item:focus, 
    .geo-poi__tab-nav-item:active{
        outline: none;
        border: none;
    }
    .geo-poi__tab-nav-item.is-active{
        color: var(--color-default);
    }
    .geo-poi__tab-nav-item.is-active:after{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        height: calc(38rem/16);
        width: calc(38rem/16);
        background: #FFFFFF;
        border-radius: 50%;
        z-index: -1;
        transform: translate(-50%, -50%);
    }
}

/*caption*/
.geo-poi__caption{
    font-size: calc(12rem/16);
    line-height: calc(20/12);
    color: var(--color-grey);
}

@keyframes arrowPulse {
    0%, 100% {
        transform: translateX(calc(5rem/16));
    }
    50% {
        transform: translateX(0);
    }
}
@keyframes arrowPulseLeft {
    0%, 100% {
        transform: translateX(calc(5rem/16)) rotate(180deg);
    }
    50% {
        transform: translateX(0) rotate(180deg);
    }
}