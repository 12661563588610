.navbar.navbar--tvt .navbar-placeholder,
.navbar.navbar--tvt .navbar-brand {
    flex: 1;
}

.navbar.navbar--tvt.is-sticky .navbar-steps {
    opacity: 0;
    visibility: hidden;
}

.navbar-steps {
    transition: all .25s ease;
}

.navbar .navbar-steps .nav-item.active .nav-link__text:after {
    transform: translateY(0) translateX(-50%);
}

.navbar-dark .navbar-blog.navbar-steps .navbar-nav .nav-link {
    color: var(--color-grey)
}

.navbar-blog.navbar-steps .navbar-nav>.nav-item:not(:last-child) {
    @media screen and (min-width: 992px){
        margin-right: calc(55rem/16);
    }
}

.navbar-steps .nav-item.is-done {
    position: relative;
}

.navbar-steps .nav-item.is-done:after {
    content: var(--icon-check);
    font-family: 'iconfont';
    font-size: calc(9rem/16);
    color: var(--color-primary);
    position: absolute;
    right: calc(7rem/16);
    top: calc(13rem/16);
}
