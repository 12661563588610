html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: calc(982rem/16);
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}

body.nav-open{
    @media (max-width: 1299px){
       overflow-y: hidden;
    }
}

body.overlay-open:before{
    content: '';
    background-color: #000;
    opacity: 0.4;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

body:after{
    content: '';
    background-color: #fff;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -5;
    transform: all 300ms ease;
}

body.open-search:after{
    opacity: 0.9;
    z-index: 9;
}

/*

.page-wrapper{
    padding-bottom: calc(50rem/16);
    
    @media screen and (min-width: 768px){
       padding-bottom: calc(100rem/16);
    }
}
*/

.page-wrapper{
    overflow: hidden;
}
#mainContent{
    @media screen and (max-width: 767px){
       padding-top: calc(70rem/16);
    }
}

#mainContent .header-img,
#mainContent .header-sujet,
#mainContent .img-teaser-grid,
#mainContent .product-main,
#mainContent .product-detail{
    @media screen and (max-width: 767px){
        margin-top: calc(-70rem/16);
    }
}

.container.container {
    max-width: calc(1430rem/16); /* 1400px breite + 30px padding */
    width: 100%;
    
    @media screen and (max-width: 767px){
       padding-left: calc(20rem/16);
       padding-right: calc(20rem/16);
    }
}

.container-medium {
    max-width: calc(1200rem/16); /* 1170px breite + 30px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.container-small {
    max-width: calc(1115rem/16); /* 1085px breite + 30px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.container-narrow {
    max-width: calc(960rem/16); /* 930px breite + 30px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.container-xsmall{
    max-width: calc(720rem/16); /* 690px breite + 30px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.container.container-half{
    max-width: calc(715rem/16);
    margin-left: auto;
    margin-right: 0;
}

.container.container-half-right{
    max-width: calc(715rem/16);
    margin-left: 0;
    margin-right: auto;
}

.container.container-hidden-xs {
    @media screen and (max-width: 767px){
        padding-left: 0;
        padding-right: 0;
    }
}
