.dropdown-menu.dropdown-static__menu{
    @media screen and (min-width: 768px){
        display: block;
        padding: calc(60rem/16);
        background-color: var(--color-grey-100);
        border: none;
        width: 100%;
        position: relative;
        z-index: 1;
    }
    
    @media screen and (max-width: 767px){
       padding: calc(20rem/16);
        box-shadow: 0 9px 20px 0 rgba(0,0,0,.15);
        width: calc(100% - 30px );
    }
}

.slide.bg-white .dropdown-menu.dropdown-static__menu{
    @media screen and (min-width: 768px){
       background-color: #fff;
    }
}

.slide.bg-grey .dropdown-menu.dropdown-static__menu{
    @media screen and (min-width: 768px){
        background-color: var(--color-lightgrey);
    }
}

.dropdown-static .btn{
    @media screen and (min-width: 768px){
        display: none;
    }
    
    @media screen and (max-width: 767px){
       width: 100%;
        margin-bottom: calc(20rem/16);
    }
}

.dropdown-static__link{
    text-transform: uppercase;
    font-family: var(--font-black);
    letter-spacing: calc(4rem/16);
    opacity: 0.3;
    transition: opacity 150ms ease;
    display: block;
    padding: calc(5rem/16) 0;
}

.dropdown-static__link.active{
    opacity: 1;
}

/*
.dropdown-static__menu ol{
    padding-left: calc(24rem/16)
}

.dropdown-static__menu li::marker {
    font-family: var(--font-black);
    margin-right: calc(3rem/16);
    letter-spacing: calc(4rem/16);
}
*/


.dropdown-static__menu li+li{
    margin-top: calc(20rem/16);
}

.dropdown-static__link:hover{
    opacity: 1;
}