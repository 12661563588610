.interest-badge{
    padding: calc(7rem/16) calc(12rem/16);
    display: flex;
    align-items: center;
    font-size: calc(14rem/16);
    font-family: var(--font-bold);
}
.interest-badge.interest-badge--teaser{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 8;
    transform: translateY(-50%);
}
.interest-badge__icon{
    margin-right: calc(8rem/16);
    font-size: calc(20rem/16);
}
