
.google-map__search-route{
  width: calc(220rem/16);
  position: relative;
  bottom: calc(15rem/16);
  left: calc(15rem/16);
  
  @media screen and (min-width: 768px){
    width: calc(300rem/16);
  }
}

.google-map__search-route .btn{
  padding: calc(14rem/16) calc(18rem/16);
  font-size: calc(15rem/16);
}

.google-map__search-route__location{
  position: absolute;
  right: calc(75rem/16);
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
}

.google-map__search-route .form-control{
  border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  border-bottom: 0;
  padding-left: calc(20rem/16);
}