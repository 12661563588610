@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1742800893203/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1742800893203/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1742800893203/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-arrow-big-right:before { content: "\EA01" }
.icon-arrow-down:before { content: "\EA02" }
.icon-arrow-left:before { content: "\EA03" }
.icon-arrow-right-thin:before { content: "\EA04" }
.icon-arrow-right:before { content: "\EA05" }
.icon-attention:before { content: "\EA06" }
.icon-auslieferpartner:before { content: "\EA07" }
.icon-auswahl:before { content: "\EA08" }
.icon-b2b-close:before { content: "\EA09" }
.icon-b2b-logout:before { content: "\EA0A" }
.icon-b2b-menu:before { content: "\EA0B" }
.icon-bag-copy:before { content: "\EA0C" }
.icon-bag-move:before { content: "\EA0D" }
.icon-bike-bold:before { content: "\EA0E" }
.icon-bike-id-bold:before { content: "\EA0F" }
.icon-bike:before { content: "\EA10" }
.icon-biker:before { content: "\EA11" }
.icon-bodyscanner:before { content: "\EA12" }
.icon-calendar-bold:before { content: "\EA13" }
.icon-calendar-cancel:before { content: "\EA14" }
.icon-calendar:before { content: "\EA15" }
.icon-cart:before { content: "\EA16" }
.icon-certificate:before { content: "\EA17" }
.icon-check:before { content: "\EA18" }
.icon-checkmark-circle:before { content: "\EA19" }
.icon-checkmark:before { content: "\EA1A" }
.icon-clock:before { content: "\EA1B" }
.icon-close-fat:before { content: "\EA1C" }
.icon-close:before { content: "\EA1D" }
.icon-dealer-marker-active:before { content: "\EA1E" }
.icon-dealer-marker:before { content: "\EA1F" }
.icon-direction:before { content: "\EA20" }
.icon-document:before { content: "\EA21" }
.icon-download:before { content: "\EA22" }
.icon-e-bikes:before { content: "\EA23" }
.icon-e-mountainbikes:before { content: "\EA24" }
.icon-e-roadbikes:before { content: "\EA25" }
.icon-edit:before { content: "\EA26" }
.icon-electric:before { content: "\EA27" }
.icon-elements:before { content: "\EA28" }
.icon-event-institution:before { content: "\EA29" }
.icon-event-mail:before { content: "\EA2A" }
.icon-event-marker:before { content: "\EA2B" }
.icon-event-phone:before { content: "\EA2C" }
.icon-event-tag:before { content: "\EA2D" }
.icon-event-website:before { content: "\EA2E" }
.icon-facebook:before { content: "\EA2F" }
.icon-female-badge-de:before { content: "\EA30" }
.icon-female-badge-en:before { content: "\EA31" }
.icon-female:before { content: "\EA32" }
.icon-file-download:before { content: "\EA33" }
.icon-flexbike:before { content: "\EA34" }
.icon-gespraech-1:before { content: "\EA35" }
.icon-gespraech-2:before { content: "\EA36" }
.icon-gespraech:before { content: "\EA37" }
.icon-gesundheit:before { content: "\EA38" }
.icon-googleplus:before { content: "\EA39" }
.icon-haendler-bold:before { content: "\EA3A" }
.icon-heart-bold-highlight:before { content: "\EA3B" }
.icon-heart-bold:before { content: "\EA3C" }
.icon-icon_double-bearing:before { content: "\EA3D" }
.icon-icon_internal-battery:before { content: "\EA3E" }
.icon-image-file-upload:before { content: "\EA3F" }
.icon-info:before { content: "\EA40" }
.icon-instagram:before { content: "\EA41" }
.icon-location:before { content: "\EA42" }
.icon-magazine:before { content: "\EA43" }
.icon-mail-1:before { content: "\EA44" }
.icon-mail:before { content: "\EA45" }
.icon-male:before { content: "\EA46" }
.icon-minus:before { content: "\EA47" }
.icon-mountainbike:before { content: "\EA48" }
.icon-online:before { content: "\EA49" }
.icon-options:before { content: "\EA4A" }
.icon-orders:before { content: "\EA4B" }
.icon-party-1:before { content: "\EA4C" }
.icon-party:before { content: "\EA4D" }
.icon-percent:before { content: "\EA4E" }
.icon-phone-1:before { content: "\EA4F" }
.icon-phone:before { content: "\EA50" }
.icon-pin:before { content: "\EA51" }
.icon-play:before { content: "\EA52" }
.icon-plus:before { content: "\EA53" }
.icon-print:before { content: "\EA54" }
.icon-profile:before { content: "\EA55" }
.icon-properties:before { content: "\EA56" }
.icon-ratenzahlung:before { content: "\EA57" }
.icon-recommendation-bold:before { content: "\EA58" }
.icon-recommendation:before { content: "\EA59" }
.icon-roadbikes:before { content: "\EA5A" }
.icon-rocket:before { content: "\EA5B" }
.icon-search:before { content: "\EA5C" }
.icon-services-download:before { content: "\EA5D" }
.icon-services-size:before { content: "\EA5E" }
.icon-settings:before { content: "\EA5F" }
.icon-shopping-bag-bold-highlight:before { content: "\EA60" }
.icon-shopping-bag-bold:before { content: "\EA61" }
.icon-shopping-cart-highlight:before { content: "\EA62" }
.icon-shopping-cart:before { content: "\EA63" }
.icon-shopping:before { content: "\EA64" }
.icon-size:before { content: "\EA65" }
.icon-star-empty:before { content: "\EA66" }
.icon-star-filled:before { content: "\EA67" }
.icon-star-round:before { content: "\EA68" }
.icon-star:before { content: "\EA69" }
.icon-support-bold:before { content: "\EA6A" }
.icon-technology-lightbulb:before { content: "\EA6B" }
.icon-technology:before { content: "\EA6C" }
.icon-three-sixty:before { content: "\EA6D" }
.icon-trash:before { content: "\EA6E" }
.icon-trekking-bike:before { content: "\EA6F" }
.icon-twitter:before { content: "\EA70" }
.icon-upload:before { content: "\EA71" }
.icon-user-extrabold:before { content: "\EA72" }
.icon-vertrag:before { content: "\EA73" }
.icon-vorsorge:before { content: "\EA74" }
.icon-warning:before { content: "\EA75" }
.icon-watch:before { content: "\EA76" }
.icon-website:before { content: "\EA77" }
.icon-weiterbildung:before { content: "\EA78" }
.icon-whatsapp:before { content: "\EA79" }
.icon-youtube:before { content: "\EA7A" }


:root {
--icon-arrow-big-right: "\EA01";
    --icon-arrow-down: "\EA02";
    --icon-arrow-left: "\EA03";
    --icon-arrow-right-thin: "\EA04";
    --icon-arrow-right: "\EA05";
    --icon-attention: "\EA06";
    --icon-auslieferpartner: "\EA07";
    --icon-auswahl: "\EA08";
    --icon-b2b-close: "\EA09";
    --icon-b2b-logout: "\EA0A";
    --icon-b2b-menu: "\EA0B";
    --icon-bag-copy: "\EA0C";
    --icon-bag-move: "\EA0D";
    --icon-bike-bold: "\EA0E";
    --icon-bike-id-bold: "\EA0F";
    --icon-bike: "\EA10";
    --icon-biker: "\EA11";
    --icon-bodyscanner: "\EA12";
    --icon-calendar-bold: "\EA13";
    --icon-calendar-cancel: "\EA14";
    --icon-calendar: "\EA15";
    --icon-cart: "\EA16";
    --icon-certificate: "\EA17";
    --icon-check: "\EA18";
    --icon-checkmark-circle: "\EA19";
    --icon-checkmark: "\EA1A";
    --icon-clock: "\EA1B";
    --icon-close-fat: "\EA1C";
    --icon-close: "\EA1D";
    --icon-dealer-marker-active: "\EA1E";
    --icon-dealer-marker: "\EA1F";
    --icon-direction: "\EA20";
    --icon-document: "\EA21";
    --icon-download: "\EA22";
    --icon-e-bikes: "\EA23";
    --icon-e-mountainbikes: "\EA24";
    --icon-e-roadbikes: "\EA25";
    --icon-edit: "\EA26";
    --icon-electric: "\EA27";
    --icon-elements: "\EA28";
    --icon-event-institution: "\EA29";
    --icon-event-mail: "\EA2A";
    --icon-event-marker: "\EA2B";
    --icon-event-phone: "\EA2C";
    --icon-event-tag: "\EA2D";
    --icon-event-website: "\EA2E";
    --icon-facebook: "\EA2F";
    --icon-female-badge-de: "\EA30";
    --icon-female-badge-en: "\EA31";
    --icon-female: "\EA32";
    --icon-file-download: "\EA33";
    --icon-flexbike: "\EA34";
    --icon-gespraech-1: "\EA35";
    --icon-gespraech-2: "\EA36";
    --icon-gespraech: "\EA37";
    --icon-gesundheit: "\EA38";
    --icon-googleplus: "\EA39";
    --icon-haendler-bold: "\EA3A";
    --icon-heart-bold-highlight: "\EA3B";
    --icon-heart-bold: "\EA3C";
    --icon-icon_double-bearing: "\EA3D";
    --icon-icon_internal-battery: "\EA3E";
    --icon-image-file-upload: "\EA3F";
    --icon-info: "\EA40";
    --icon-instagram: "\EA41";
    --icon-location: "\EA42";
    --icon-magazine: "\EA43";
    --icon-mail-1: "\EA44";
    --icon-mail: "\EA45";
    --icon-male: "\EA46";
    --icon-minus: "\EA47";
    --icon-mountainbike: "\EA48";
    --icon-online: "\EA49";
    --icon-options: "\EA4A";
    --icon-orders: "\EA4B";
    --icon-party-1: "\EA4C";
    --icon-party: "\EA4D";
    --icon-percent: "\EA4E";
    --icon-phone-1: "\EA4F";
    --icon-phone: "\EA50";
    --icon-pin: "\EA51";
    --icon-play: "\EA52";
    --icon-plus: "\EA53";
    --icon-print: "\EA54";
    --icon-profile: "\EA55";
    --icon-properties: "\EA56";
    --icon-ratenzahlung: "\EA57";
    --icon-recommendation-bold: "\EA58";
    --icon-recommendation: "\EA59";
    --icon-roadbikes: "\EA5A";
    --icon-rocket: "\EA5B";
    --icon-search: "\EA5C";
    --icon-services-download: "\EA5D";
    --icon-services-size: "\EA5E";
    --icon-settings: "\EA5F";
    --icon-shopping-bag-bold-highlight: "\EA60";
    --icon-shopping-bag-bold: "\EA61";
    --icon-shopping-cart-highlight: "\EA62";
    --icon-shopping-cart: "\EA63";
    --icon-shopping: "\EA64";
    --icon-size: "\EA65";
    --icon-star-empty: "\EA66";
    --icon-star-filled: "\EA67";
    --icon-star-round: "\EA68";
    --icon-star: "\EA69";
    --icon-support-bold: "\EA6A";
    --icon-technology-lightbulb: "\EA6B";
    --icon-technology: "\EA6C";
    --icon-three-sixty: "\EA6D";
    --icon-trash: "\EA6E";
    --icon-trekking-bike: "\EA6F";
    --icon-twitter: "\EA70";
    --icon-upload: "\EA71";
    --icon-user-extrabold: "\EA72";
    --icon-vertrag: "\EA73";
    --icon-vorsorge: "\EA74";
    --icon-warning: "\EA75";
    --icon-watch: "\EA76";
    --icon-website: "\EA77";
    --icon-weiterbildung: "\EA78";
    --icon-whatsapp: "\EA79";
    --icon-youtube: "\EA7A";
    
}