.row.row--gutter-width-0 {
    margin-left:0;
    margin-right:0;
}

.row--gutter-width-0 > .col,
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"] {
    padding-left:0;
    padding-right:0;
}

@media screen and (max-width: 767px){
    .row.row--gutter-width-0-xs {
        margin-left:0;
        margin-right:0;
    }

    .row--gutter-width-0-xs > .col,
    .row--gutter-width-0-xs > [class^="col"],
    .row--gutter-width-0-xs > [class*=" col"] {
        padding-left:0;
        padding-right:0;
    }

}

.row.row--gutter-width-10 {
    margin-left:-5px;
    margin-right:-5px;
}

.row--gutter-width-10 > .col,
.row--gutter-width-10 > [class^="col"],
.row--gutter-width-10 > [class*=" col"] {
    padding-left: 5px;
    padding-right: 5px;
}


.row.row--gutter-width-20 {
    margin-left:-10px;
    margin-right:-10px;
}

.row--gutter-width-20 > .col,
.row--gutter-width-20 > [class^="col"],
.row--gutter-width-20 > [class*=" col"] {
    padding-left:10px;
    padding-right:10px;
}

.row.row--gutter-width-50 {
    margin-left: calc(-25rem / 16);
    margin-right: calc(-25rem / 16);
}

.row--gutter-width-50 > .col,
.row--gutter-width-50 > [class^="col"],
.row--gutter-width-50 > [class*=" col"] {
    padding-left:calc(25rem/16);
    padding-right:calc(25rem/16);
}

.row.row--gutter-width-jobs {
    margin-left: calc(-36rem / 16);
    margin-right: calc(-36rem / 16);
}

.row--gutter-width-jobs > .col,
.row--gutter-width-jobs > [class^="col"],
.row--gutter-width-jobs > [class*=" col"] {
    padding-left:calc(36rem/16);
    padding-right:calc(36rem/16);
}

.row.row--gutter-width-140 {
    margin-left: calc(-70rem / 16);
    margin-right: calc(-70rem / 16);
}

.row--gutter-width-140 > .col,
.row--gutter-width-140 > [class^="col"],
.row--gutter-width-140 > [class*=" col"] {
    padding-left:calc(70rem/16);
    padding-right:calc(70rem/16);
}

/* Vertical gutter */

.row--vertical-gutter-10 {
    margin-top: calc(-10rem/16);
    clear: both;
}
.row--vertical-gutter-10 > [class^="col"],
.row--vertical-gutter-10 > [class*=" col"] {
    margin-top: calc(10rem/16);
}

.row--vertical-gutter-20 {
    margin-top: calc(-20rem/16);
    clear: both;
}
.row--vertical-gutter-20 > [class^="col"],
.row--vertical-gutter-20 > [class*=" col"] {
    margin-top: calc(20rem/16);
}

.row--vertical-gutter-30 {
    margin-top: calc(-30rem/16);
    clear: both;
}
.row--vertical-gutter-30 > [class^="col"],
.row--vertical-gutter-30 > [class*=" col"] {
    margin-top: calc(30rem/16);
}

.row--vertical-gutter-40 {
    margin-top: calc(-40rem/16);
    clear: both;
}
.row--vertical-gutter-40 > [class^="col"],
.row--vertical-gutter-40 > [class*=" col"] {
    margin-top: calc(40rem/16);
}

.row--vertical-gutter-100 {
    margin-bottom: calc(-60rem/16);
    clear: both;
    @media screen and (min-width: 768px){
        margin-bottom: calc(-100rem/16);
    }
}
.row--vertical-gutter-100 > [class^="col"],
.row--vertical-gutter-100 > [class*=" col"] {
    margin-bottom: calc(60rem/16);
    @media screen and (min-width: 768px){
        margin-bottom: calc(100rem/16);
    }
}

/*Vertical gutter mobile*/
.row--gutter-sm-mobile.row--vertical-gutter-30 {
    @media screen and (max-width: 767px){
        margin-top: calc(-10rem/16);
    }
}
.row--gutter-sm-mobile.row--vertical-gutter-30 > [class^="col"],
.row--gutter-sm-mobile.row--vertical-gutter-30 > [class*=" col"] {
    @media screen and (max-width: 767px){
        margin-top: calc(10rem/16);
    }
}
.row--vertical-gutter-desktop-0{
    @media screen and (min-width: 768px){
        margin-top: 0;
    }
}
.row--vertical-gutter-desktop-0 > [class^="col"],
.row--vertical-gutter-desktop-0  > [class*=" col"]{
    @media screen and (min-width: 768px){
        margin-top: 0;
    }
}

/*gutter mobile*/
.row.row--gutter-sm-mobile {
    @media screen and (max-width: 767px){
        margin-left:-5px;
        margin-right:-5px;
    }
}

.row--gutter-sm-mobile > .col,
.row--gutter-sm-mobile > [class^="col"],
.row--gutter-sm-mobile > [class*=" col"] {
    @media screen and (max-width: 767px){
        padding-left:5px;
        padding-right:5px;
    }
}

.teaser-grid-block-row:not(:last-child){
    margin-bottom: calc(30rem/16);
}