.custom-toast{
    position: fixed;
    bottom: 0;
    right: 0;
    margin: calc(20rem/16);
    padding: calc(16rem/16);
    background: var(--color-darkgrey);
    color: #FFF;
    z-index: 5;
    transition: transform 200ms ease-in-out;
    @media screen and (min-width: 768px){
        margin: calc(30rem/16);
        padding: calc(30rem/16);
    }
}
.custom-toast.is-hidden{
    transform: translateY(200%);
}
.custom-toast__header{
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(16rem/16);
}
.custom-toast__close{
    background: none;
    border: none;
    cursor: pointer;
}