.table-tech-info{
    background-color: var(--color-grey-100);
    width: 100%;
    font-size: calc(16rem/16);
}

.table-tech-info th,
.table-tech-info td{
    padding: calc(15rem/16) calc(15rem/16);
    @media screen and (min-width: 768px){
        padding: calc(15rem/16) calc(30rem/16);
    }
}

.table-tech-info th{
    width: 40%;
    font-family: var(--font-black);
    font-size: calc(14rem/16);
    letter-spacing: calc(1.75rem/16);
    text-transform: uppercase;

    @media screen and (min-width: 768px){
        width: 35%;
    }
}

.table-tech-info tr:not(:last-child){
    border-bottom: 1px solid var(--color-lightgrey);
}