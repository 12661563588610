/*Margin Helper*/

.mt40{
    margin-top: calc(20rem/16);
}

.mt30{
    margin-top: calc(15rem/16);
}

.mt20{
    margin-top: calc(20rem/16);
}

.mb10{
    margin-bottom: calc(10rem/16);
}

.mb40{
    margin-bottom: calc(40rem/16);
}

.mb100{
    margin-bottom: calc(50rem/16);
}

@media screen and (min-width: 768px){
    .mt40{
        margin-top: calc(40rem/16);
    }
    .mt30{
        margin-top: calc(30rem/16);
    }

    .mb100{
        margin-bottom: calc(100rem/16);
    }

}

.mt-n4 {
    margin-top: -1.5rem;
}
.mb-n5 {
    margin-bottom: -3rem;
}

.row--mt30{
    margin-top: calc(30rem/16);
}

/*Padding Helper*/
.gap-2 {
    gap: calc(8rem/16);
}

.gap-3 {
    gap: calc(16rem/16);
}

.gap-30{
    gap: calc(30rem/16);
}


/*Text Color Helper*/
.text-white { color: #ffffff; }
.text-default { color: var(--color-default); }
.text-lightgrey { color: var(--color-lightgrey); }
.text-grey { color: var(--color-grey) !important; }
.text-darkgrey { color: var(--color-darkgrey); }

/*Background Color Helper*/
.bg-primary,
.bg-success,
.bg-default,
.bg-black{
    color: #fff!important;
    background-color: #000;
}

.bg-white,
.btn.bg-white{
    background-color: #fff;
    color: var(--color-default);
}

.bg-lightgrey{
    background-color: var(--color-lightgrey);
}

.bg-whitesmoke {
    background-color: var(--color-grey-100);
}
.bg-transparent{
    background-color: transparent;
}

.isClickable{
    cursor: pointer;
}

.text-line-through{
    text-decoration: line-through;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}

.centered{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.icon-with-text {
    margin-right: calc(8rem/16);
    vertical-align: -2px;
}
.link-with-icon{
    font-family: var(--font-bold);
    letter-spacing: calc(6rem/16);
    font-size: calc(16rem/16);
    line-height: calc(17/16);
    display: flex;
    align-items: center;
}

.link-with-icon .icon{
    margin-left: calc(3rem/16);
    transition: transform 250ms ease;
}

.link-with-icon:hover .icon{
    transform: translateX(5px);
}

.link-with-icon.icon-left .icon{
    margin-left: 0;
    margin-right: calc(15rem/16);
}

.link-with-icon.icon-left:hover .icon{
    transform: translateX(-5px) rotate(180deg);
}

.circle-img{
    border-radius: 50%;
}

.overflow-visible {
    overflow: visible;
}
.overflow-hidden {
    overflow: hidden;
}

.link-list>li>a{
    font-size: calc(16rem/16);
    font-family: var(--font-black);
    letter-spacing: calc(4.6rem/16);
    position: relative;
    padding: calc(5rem/16) calc(5rem/16) calc(8rem/16);
    color: var(--color-lightgrey);
    text-transform: uppercase;
    overflow: hidden;
    display: inline-block;
    transition: all 250ms ease;
}


.link-list>li>a:hover{
    color: var(--color-default);
}

.link-list>li.active>a{
    color: var(--color-default);
}

.link-list--primary>li>a:after{
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    width: calc(35rem/16);
    height: calc(4rem/16);
    background-color: var(--color-primary);
    transform: translateY(100%) translateX(-50%);
    transition: transform 250ms ease;
    margin-left: calc(-2rem/16);
}

.link-list--primary>li>a:hover:after,
.link-list--primary>li.active>a:after{
    transform: translateY(0) translateX(-50%)
}

.link-list .list-inline-item:not(:last-child){
    margin-right: calc(10rem/16);

@media screen and (min-width: 768px){
    margin-right: calc(25rem/16);
}
}

.icon-rotate-25 {
    transform: rotate(25deg);
}
.icon-rotate-45 {
    transform: rotate(45deg);
}
.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}

div.ovviosinfo.ovviosinfo{
    height: 40px;
    width: 40px;
    top: 0;
    left: auto!important;
    right: 0;
    border-radius: 50%;
}

.timer__time {
    display: inline-block;
}

.popover a{
    color: var(--color-primary);
}

/* embed responsive cover */
.embed-responsive-cover {
    height:100%;
}
.embed-responsive-cover .embed-responsive-item {
    width:auto;
    min-height:100%;
    left:50%;
    transform: translateX(-50%);
}
@media screen and (min-width: 768px){
    .embed-responsive-cover-md {
        height:100%;
    }
    .embed-responsive-cover-md .embed-responsive-item {
        width:auto;
        min-height:100%;
        left:50%;
        transform: translateX(-50%);
    }
}
.letter-spacing-0{
    letter-spacing: 0!important;
}
.letter-spacing-1 {
     letter-spacing: calc(1rem /16)!important;
 }
.letter-spacing-2 {
     letter-spacing: calc(2rem /16)!important;
 }
.letter-spacing-3 {
     letter-spacing: calc(3rem /16)!important;
 }
.letter-spacing-4 {
     letter-spacing: calc(4rem /16)!important;
 }
.letter-spacing-5 {
    letter-spacing: calc(5rem /16)!important;
}
.letter-spacing-6 {
    letter-spacing: calc(6rem /16)!important;
}
.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.flex-auto {
    flex: auto;
}


.icon-in-text {
    display: inline;
    vertical-align: -.1em;
}

.js-disable-button{
    overflow: hidden;
}

.text-transform-none{
    text-transform: none;
}

.has-overflow-badge{
    overflow: visible;
    position: relative;
}
.z-index-100 {
    z-index:100;
}
.flex-1 {
    flex:1;
}

.row--same-height{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.row--same-height {
    flex-wrap: wrap;
}

.row--same-height.slick-slider {
    display: block;
}

.row--same-height.slick-slider .slick-track{
    display: flex;
    flex-direction: row;
}

.row--same-height:before,
.row--same-height:after {
    display: none;
}

.lh-1{
    line-height: 1;
}

.object-fit__contain{
    object-fit: contain;
}