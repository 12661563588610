.wishlist-item{
    padding: calc(31rem/16) 0;
    border-top: 2px solid var(--color-lightgrey);
}
.wishlist-item__img{
    padding-bottom: calc(45rem/16);
    position: relative;
}
.wishlist-item__img:after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(/static/img/shadow.png);
    background-size: contain;
    height: calc(45rem/16);
    background-repeat: no-repeat;
    width: 100%;
    opacity: 0.5;
}
.wishlist-item__title{
    margin-bottom: calc(36rem/16);
}

/*availability*/
.wishlist-item.not-available .wishlist-item__img,
.wishlist-item.not-available .wishlist-item__info{
    opacity: 0.2;
}

.wishlist-item__availability{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    color: var(--color-primary);
    font-family: var(--font-bold);
    display: none;
    padding: calc(5rem/16) calc(20rem/16);
}
.wishlist-item.not-available .wishlist-item__availability{
    display: block;
}