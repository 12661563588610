.navbar-parent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    transition: none;
}


.navbar {
    color: var(--color-default);
    transition: background-color 0.4s ease;
    height: calc(100rem / 16);
    pointer-events: none;
    padding-left: calc(20rem / 16);
    padding-right: calc(20rem / 16);
    justify-content: space-between;

    @media screen and (min-width: 1300px) {
        height: calc(118rem / 16);
        padding-left: calc(56rem / 16);
        padding-right: calc(56rem / 16);
        background-color: #fff;
        justify-content: flex-start;
        flex-flow: row nowrap;
    }
}

.nav-open .navbar {
    @media screen and (max-width: 1299px) {
        background-color: var(--color-default);
    }
}


.navbar-parent.is-affix {
    position: fixed;
}

.navbar.navbar--show-on-scroll {
    transition: transform 200ms ease-out, background-color 0.4s ease;
}

.navbar.navbar--show-on-scroll.is-hidden {
    transform: translateY(-100%);
}

.navbar.navbar--show-on-scroll.navbar-white .navbar-brand {
    opacity: 1;
}

.navbar--static {
    position: relative;
    pointer-events: auto;
}

.navbar-white {
    @media screen and (max-width: 1299px) {
        background-color: #fff;
    }
}

.navbar-collapse {
    pointer-events: auto;

    @media screen and (max-width: 1299px) {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: calc(100rem / 16);
        background: var(--color-default);
        color: #fff;
        text-align: center;
        transform: translateX(100%);
        transition: transform 250ms ease;
    }
}

.navbar.navbar-expand-md .navbar-collapse.navbar-collapse {
    @media screen and (min-width: 768px) {
        display: none !important;
        padding: 0 calc(15rem / 16);
    }

    @media screen and (min-width: 1300px) {
        padding: 0 calc(25rem / 16);
    }
}

.nav-open .navbar.navbar-expand-md .navbar-collapse.navbar-collapse {
    @media screen and (min-width: 768px) {
        display: flex !important;
    }
}


.navbar-collapse__content {
    @media screen and (max-width: 1299px) {
        padding: calc(40rem / 16) 0;
        height: 100%;
        overflow-y: auto;
    }
}

.nav-open .navbar-collapse {
    @media screen and (max-width: 1299px) {
        transform: translateX(0);
    }
}

.navbar-brand {
    pointer-events: auto;
    margin-right: 0;

    @media screen and (max-width: 1299px) {
        margin-right: calc(15rem / 16);
    }
}

.is-sticky .navbar-brand {
    opacity: 0;
    transition: all 250ms ease;
    pointer-events: none;
}

.nav-open .is-sticky .navbar-brand {
    opacity: 1;
}

.navbar-brand > img:not(.navbar-brand__sign) {
    width: calc(120rem / 16);
    @media screen and (min-width: 768px) {
        width: calc(150rem / 16);
    }
    @media screen and (min-width: 992px) {
        width: calc(160rem / 16);
    }

    @media screen and (min-width: 1300px) {
        width: calc(200rem / 16);
    }
}

.navbar-brand__dark {
    display: none;

    @media screen and (min-width: 1300px) {
        display: block;
    }
}

.navbar-brand__sign {
    height: calc(23rem / 16);
    display: block;

    @media screen and (min-width: 1300px) {
        display: none;
    }
}

.navbar-white .navbar-brand__white.navbar-brand__sign {
    @media screen and (max-width: 1299px) {
        display: none;
    }
}

.nav-open .navbar-brand__white.navbar-brand__sign {
    @media screen and (max-width: 1299px) {
        display: block;
    }
}

.nav-open .navbar-brand__dark.navbar-brand__sign {
    @media screen and (max-width: 1299px) {
        display: none;
    }
}

.navbar-collapse {
    @media screen and (min-width: 1300px) {
        display: flex;
        flex-basis: auto;
    }
}

.navbar-nav {
    @media screen and (min-width: 1300px) {
        flex-direction: row;
    }
}

.navbar__right {
    color: var(--color-grey);
    text-transform: uppercase;
}

.is-sticky .navbar__right {
    opacity: 0;
    transition: all 250ms ease;
    pointer-events: none;
}

.nav-open .is-sticky .navbar__right {
    opacity: 1;
}

.navbar__right .nav-link {
    padding: calc(5rem / 16) 0;
    pointer-events: auto;

    @media screen and (min-width: 768px) and (max-width: 1400px) {
        font-size: calc(15rem / 16);
    }
}

.navbar__right .nav-link:hover {
    color: #fff;
}

.navbar__right > .nav-item:not(:last-child) {
    margin-right: calc(4rem / 16);

    @media screen and (min-width: 992px) {
        margin-right: calc(16rem / 16);
    }
}

.navbar__right .nav-item.nav-item-hide {
    @media screen and (max-width: 1299px) {
        display: none;
    }
}

.nav-open .navbar__right .nav-item.nav-item-hide {
    @media screen and (max-width: 1299px) {
        display: block;
    }
}

.nav-item__dealer > .nav-link {
    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: 1299px) {
        font-size: calc(18rem / 16);
        color: var(--color-grey);
        text-transform: uppercase;
    }
}

.nav-item__lang > .nav-link {
    display: inline-block;
    padding: calc(6rem / 16) calc(10rem / 16);

    @media screen and (max-width: 768px) {
        font-size: calc(24rem / 16);
        color: var(--color-grey);
        text-transform: uppercase;
    }
}

.nav-item__lang .nav-link.active {
    font-family: var(--font-bold);
    color: #fff;
}

.nav-item__btn {
    pointer-events: auto;
}

.nav-item__btn-hide {
    @media screen and (max-width: 1299px) {
        opacity: 0;
        transition: opacity 0.4s ease;
    }
}

.nav-open .nav-item__btn-hide {
    @media screen and (max-width: 1299px) {
        opacity: 1;
    }
}

.nav-item__nav-toggle-btn {
    @media screen and (min-width: 1300px) {
        display: none;
    }
}

.nav-item__btn .btn:not(.btn-no-style) {
    background-color: #fff;
    color: var(--color-darkgrey);
    cursor: pointer;
    border: 1px solid #fff;
    position: relative;
    transition: background-color 0.4s ease, border-color 0.4s ease, color 0.4s ease;
    padding: calc(18rem / 16) !important;

    @media screen and (max-width: 1299px) {
        width: calc(40rem / 16);
        height: calc(40rem / 16);
        padding: calc(15rem / 16) !important;
        background-color: var(--color-default);
        color: #fff;
        border-color: var(--color-default);
    }
}

.nav-open .nav-item__btn .btn {
    @media screen and (max-width: 1299px) {
        border: 1px solid var(--color-default);
    }
}

.nav-item__btn .btn .icon {
    @media screen and (max-width: 1299px) {
        font-size: calc(18rem / 16);
    }
}

.nav-item__btn .btn .nav-item__btn-text {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    font-family: var(--font-default);
    font-size: calc(10rem / 16);
    line-height: calc(12 / 10);
    white-space: break-spaces;
    text-transform: none;
    color: #fff;
    opacity: 0;
    transition: opacity 0.4s ease;

    @media screen and (min-width: 1300px) {
        color: var(--color-darkgrey);
        font-size: calc(14rem / 16);
        line-height: calc(14 / 14);
        opacity: 1;
        width: max-content;
    }
}

.nav-open .nav-item__btn .btn .nav-item__btn-text {
    opacity: 1;
}

.navbar-main {
    @media screen and (max-width: 1299px) {
        margin-top: calc(20rem / 16);
        margin-bottom: calc(30rem / 16);
    }
}

.navbar-main .nav-link {
    color: #fff;
    font-family: var(--font-black);
    letter-spacing: calc(5.25rem / 16);
    font-size: calc(14rem / 16);
    text-transform: uppercase;

    @media screen and (max-width: 1299px) {
        padding: calc(5rem / 16) 0;
        padding-right: calc(30rem / 16);
    }

    @media screen and (min-width: 1300px) {
        font-size: calc(16rem / 16);
    }
}

.navbar--light .nav-link {
    @media screen and (min-width: 768px) {
        color: var(--color-darkgrey);
    }
}

.navbar-nav .nav-link {
    @media screen and (min-width: 1300px) {
        padding: calc(15rem / 16) calc(10rem / 16);
    }
}

/*.navbar-main > .nav-item {*/
/*    @media screen and (max-width: 1299px) {*/
/*        position: relative;*/
/*    }*/
/*}*/

.navbar-main > .nav-item,
.navbar-main > .nav-item > .nav-link {
    @media screen and (min-width: 1300px) {
        display: flex;
        align-items: center;
        height: calc(116rem / 16);
    }
}

.navbar-main > .nav-item > .nav-link:focus {
    outline: none;
    box-shadow: none;
}

.navbar-main .nav-item:not(:last-child) {
    @media screen and (min-width: 768px) {
        margin-right: calc(8rem / 16);
    }

    @media screen and (min-width: 992px) {
        margin-right: calc(10rem / 16);
    }

    @media screen and (min-width: 1400px) {
        margin-right: calc(45rem / 16)
    }
}

.navbar-main--narrow .nav-item:not(:last-child) {
    @media screen and (min-width: 1400px) {
        margin-right: 1rem;
    }
}

.navbar .nav-link__text {
    color: var(--color-default);
    padding: calc(10rem / 16) calc(5rem / 16);
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 1299px) {
        color: #fff;
        font-size: calc(20rem / 16);
        letter-spacing: calc(7.5rem / 16);
        max-width: 72%;
        white-space: normal;
        display: inline-block;
        padding: 0;
        padding-bottom: calc(12rem / 16);
        margin-left: calc(30rem / 16);
    }
}


.navbar .nav-item .nav-link__text:after {

    @media screen and (min-width: 1300px) {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: calc(35rem / 16);
        height: calc(4rem / 16);
        background-color: var(--color-primary);
        transform: translateY(100%) translateX(-50%);
        transition: all 250ms ease;
    }

}

.navbar .nav-item > .nav-link.is-open .nav-link__text:after,
.navbar .nav-item:hover .nav-link__text:after {
    @media screen and (min-width: 1300px) {
        transform: translateY(0) translateX(-50%);
    }
}


.nav-link__toggle {
    @media screen and (max-width: 1299px) {
        font-size: calc(6rem / 16);
        transform: rotate(45deg);
    }
}

.navbar-main > .nav-item:not(:last-child) {
    @media screen and (max-width: 1299px) {
        margin-bottom: calc(22rem / 16);
    }
}


.main-nav-toggle {
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    background-color: var(--color-default);
    color: #fff;
    border: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 2;
    position: relative;
    cursor: pointer;
    pointer-events: auto;

    @media screen and (min-width: 1300px) {
        width: calc(60rem / 16);
        height: calc(60rem / 16);
    }
}

.main-nav-toggle.is-open {
    @media screen and (min-width: 1300px) {
        background-color: #fff;
    }
}

button.main-nav-toggle:focus {
    outline: none;
    box-shadow: none;
}

.main-nav-toggle__bar {
    display: block;
    width: calc(22rem / 16);
    height: calc(3rem / 16);
    background-color: #fff;
    transition: transform .25s ease-in-out, opacity .25s ease-in-out, background-color 0.4s ease;
    border-radius: calc(2rem / 16);

    @media screen and (min-width: 1300px) {
        width: calc(28rem / 16);
        height: calc(4rem / 16);
    }
}

.main-nav-toggle__bar + .main-nav-toggle__bar {
    margin-top: calc(4rem / 16);

    @media screen and (min-width: 1300px) {
        margin-top: calc(5rem / 16);
    }
}

.main-nav-toggle.is-open .main-nav-toggle__bar {
    @media screen and (min-width: 1300px) {
        background: var(--color-darkgrey);
    }
}

.main-nav-toggle.is-open .main-nav-toggle__bar:first-child {
    transform: rotate(-45deg) translateX(-5px) translateY(5px);
    width: calc(22rem / 16);

    @media screen and (min-width: 1300px) {
        width: calc(28rem / 16);
        transform: rotate(-45deg) translateX(-6px) translateY(6px);
    }
}

.main-nav-toggle.is-open .main-nav-toggle__bar:nth-child(2) {
    opacity: 0;
}

.main-nav-toggle.is-open .main-nav-toggle__bar:nth-child(3) {
    transform: rotate(45deg) translateX(-5px) translateY(-5px);
    width: calc(23rem / 16);

    @media screen and (min-width: 1300px) {
        width: calc(29rem / 16);
        transform: rotate(45deg) translateX(-7px) translateY(-6px);
    }
}


.navbar-main__subnav {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(110rem / 16);
    background-color: var(--color-default);
    padding: calc(25rem / 16) 0 calc(55rem / 16);
    display: block;
    transition: all .25s ease-in-out;
    z-index: 3;

    @media screen and (min-width: 1300px) {
        top: calc(116rem / 16);
        visibility: hidden;
        opacity: 0;
        background-color: var(--color-darkgrey);
        padding: calc(45rem / 16) 0 calc(55rem / 16);
        pointer-events: none;
    }

    @media screen and (max-width: 1299px) {
        top: 0;
        bottom: 0;
        position: fixed;
        transform: translateX(100%);
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
}

.navbar-main__subnav .row > div {
    @media screen and (max-width: 1299px) {
        position: static;        
    }
}

.nav-item:hover .navbar-main__subnav {
    z-index: 100;
}

.navbar-main__subnav-title {
    @media screen and (max-width: 1299px) {
        font-family: var(--font-black);
        text-transform: uppercase;
        color: #fff;
        font-size: calc(20rem / 16);
        letter-spacing: calc(8rem / 16);
        margin-bottom: calc(50rem / 16);
    }
}

.navbar-main .nav-item:hover .navbar-main__subnav,
.navbar-main .nav-item.is-open .navbar-main__subnav {
    @media screen and (min-width: 1300px) {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }
}

.nav-open .navbar-main__subnav.is-open {
    @media screen and (max-width: 1299px) {
        transform: translateX(0);
    }
}

@media screen and (max-width: 1299px) {
    .subnav-toggle {
        width: calc(30rem / 16);
        height: calc(30rem / 16);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        top: calc(5rem / 16);
        font-size: calc(7rem / 16);
    }
}

@media screen and (min-width: 1300px) {
    /* hover helper */
    .navbar .nav-item > .nav-link:before {
        content: '';
        position: absolute;
        top: calc(45rem / 16);
        bottom: calc(-15rem / 16);
        width: calc(75rem / 16);
        left: calc(-57rem / 16);
        display: none;
    }

    .navbar-nav .nav-item:hover > .nav-link:before {
        display: block;
    }

    .navbar .nav-item > .nav-link:after {
        content: '';
        position: absolute;
        top: calc(45rem / 16);
        bottom: calc(-15rem / 16);
        width: calc(75rem / 16);
        right: calc(-57rem / 16);
        display: none;
    }

    .navbar-nav .nav-item:hover > .nav-link:after {
        display: block;
    }
}


.navbar-main__subnav__list {
    list-style: none;
    padding: 0;
    @media screen and (min-width: 768px) {
        min-height: calc(400rem / 16);
    }
}

.navbar-main__subnav__list .nav-link {
    color: var(--color-grey);
    font-size: calc(16rem / 16);
    letter-spacing: calc(6rem / 16);
    display: inline-block;
    position: relative;
    z-index: 2;

    @media screen and (max-width: 1299px) {
        margin-left: 28px;
    }
}

.navbar-main__subnav__list .is-open > .nav-link {
    @media screen and (min-width: 768px) {
        color: #fff;
    }
}

@media screen and (min-width: 992px) {
    .navbar-main__subnav__list .nav-link:hover {
        color: #fff;
    }
}


.navbar-main__subnav__list > li {
    @media screen and (max-width: 1299px) {
        margin-bottom: calc(15rem / 16);
    }
}


.btn-close-subnav {
    margin-bottom: calc(10rem / 16);
    text-transform: none;
    color: var(--color-grey);
    font-size: calc(14rem / 16);
    font-family: var(--font-default);

    @media screen and (max-width: 1299px) {
        margin-left: calc(-10rem / 16);
    }
}

.btn-close-subnav .icon {
    font-size: calc(10rem / 16);
    margin-right: calc(5rem / 16);
}


.nav-link--button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: relative;
}

.nav-link--button:focus {
    outline: none;
    border: none;
}


.subnav-level {
    @media screen and (min-width: 1300px) {
        position: absolute;
        right: 0;
        top: 0;
        padding-left: 15px;
        padding-top: calc(10rem / 16);
        visibility: hidden;
        opacity: 0;
        overflow-y: auto;
        height: 100%;
        transform: translateX(100%);
        transition: all 150ms ease;
    }

    @media screen and (max-width: 1299px) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--color-default);
        color: #fff;
        transition: all .25s ease-in-out;
        z-index: 4;
        transform: translateX(100%);
        overflow-y: auto;
        padding: calc(25rem / 16) 0;
        height: 100%;
    }
}

.nav-item.is-open > .subnav-level {
    @media screen and (min-width: 768px) {
        visibility: visible;
        opacity: 1;
    }

    @media screen and (max-width: 1299px) {
        transform: translateX(0);
    }
}

.subnav-level__list {
    padding-left: 0;
    list-style: none;
}


.subnav-level__link {
    font-size: calc(16rem / 16);
    line-height: calc(17 / 16);
    letter-spacing: calc(3rem / 16);
    font-family: var(--font-black);
    color: var(--color-grey);
    padding: calc(15rem / 16) 0;
    display: inline-block;

    &:hover {
        color: #fff;
    }
}

.subnav-level__item:first-child .subnav-level__link {
    padding-top: 0;
}

.subnav-toggle {
    position: absolute;
    right: calc(-15rem / 16);
    top: calc(24rem / 16);
    transition: all 250ms ease;
}

.subnav-toggle--open.icon {
    font-size: calc(7rem / 16);
    transition: all 250ms ease;
    transform: rotate(45deg);
}

.subnav-toggle--close:after {
    @media screen and (min-width: 768px) {
        content: '';
        position: absolute;
        width: 31px;
        height: 2px;
        background-color: red;
        top: 1px;
        right: calc(-15rem / 16);
        transition: all 250ms ease;
        transform: scaleX(0);
        transform-origin: left;
    }

}

.nav-item.is-open .subnav-toggle--close:after {
    transform: scaleX(1);
}

.nav-item.is-open .subnav-toggle--open {
    @media screen and (min-width: 768px) {
        transform: scale(0) rotate(45deg);

    }
}

.navbar-search {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-default);
    color: #fff;
    z-index: 5555;
    transform: translateY(-100%);
    transition: all 350ms ease;
    height: calc(75rem / 16);

    @media screen and (min-width: 768px) {
        height: calc(158rem / 16);
    }
}

.navbar-search__form {
    width: 70%;
}


.open-search .navbar-search {
    transform: translateY(0);
}

.navbar-search .btn-close-search {
    pointer-events: auto;
}


.navbar-search__form .form-control {
    pointer-events: auto;

    height: calc(40rem / 16);
    font-size: calc(15rem / 16);
    padding: calc(10rem / 16) calc(15rem / 16);
    color: #fff;

    background-color: transparent;

    border-bottom: 1px solid #fff;

    transition: all 400ms cubic-bezier(.64, .09, .08, 1);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 96%, #fff 4%);
    background-position: calc(-1000rem / 16) 0;
    background-size: 100%;
    background-repeat: no-repeat;

    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
        padding: calc(10rem / 16) calc(25rem / 16);
        height: calc(80rem / 16);
    }

    @media screen and (max-width: 1299px) {
        margin-right: calc(10rem / 16);
    }

}

.navbar-search__form .form-control:focus {
    background-position: 0 0;
}

.navbar-search__form .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
}

.navbar-search__form .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
}

.navbar-search__form .form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff;
}

.navbar-search .navbar-brand > img {
    @media screen and (max-width: 1299px) {
        width: calc(100rem / 16);
    }
}

.navbar__addition {
    position: absolute;
    bottom: .25rem;
    left: 0;
    right: 0;
}

@media screen and (min-width: 768px) {
    .navbar--md-visible .navbar-collapse,
    .navbar.navbar-expand-md.navbar--md-visible .navbar-collapse.navbar-collapse {
        display: flex !important;
    }
}


.interactive-nav__group {
    position: relative;
}

.interactive-nav__item {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transition: visibility 250ms ease, opacity 250ms ease;
}

.interactive-nav__item.is-active {
    opacity: 1;
    visibility: visible;
    position: relative;
}

.interactive-nav__item-teaser {
    @media screen and (min-width: 768px) {
        padding-left: 20%;
    }

    @media screen and (max-width: 1299px) {
        margin-top: 30px;
    }
}

/* HELPER */
/* xxl for nav is 1300px NOT 1400px like Bootstrap Breakpoint */
.nav-d-xxl-none {
    @media screen and (min-width: 1300px) {
        display: none;
    }
}