.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
    font-family: var(--font-bold);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}

.wysiwyg--multicolumn-2{
    @media screen and (min-width: 768px){
        column-count: 2;
        column-gap: calc(30rem/16);
    }
}

.wysiwyg--with-border{
    padding: calc(20rem/16) 0;
    border-top: 2px solid var(--color-lightgrey);
    border-bottom: 2px solid var(--color-lightgrey);

    @media screen and (min-width: 768px){
        padding: calc(30rem/16) 0;
    }
}