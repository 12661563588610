.vertical-gutter--1 {
  margin-top: -.25rem;
}
.vertical-gutter--1 > .vertical-gutter__item {
  margin-top: .25rem;
}
.vertical-gutter--2 {
  margin-top: -.5rem;
}
.vertical-gutter--2 > .vertical-gutter__item {
  margin-top: .5rem;
}
.vertical-gutter--3 {
  margin-top: -1rem;
}
.vertical-gutter--3 > .vertical-gutter__item {
  margin-top: 1rem;
}
.vertical-gutter--4 {
  margin-top: -1.5rem;
}
.vertical-gutter--4 > .vertical-gutter__item {
  margin-top: 1.5rem;
}
.vertical-gutter--5 {
  margin-top: -3rem;
}
.vertical-gutter--5 > .vertical-gutter__item {
  margin-top: 3rem;
}



.vertical-gutter--100 {
  margin-top: calc(-100rem/16);
}
.vertical-gutter--100 > .vertical-gutter__item {
  margin-top: calc(100rem/16);
}

.vertical-gutter-md--100 {
    margin-top: -3.75rem;
    @media screen and (min-width: 768px){
        margin-top: calc(-100rem/16);
    }
}
.vertical-gutter-md--100 > .vertical-gutter__item {
    margin-top: 3.75rem;
    @media screen and (min-width: 768px){
        margin-top: calc(100rem/16);
    }
}


.vertical-gutter--30 {
    margin-top: calc(-18rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(-30rem/16);
    }
}
.vertical-gutter--30 > .vertical-gutter__item {
    margin-top: calc(18rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(30rem/16);
    }
}
