.configuration-area{
    background: linear-gradient(180deg, var(--color-grey-100) 0%, var(--color-lightgrey) 100%);
    position: relative;
    padding: calc(62rem/16) 0 calc(112rem/16) 0;
    z-index: 0;
    overflow: hidden;
    @media screen and (min-width: 1600px){
        padding: calc(62rem/16) 0;
    }
}
.configuration-area:after{
    @media screen and (min-width: 768px){
        content: '';
        position: absolute;
        top: 0;
        left: 32.3%;
        width: 32.3%;
        height: 100%;
        background: linear-gradient(0deg, var(--color-grey-100) 0%, var(--color-lightgrey) 100%);
        transform: skewX(-12deg);
        z-index: -1;
    }
}
.configuration-area__img-container{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: calc(335rem/16);
    z-index: -1;
    @media screen and (min-width: 768px){
        width: calc(550rem/16);
        height: 100%;
    }
    @media screen and (min-width: 1200px){
        width: calc(640rem/16);
    }
    @media screen and (min-width: 1600px){
        width: calc(900rem/16);
    }
}
.configuration-area__img-container:before{
    @media screen and (max-width: 767px){
        content: '';
        position: absolute;
        bottom: calc(-80rem/16);
        left: 0;
        background: var(--color-grey-100);
        z-index: 1;
        width: 100%;
        height: calc(140rem/16);
        transform: skewY(13deg);
    }
}
.configuration-area__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*Tabbing*/
.nav-tabs.configuration-area__nav{
    height: calc(81rem/16);
    @media screen and (max-width: 767px){
        margin-top: calc(210rem/16);
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
    }
}
.nav-tabs.configuration-area__nav .nav-item + .nav-item{
    margin-left: calc(30rem/16);
}
.nav-tabs.configuration-area__nav .nav-item > .configuration-area__nav-link{
    background: none;
    padding: calc(16rem/16) 0;
    font-size: calc(14rem/16);
    line-height: calc(17/14);
    letter-spacing: calc(3rem/16);
    color: var(--color-default);
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        line-height: calc(17/16);
        letter-spacing: calc(4rem/16);
    }
}
.nav-tabs.configuration-area__nav .nav-item > .configuration-area__nav-link:focus{
    outline: none;
}