.author{
  width: 100%;
  margin: 0 auto;
}

.author:not(.author--big){
  max-width: calc(300rem/16);
}

.author__img img{
  border-radius: 50%;
}

.author__name{
  font-size: calc(24rem/16);
  line-height: calc(26/24);
  margin-top: calc(20rem/16);
  margin-bottom: calc(10rem/16);
}


.author .link-with-icon{
  margin-top: calc(20rem/16);
}

.author-list>.row{
  border-bottom: 1px solid var(--color-lightgrey);
}

.author-list__row{
  align-items: center;
}

.author-list__row__item{
  padding: calc(23rem/16);
  display: block;
  
  @media screen and (max-width: 767px){
     font-size: calc(14rem/16);
    padding: calc(8rem/16) calc(2rem/16);
  }
}

.author-list__row__item img{
  @media screen and (min-width: 768px){
     margin-right: calc(8rem/16);
  }
  @media screen and (max-width: 767px){
     display: block;
     margin: 0 auto;
  }
}

/* author big */

.author--big .author__name{
  font-size: calc(30rem/16);
  letter-spacing: calc(15rem/16);
  text-transform: uppercase;
  margin-top: calc(30rem/16);
  margin-bottom: calc(40rem/16);

  @media screen and (min-width: 768px){
     font-size: calc(90rem/16);
    letter-spacing: calc(33.75rem/16);
    margin-top: calc(50rem/16);
    margin-bottom: calc(60rem/16);
  }
}

.author--big .author__text{
  @media screen and (min-width: 768px){
     width: 100%;
    max-width: calc(700rem/16);
    margin: 0 auto;
  }
}