.account-sidebar{
    position: fixed;
    left: 0;
    top: 0;
    width: 80vw;
    height: 100%;
    z-index: 9;
    background-color: var(--color-grey-100);
    transform: translateX(-100%);
    transition: transform 200ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (min-width: 768px){
        width: 50vw;
    }
    @media screen and (min-width: 992px){
        position: relative;
        transform: translateX(0);
        height: auto;
        width: auto;
    }
}
.account-sidebar.active{
    @media screen and (max-width: 991px){
        transform: translateX(0);
    }
}
.account-sidebar__dismiss{
    display: flex;
    justify-content: flex-end;
    margin: calc(32rem/16) calc(32rem/16) 0 calc(32rem/16);
}
.account-sidebar--light{
    background-color: var(--color-grey-100);
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    padding: calc(32rem / 16) calc(32rem / 16) 100%;
    @media screen and (min-width: 992px){
        padding: calc(22rem/16);
    }
    @media screen and (min-width: 1400px){
        padding: calc(62rem/16);
    }
}
.account-sidebar--dark{
    background-color: var(--color-darkgrey);
    padding: calc(32rem/16);
    @media screen and (min-width: 992px){
        padding: calc(62rem/16);
    }
    @media screen and (max-width: 767px){
        position: fixed;
        bottom: 0;
        width: 100%;
    }
}
.account-sidebar__item{
    color: var(--color-grey);
    font-size: calc(16rem/16);
    line-height: calc(20rem/16);
    text-transform: uppercase;
    letter-spacing: calc(6rem/16);
    font-family: var(--font-black);
    white-space: nowrap;
}
.account-sidebar__item + .account-sidebar__item{
    margin-top: calc(31rem/16);
}
.account-sidebar__item .icon{
    vertical-align: calc(-2rem/16);
}
.account-sidebar__item.active,
.account-sidebar__item:hover{
    color: var(--color-default);
}
.account-sidebar__item.active .icon,
.account-sidebar__item:hover .icon{
    color: var(--color-primary);
}
.account-sidebar__icon{
    width: calc(30rem/16);
}