#mainContent.dam-maincontent{
  @media screen and (max-width: 767px){
     padding: 0;
  }
}
.dam{
  position: relative;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.dam__login-box{
  margin: 0 15px;
}

.dam__login{
  width: 100%;
  max-width: calc(600rem/16);
  margin: 0 auto;

  padding: calc(20rem/16) calc(30rem/16) calc(30rem/16);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  background-color: #fff;
}

.dam__login__hl{
  font-size: calc(30rem/16);
  margin-bottom: calc(20rem/16);
  @media screen and (min-width: 768px){
     font-size: calc(60rem/16);
  }
}

.dam__nav{
  padding: calc(30rem/16) 0;

  @media screen and (min-width: 768px){
    padding: calc(60rem/16) 0;

  }
}