.simple-dealer-item{
    border-top: calc(1rem/16) solid var(--color-lightgrey);
}

.list-dealer li:last-child .simple-dealer-item{
    border-bottom: calc(1rem/16) solid var(--color-lightgrey);
}

.simple-dealer-item__icon{
    width: calc(60rem/16);
    height: calc(60rem/16);
    border-radius: 50%;
    background-color: var(--color-lightgrey);
    color: var(--color-default);
    font-size: calc(30rem/16);
}

.simple-dealer-item__title{
    letter-spacing: calc(3rem/16);
}

.simple-dealer-item__btn.link-with-icon{
    text-transform: none;
    font-size: calc(16rem/16);
    letter-spacing: calc(4rem/16);
}

.dealer-search.input-group .form-control,
.detail-search.input-group .form-control{
    border-bottom: 2px solid #111;
}

.form-section--dealer{
    position: relative;
}
.form-section--dealer .parsley-errors-list{
    bottom: calc(-30rem/16);
    background-color: var(--color-danger);
    color: #fff;
    padding: calc(7rem/16) calc(10rem/16);
}

.simple-dealer-item__input{
    opacity: 0;
    position: absolute;
    overflow: hidden;
    clip: rect(0,0,0,0);
}

.simple-dealer-item__label{
/*    width: calc(40rem/16);
    height: calc(40rem/16);
    border: 1px solid var(--color-default);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(16rem/16);
    font-family: var(--font-bold);
    transition: all 200ms ease;*/
    padding: calc(10rem/16);

    cursor: pointer;
}

.simple-dealer-item__input:checked ~ .simple-dealer-item__label,
.simple-dealer-item__label:hover{
    background-color: var(--color-grey-100);
    color: var(--color-default);
}


.simple-dealer-item__choosen{
    display: none;
    letter-spacing: calc(4rem/16);
    font-family: var(--font-bold);
    font-size: calc(16rem/16);
    text-align: center;
}

.simple-dealer-item__input:checked ~ .simple-dealer-item__label .simple-dealer-item__choosen{
    display: block;
}

.simple-dealer-item__input:checked ~ .simple-dealer-item__label .simple-dealer-item__btn.link-with-icon{
    display: none;
}

.simple-dealer-item__info{
    text-align: center;
    font-family: var(--font-bold);
    letter-spacing: calc(1rem/16);
    font-size: calc(16rem/16);
    margin: calc(10rem/16) 0;
    display: none;
}

.simple-dealer-item__input:checked ~ .simple-dealer-item__label .simple-dealer-item__info{
    display: block;
}
