.product-nav-teaser{
  background-color: #fff;
  padding: 0;
  color: var(--color-default);
  position: relative;
}

.product-nav-teaser + .product-nav-teaser{
  margin-top: calc(30rem/16);
}

.product-nav-teaser__img{
  text-align: center;
  margin-top: 15px;
}

.product-nav-teaser__img--border{
  padding: 0 calc(15rem/16);
}

.product-nav-teaser img{
  transition: all 450ms ease;

}
.product-nav-teaser:hover img{
  transform: scale(1.1);
}

.product-nav-teaser__hl{
  margin-bottom: calc(15rem/16);
  
  @media screen and (min-width: 768px) and (max-width: 991px){
     font-size: calc(22rem/16);
    margin-top: calc(2rem/16);
  }
}

.product-nav-teaser__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  background: linear-gradient(180deg,rgba(33,33,33,0),#ffffffa8);
  z-index: 1;
  opacity: 0;
  transition: opacity 250ms ease;
}

.product-nav-teaser:hover .product-nav-teaser__overlay {
  opacity: 1;
}

.product-nav-teaser__topline{
  line-height: 1.5;
  letter-spacing: calc(3.56rem/16);
  font-family: var(--font-black);
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 44px;
}