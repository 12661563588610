.product-hotspots-wrapper{
    position: relative;
}
.product-hotspots{
  position: relative;
  display: inline-block;
}


.hotspot{
/*  display: none;*/
  width: calc(24rem/16);
  height: calc(24rem/16);
  background-color: var(--color-primary);
  border-radius: 50%;
  position: absolute;
    cursor: pointer;
}

.hotspot__inner{
  display: block;
  width: calc(16rem/16);
  height: calc(16rem/16);
  background-color: var(--color-default);
  border-radius: 50%;
}
.hotspot.is-active .hotspot__inner{
    background-color: var(--color-primary);
}

.hotspot:not(.is-active):before{
  content: '';
  position: absolute;
  display: block;
  width: calc(100rem/16);
  height: calc(100rem/16);
  box-sizing: border-box;
  margin-left: calc(-38rem/16);
  margin-top: calc(-38rem/16);
  border-radius: 50%;
  background-color: var(--color-primary);
  animation: pulse-ring 1.3s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  z-index: 0;
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
    opacity: 0.6;
  }
   100% {
    opacity: 0;
  }
}


