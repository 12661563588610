.geometry-collapse-btn {
    width: 100%;
    margin-top: calc(16rem/16);

    @media screen and (min-width: 768px) {
        width: auto;
        margin-top: 0;
    }
}

.geometry-collapse-btn__show-text {
    display: none;
}

.geometry-collapse-btn.collapsed .geometry-collapse-btn__show-text {
    display: block;
}

.geometry-collapse-btn.collapsed .geometry-collapse-btn__hide-text {
    display: none;
}

.geometry-table td,
.geometry-table th{
  padding: 5px 10px;
}

.geometry__info{
  font-size: 14px;
  color: var(--color-grey);
  letter-spacing: 0.5px;
}