.img-teaser{
  position: relative;
  text-align: left;
    overflow: hidden;
}

.img-teaser:before{
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -1px;
  background: linear-gradient(180deg, rgba(33,33,33,0) 0%, #282829 100%);
  z-index: 1;
}

.img-teaser .img-teaser__img img{
  transition: all 450ms ease;
}

.img-teaser:hover .img-teaser__img img{
  transform: scale(1.15);
}


.img-teaser__body{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: calc(15rem/16) calc(10rem/16);
  z-index: 2;

  @media screen and (min-width: 768px){
    padding: calc(15rem/16) calc(30rem/16);

  }
}

.img-teaser--medium .img-teaser__body{
  @media screen and (min-width: 768px){
     padding: calc(65rem/16) calc(10rem/16);
  }
}

.img-teaser.img-teaser--big .img-teaser__body{
  padding: calc(15rem/16) calc(20rem/16);

  @media screen and (min-width: 768px){
     padding: calc(65rem/16) calc(75rem/16);
  }
}

.img-teaser__pl{
  font-size: calc(13rem/16);
  line-height: calc(24/16);
  letter-spacing: calc(3.56rem/16);
  text-shadow: 0 2px 22px rgba(0,0,0,0.85);
  font-family: var(--font-black);
  text-transform: uppercase;

  @media screen and (min-width: 992px){
    font-size: calc(16rem/16);
  }
}

.img-teaser__hl{
  font-size: calc(15rem/16);
  letter-spacing: calc(3.4rem/16);
  color: #fff;
  text-shadow: 4px 4px 10px rgba(0,0,0,0.58);

  @media screen and (min-width: 768px){
    font-size: calc(19rem/16);
    letter-spacing: calc(7rem/16);
  }

  @media screen and (min-width: 1200px){
    font-size: calc(22rem/16);
    letter-spacing: calc(8rem/16);
  }


  @media screen and (min-width: 1350px){
    font-size: calc(36rem/16);
    letter-spacing: calc(10rem/16);
  }
}

/* Article Img Teaser */

.img-teaser--article .img-teaser__hl{
  font-size: calc(16rem/16);
  letter-spacing: calc(6rem/16);
  line-height: calc(22/16);
  margin-bottom: 0;

  text-shadow: none;
}
.img-teaser--article.is-top-article .img-teaser__hl{
  position: relative;
  padding-left: calc(30rem/16);
}

.img-teaser--article .img-teaser__hl .icon{
  font-size: calc(20rem/16);
  position: absolute;
  top: 2px;
  left: 0;
}

.img-teaser.img-teaser--article:before{
  background: linear-gradient(0deg, rgba(0,0,0,0.38) 0%, rgba(0,0,0,0.12) 100%);
}

.img-teaser--article .img-teaser__body{
  padding: calc(25rem/16) calc(22rem/16);

  @media screen and (min-width: 768px){
     padding: calc(45rem/16) calc(28rem/16);
  }
}

.img-teaser--article .img-teaser__top{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: calc(15rem/16);
  color: #fff;
  z-index: 3;
  font-size: calc(15rem/16);
  
  @media screen and (min-width: 768px){
     padding: calc(25rem/16) calc(28rem/16);
  }
}

.img-teaser__category{
  font-family: var(--font-bold);
  margin-left: calc(41rem/16);
}

/* Img Teasergrid */

.img-teaser-grid .img-teaser .btn{
  @media screen and (max-width: 767px){
    padding: calc(10rem/16) calc(20rem/16);
    font-size: calc(13rem/16);
  }
}