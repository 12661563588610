.btn.birthday-button__icon {
    border: 0;
    padding: 5px;
    border-radius: 0;
}
.btn.birthday-button__icon:active{
    background: transparent;
}
.btn.birthday-button__icon:focus{
    box-shadow: none;
}
.btn.birthday-button__icon:hover {
    background-color: transparent;
    color: var(--color-primary);
}

label.birthday-button__label {
    font-size: calc(10rem/16);
    position: absolute;
    top: calc(-10rem/16);
}
.birthday-button__input {
    border-bottom: 2px solid black;
}