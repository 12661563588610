
.modal-title{
  letter-spacing: calc(6rem/16);
}

.modal-xl{
  @media screen and (min-width: 768px){
     max-width: calc(1100rem/16);
  }
}

.modal-xxl{

  @media screen and (min-width: 768px){
    max-width: 100%;
    height: calc(100% - 15px);
    margin: 15px;
  }

}


.modal-header .close {
  padding: calc(10rem/16) calc(20rem/16);
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  font-size: calc(38rem/16);
  color: var(--color-default);
  
  @media screen and (max-width: 767px){
    padding: calc(3rem/16) calc(10rem/16);
    font-size: calc(25rem/16);
  }
}

.modal--configurator .modal-content{
  @media screen and (min-width: 768px){
    height: calc(100%);
    margin: 0;
  }
}
.modal--configurator .modal-content{
    overflow-y: scroll;
}

.modal--configurator .modal-body{
  margin: 0 calc(10rem/16);
}

.modal--configurator .modal-header{
  display: block;
  padding: calc(16rem/16) calc(28rem/16);
  text-align: left;
}

@media screen and (min-width: 768px) and (max-height: 800px){
  .modal--configurator .modal-header{
    padding: calc(8rem/16) calc(28rem/16);
  }

  .modal--configurator .modal-title{
    font-size: calc(15rem/16);
  }

  .modal--configurator .modal-header .btn{
    padding: calc(5rem/16) calc(15rem/16);
    font-size: calc(11rem/16);
  }
  .modal--configurator .modal-header .btn .btn__icon{
    font-size: calc(10rem/16);
  }
}

.modal--configurator .modal-header .btn{
  float: right;
}

.modal--configurator .modal-title{
  display: inline-block;
  margin-top: calc(10rem/16);

}

@media screen and (min-width: 768px) and (max-height: 959px){
   .modal--configurator .modal-footer .btn{
     padding: calc(7rem/16) calc(25rem/16);
     font-size: calc(13rem/16);
   }
}

.configurator-iframe{
    min-height: calc(900rem/16);
}
@media screen and (min-width: 768px) and (max-width: 1199px){
  .configurator-iframe{
    min-height: calc(980rem/16);
  }
}


.configurator-iframe__loading {
    min-height: 50vh;
    padding-top: 20vh;
}

.modal-backdrop.modal-delivery-overview {
  background-color: rgba(0,0,0,0.8);
}
.modal-delivery-overview .btn {
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.modal--simple .modal-header{
  padding: calc(20rem/16) calc(40rem/16);
  border: none;
}

.modal--simple .modal-body{
  padding: calc(15rem/16) calc(30rem/16) calc(30rem/16) calc(15rem/16);

  @media screen and (min-width: 768px){
    padding: calc(20rem/16) calc(60rem/16) calc(60rem/16) calc(60rem/16);
  }
}


.modal--alt .modal-content {
  border:none;
  border-radius:calc(10rem/16);
}
.modal--alt .modal-header {
  border-bottom:none;
  padding:calc(60rem/16) calc(60rem/16) 0 calc(60rem/16);
}
.modal--alt .modal-title {
  letter-spacing:calc(4rem/16);
  font-size:calc(30rem/16);
}
.modal--alt .modal-body {
  padding:calc(30rem/16) calc(60rem/16) calc(60rem/16) calc(60rem/16);
}

.modal__divider {
    margin-top: calc(30rem/16);
    padding-top: calc(30rem/16);
    border-top: calc(2rem/16) solid var(--color-lightgrey);
}

.modal--content .modal-body{
    padding: calc(30rem/16) calc(30rem/16);

    @media screen and (min-width: 768px){
        padding: calc(60rem/16) calc(120rem/16);
    }
}

.btn.modal--content__close{
    font-size: calc(16rem/16);
    letter-spacing: 0;
}