
.dropdown.dropdown--primary .dropdown-menu{
  width: 100%;
  border-radius: 0;
  padding: 0;
}

.dropdown.dropdown--primary.dropdown--auto .dropdown-menu{
  width: auto;
}

.dropdown.dropdown--primary .dropdown-item{
  padding: calc(10rem/16) calc(15rem/16);
  text-align: center;
  font-family: var(--font-black);
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 0;
  box-shadow: none;
}

.dropdown.dropdown--primary .dropdown-item:not(:last-child){
  border-bottom: 1px solid var(--color-lightgrey);

}

.dropdown--cart{
  pointer-events: auto;
  
  @media screen and (max-width: 767px){
     position: static;
  }
}

.dropdown--cart__btn{
  background-color: var(--color-default);
  color: #fff;
  cursor: pointer;
  border: 1px solid var(--color-default);
}

.dropdown--cart__btn.has-cart-items .dropdown--cart__icon:before{
  content: var(--icon-shopping-cart-highlight);
}

.dropdown--cart.show .dropdown--cart__btn .icon{
  color: var(--color-primary);
}

.nav-open .dropdown--cart .btn{
  border-color: #fff;
}

.dropdown--cart .dropdown-toggle:after{
  display: none;
}

.dropdown--cart .dropdown-menu {
    width: calc(100% - 30px);
    background-color: var(--color-darkgrey);
    color: #fff;
    border-radius: 0;
    left: 0;
    right: 0;
    top: calc(100% - 8px);
    margin: 0 auto;
    padding: calc(55rem / 16) 0 calc(30rem / 16);

    @media screen and (min-width: 768px) {
        width: calc(360rem / 16);
        top: calc(100% + 28rem / 16);
        left: 50%;
        right: auto;
        transform: translateX(-78%);
        padding: calc(60rem / 16) 0;
    }

    @media screen and (max-width: 767px) {
        overflow-y: scroll;
    }

    @media screen and (max-height: 690px) and (min-width: 768px) {
        padding: calc(30rem / 16) 0;
    }
}


.dropdown--cart .dropdown-menu:before{
  content: '';
  position: absolute;
  top: calc(-10rem/16);
  width: 0;
  height: 0;
  border-left: calc(10rem/16) solid transparent;
  border-right: calc(10rem/16) solid transparent;
  border-bottom: calc(10rem/16) solid #282829;
  
  @media screen and (max-width: 767px){
     right: calc(70rem/16);
  }
  @media screen and (min-width: 768px){
    left: 75%;
    transform: translateX(0);
  }
}

.dropdown--cart .dropdown-item{
  color: var(--color-grey);
  font-size: calc(16rem/16);
  text-transform: uppercase;
  letter-spacing: calc(6rem/16);
  font-family: var(--font-black);
  padding: calc(12rem/16) calc(35rem/16);

  @media screen and (min-width: 768px){
    padding: calc(15rem/16) calc(60rem/16);
  }
}

.dropdown--cart .dropdown-item.disabled{
  cursor: not-allowed;
  pointer-events: none;
}

.dropdown--cart .dropdown-item:not(.disabled):hover{
  background-color: #3b3b3b;
  color: #fff;
}

.dropdown--cart .dropdown-item .icon{
  vertical-align: calc(-2rem/16);
  color: var(--color-primary);
}

.dropdown--cart .dropdown-item.disabled .icon{
  color: var(--color-grey);
}

.dropdown--cart .dropdown-item--no-products{
    letter-spacing: 2px;
    padding: 0.9375rem 3.75rem;
}

.dropdown--cart .dropdown-item--no-products:hover{
    background-color: transparent;
}


.dropdown--cart__item__title{
  font-family: var(--font-black);
  font-size: calc(18rem/16);
  letter-spacing: calc(2rem/16);
  text-transform: none;
}

.dropdown--cart__item{
  padding: calc(8rem/16) calc(30rem/16);
}

.dropdown--cart__cart li:hover{
  background-color: #3b3b3b;
}

/*
.dropdown--cart__cart li+li .row{
  border-top: 1px solid var(--color-grey);
}
*/

.dropdown--cart__cart__btn{
  padding: 0 calc(30rem/16);
  margin-top: calc(20rem/16);
  margin-bottom: calc(30rem/16);

    @media screen and (max-height: 690px) and (min-width: 768px) {
        margin-bottom: calc(10rem/16);
    }
}

.btn-close-dropdown-cart{
  color: var(--color-grey);
  position: absolute;
  right: calc(20rem/16);
  top: calc(20rem/16);
  z-index: 5;

  @media screen and (min-width: 768px){
     display: none;
  }
}

.dropdown--cart__item__timer{
  text-transform: none;
}

.dropdown--cart__list{
    max-height: calc(135rem/16);
    overflow-y: auto;
    margin-right: calc(30rem/16);

    @media screen and (min-width: 768px){
        max-height: calc(210rem/16);
    }

    @media screen and (max-height: 690px) and (min-width: 768px) {
        max-height: calc(120rem/16);
    }
}

.dropdown--cart__item-amount{
    text-transform: none;
    font-size: calc(14rem/16);
}