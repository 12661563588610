.page-heading__title{
  @media screen and (min-width: 768px){
    font-size: calc(50rem/16);
    letter-spacing: calc(12rem/16);
    line-height: calc(72/60);
  }

  @media screen and (min-width: 992px){
    font-size: calc(60rem/16);
    letter-spacing: calc(22rem/16);
  }
}

.page-heading--shrink .page-heading__title{
  letter-spacing: calc(5rem/16);

}

.page-heading__subtitle{
  font-family: var(--font-black);
  letter-spacing: calc(5rem/16);
}

.page-heading .wysiwyg{
  width: 100%;
  max-width: calc(690rem/16);
  margin: 0 auto;
}

.page-heading--small{
  padding-top: calc(20rem/16);
  padding-bottom: calc(35rem/16);
  position: relative;

  @media screen and (min-width: 768px){
    padding-top: calc(80rem/16);
    padding-bottom: calc(60rem/16);
  }

  @media screen and (min-width: 992px){
    padding-top: calc(100rem/16);
    padding-bottom: calc(80rem/16);
  }
}

.page-heading--lg{
    padding-top: calc(62rem/16);
    padding-bottom: calc(62rem/16);
    position: relative;
    @media screen and (min-width: 768px){
        padding-top: calc(260rem/16);
        padding-bottom: calc(62rem/16);
    }
}

.page-heading__checkmark{
    font-size: calc(35rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(87rem/16);
    }
}