.social-sharing-bar{
  text-align: right;
  z-index: 9;
  margin-left: 50px;
  position: absolute;
  right: calc(15rem/16);

  @media screen and (min-width: 992px){
    right: 0;
  }
}

.no-header-img .social-sharing-bar{
  top: 105px;
}

.social-sharing-bar__text{
  transform: rotate(90deg);

  font-family: var(--font-black);
  color: var(--color-grey);
  text-transform: uppercase;
  letter-spacing: calc(6rem/16);

  display: block;
  margin-top: calc(40rem/16);
  transform-origin: center bottom;
  margin-bottom: calc(45rem/16);
  font-size: calc(16rem/16);
}