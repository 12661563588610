.detail-anchor-nav{
    z-index: 9;
    background: #FFFFFF;
    box-shadow: none;
    @media screen and (max-width: 992px){
        overflow-x: scroll;
    }
}
.detail-anchor-nav.is-affix{
    box-shadow: 0 4px 17px -17px rgba(0,0,0,0.8);
    transition: transform 200ms ease-out;
    @media screen and (min-width: 768px) {
        box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.1);
    }
}
.detail-anchor-nav__list{
    width: max-content;
}
.detail-anchor-nav__content{
    padding: calc(10rem/16) calc(15rem/16) calc(10rem/16) calc(15rem/16);
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: 992px){
        padding: calc(10rem/16) calc(38rem/16) calc(10rem/16) calc(125rem/16);
    }
}
.detail-anchor-nav .anchor-nav__title.nav-link__text{
    font-size: calc(14rem/16);
    line-height: calc(17/14);
    letter-spacing: calc(1.75rem/16);
    font-family: var(--font-black);
}
.detail-anchor-nav__buttons{
    display: flex;
    align-items: center;
    gap: calc(38rem/16);
    @media screen and (max-width: 767px){
        visibility: hidden;
        display: none;
    }
}
.detail-anchor-nav__affix-button{
    display: none;
    visibility: hidden;
    @media screen and (max-width: 767px){
        display: flex;
        visibility: visible;
        align-items: center;
        justify-content: center;
        position: fixed;
        width: 100%;
        height: auto;
        bottom: 0;
        left: 0;
        z-index: 9;
        padding-bottom: calc(17rem/16);
    }
}

/*overlay*/
.detail-anchor-nav__overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 100;
    background: rgba(245,245,245,0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-in-out;
}
.detail-anchor-nav__overlay.is-open{
    opacity: 1;
    visibility: visible;
}
.detail-anchor-nav__overlay-content{
    max-width: calc(450rem/16);
    @media screen and (max-width: 767px){
        padding: calc(20rem/16);
    }
}
.detail-anchor-nav__overlay-divider{
    margin-top: calc(17rem/16);
    padding-top: calc(17rem/16);
    border-top: solid calc(2rem/16) var(--color-lightgrey);
}
.detail-anchor-nav__overlay-close{
    position: absolute;
    bottom: calc(17rem/16);
    @media screen and (min-width: 768px){
        top: calc(10rem/16);
        right: calc(135rem/16);
        bottom: auto;
        transform: translateX(0);
    }
}
body.has-overlay{
    overflow-y: hidden;
}