.event-result {
    position: relative;
}
.event-result.is-loading .loading-overlay {
    display: block !important;
}

.event-info{
    font-family: var(--font-bold);
    font-size: calc(21rem/16);
    
    @media screen and (min-width: 1200px){
        font-size: 1.5rem;
    }
}

.event-info__icon{
    color: var(--color-primary);
}

.event-info__icon.icon-event-tag {
    font-size: 1rem;
}

.event-info__icon.icon-event-marker {
    font-size: 1.25rem;
}
.event-teaser{
    height: 100%;
    background-color: var(--color-lightgrey);
}
.event-teaser,
.event-teaser-lg {
    transition: all 250ms ease;
}

.event-teaser-lg{
    position: relative;
}


@media screen and (min-width: 768px){
    .event-teaser-lg:hover,
    .event-teaser:hover {
        transform: translateY(calc(-10rem/16));
    }
}

.event-teaser-lg:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0.4;
    background: linear-gradient(323.42deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.89) 100%);
}

.event-teaser-lg__body{
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0.875rem;
    z-index: 1;
    background-color: transparent;
}

.event-teaser-lg__body .event-teaser__text,
.event-teaser-lg__body .event-teaser__text__headline{
    color: white;
}

.event-teaser__body{
    padding: 0.875rem;
    background-color: var(--color-lightgrey);
}

.event-teaser__text{
    font-family: var(--font-bold);
    font-size: 1rem;
}

.event-teaser__text--muted{
    color: grey;
}

.event-teaser__text__headline{
    letter-spacing: 3px;
}

.event-teaser__text__icon{
    font-family: iconfont;
    color: var(--color-primary);
    font-size: 0.75rem;
}

.event-teaser__text__icon.icon-event-marker {
    font-size: 1rem;
}

.event-teaser-lg__badge{
    position: absolute;
    z-index: 1;
    padding: 0.75rem;
    top: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    font-family: var(--font-bold);
    letter-spacing: 1px;
}

.event-teaser__badge__icon{
    font-family: iconfont;
    color: var(--color-primary);
    font-size:  0.875rem;
    margin-right: 0.5rem;

}

/* event detail slider */

.gallery-thumb-slider .slick-dots {
    position: absolute;
    right: -12.5%;
    bottom: 0;
}

.gallery-thumb-slider .slick-dots > li{
    margin: 0;
}

.gallery-thumb-slider .slick-dots button{
    background-color: lightgrey;
    width: 0.5rem;
    height: 0.5rem;
    border: none;
    margin: 0 0.125rem;
}

.gallery-thumb-slider--container{
    position: relative;
}

.gallery-thumb-slider__thumbs{
    position: absolute;
    height: auto;
    width: 45rem;
    bottom: 2%;
    right: 4%;
}

.gallery-thumb-slider__thumbs .slick-slide{
    padding-right: 1rem;
}

.gallery-thumb-slider__thumbs .slick-slide .embed-responsive{
    border: 1px solid white;
}

@media screen and (max-width: 1199px){
    .gallery-thumb-slider__thumbs{
        display: none;
    }
    .gallery-thumb-slider .slick-dots {
        position: relative;
        right: auto;
    }
}

/* contact */

.event-contact__list > li {
    display: flex;
    flex-direction: row;
}
.event-contact__icon--container{
    width: 3rem;
    position: relative;
}

.event-contact__img {
    border-radius: 100%;
}

.event-contact__hl {
    font-family: var(--font-bold);
    text-transform: none;
    font-size: 2rem;
}

.event-contact__text {
    font-family: var(--font-default);
    font-size: 1.5rem;
    position: relative;
}

.event-contact__text__icon{
    font-family: iconfont;
    width: 2rem;
    font-size: 1rem;
    color: var(--color-primary);
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.event-contact__text__icon.icon-event-institution {
    font-size: 1.25rem;
}

.event-contact__text__icon.icon-event-website {
    font-size: 1.25rem;
}
.event-contact__text__icon.icon-event-phone {
    font-size: 1.5rem;
}
.event-contact__text__icon.icon-event-mail {
}

@media screen and (max-width: 767px){

    .event-contact__list > li {
        display: block
    }

    .event-contact__text {
        font-size: 1rem;
    }

    .event-contact__img {
        max-width: 150px;
    }

    .event-contact__hl  {
        font-size: 1.5rem;
        margin-top: 1rem;
    }
}
