.navbar.navbar.navbar--product>.container {
    @media screen and (min-width: 768px) and (max-width: 1399px) {
        margin-right: calc(100rem / 16);
    }
    @media screen and (min-width: 1400px) and (max-width: 1860px) {
        margin-right: calc(220rem / 16);
    }
}

.product-main.slide {
    position: relative;
    padding: calc(90rem/16) 0 calc(25rem/16);

    @media screen and (min-width: 1200px){
        padding-top: calc(125rem/16);
    }
    @media screen and (max-width: 767px){
        padding: calc(65rem/16) 0 calc(25rem/16);
    }
}

.product-main:after{
    content: '';
    position: absolute;
    width: 105%;
    right: -40%;
    top: 0;
    bottom: 0;
    background: linear-gradient(0deg, #e0e7e3 0%, #eff3f1 100%);
    z-index: -1;
    transform: skewX(-10deg);
}

.product-main:before{
    content: '';
    position: absolute;
    width: 95%;
    left: -60%;
    bottom: 0;
    top: 0;
    z-index: -1;
    background: linear-gradient(180deg, #e0e7e3 0%, #eff3f1 100%);
    transform: skewX(-10deg);
    @media screen and (min-width: 768px){
        width: 43%;
        left: -8%;
    }
}

.product-main.product-main--b2b{
    padding-top: calc(40rem/16);
}

.product-main.product-main--b2c-bike {
    background: radial-gradient(circle, #FFFFFF 0%, var(--color-lightgrey) 100%);
}

.product-main__body {
    @media screen and (min-width: 768px) {
        padding: 0 5%;
    }

    @media screen and (min-width: 1400px) {
        padding: 0 15%;
    }
}

.product-main__hl {
    letter-spacing: calc(3rem / 16);

    @media screen and (max-width: 767px) {
        margin-bottom: calc(30rem / 16);
        letter-spacing: calc(7rem / 16);
    }
}

.product-main__text {
    @media screen and (min-width: 768px) {
        margin-top: calc(30rem / 16);
    }

    @media screen and (min-width: 992px) {
        margin-top: calc(30rem / 16);
    }
}


.product-main__features {
    margin-top: calc(20rem / 16);
    font-size: calc(25rem / 16);
    @media screen and (min-width: 768px) {
        margin-top: calc(30rem / 16);
        font-size: calc(30rem / 16);
    }

    @media screen and (max-width: 767px) {
        flex-wrap: nowrap;
    }
}

.product-main__features .icon {
    font-size: calc(30rem / 16);
}

.product-main__features .list-inline-item:not(:last-child) {
    @media screen and (max-width: 767px) {
        margin-right: calc(15rem / 16);
    }
}

.product-main__color {
    font-size: 0;
    transform: rotate(30deg);
    position: relative;
}
.product-main__color:before{
    content: '';
    top: 50%;
    left: 50%;
    width: 130%;
    height: 130%;
    position: absolute;
    border-radius: 50%;
    border: 1px solid var(--color-grey);
    transform: translate(-51%, -51%);
    opacity: 0;
}

.product-main__color.no-border{
    width: fit-content;
}


.product-main__color.active:before,
.product-main__color:hover:before {
    opacity: 1;
}

.product-main__color__left {
    border-bottom-left-radius: 90px;
    border-top-left-radius: 90px;
    background-color: #000;
    width: calc(17rem / 16);
    height: calc(35rem / 16);
    display: inline-block;
}
.product-main__color--sm .product-main__color__left{
    width: calc(15rem/16);
    height: calc(30rem/16);
}

.product-main__color__right {
    border-bottom-right-radius: 90px;
    border-top-right-radius: 90px;
    background-color: #000;
    width: calc(17rem / 16);
    height: calc(35rem / 16);
    display: inline-block;
}
.product-main__color--sm .product-main__color__right{
    width: calc(15rem/16);
    height: calc(30rem/16);
}

.product-main__color__one {
    width: calc(35rem / 16);
    height: calc(35rem / 16);
    border-radius: 50%;
    background-color: #000;
    display: inline-block;
}


.product-main__facts {
    font-family: var(--font-black);
    font-size: calc(16rem / 16);
    text-transform: uppercase;
    letter-spacing: calc(0.5rem / 16);

    margin-top: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(25rem / 16);
    }

    @media screen and (max-width: 767px) {
        margin-bottom: calc(8rem / 16);
    }
}

.product-main__fact-point {
    display: inline-block;
    width: calc(12rem / 16);
    height: calc(12rem / 16);
    border-radius: 50%;
    border: 1px solid var(--color-default);
}

.product-main__fact-point.filled {
    background-color: var(--color-default);
}

.product-main__fact-point:not(:last-child) {
    margin-right: calc(1rem / 16);

    @media screen and (min-width: 1200px) {
        margin-right: calc(5rem / 16);
    }
}

.product-main:not(.product-main--b2c) .product-main__img {
    position: relative;
    padding-bottom: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        padding-bottom: calc(50rem / 16);
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        margin-bottom: calc(40rem / 16);
    }
}

.product-main:not(.product-main--b2c) .product-main__img:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 0;
    background-image: url(/static/img/shadow.png);
    background-size: contain;
    height: calc(80rem / 16);
    background-repeat: no-repeat;
    width: 100%;
    transform: translateX(-50%);
    opacity: 0.25;
    background-position: bottom;

    @media screen and (min-width: 768px) {
        opacity: 0.65;
    }
}

.product-main__items-wrapper {
    @media screen and (min-width: 768px) {
        background-color: #fff;

        width: calc(100rem / 16);
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;

        padding: calc(30rem / 16) 0;
        /*    display: flex;
            align-items: center;*/
    }

    @media screen and (min-width: 1400px) {
        width: calc(220rem / 16);
    }
}

.product-main--b2c-merchandise .product-main__items-wrapper {
    @media screen and (min-width: 768px){
       bottom: auto;
    }
}

.product-main__items {
    display: block;
    position: relative;

    @media screen and (min-width: 768px) {
        height: 100%;
        display: flex;
        align-items: center;
        margin-top: calc(20rem / 16);
    }

    @media screen and (max-width: 767px) {
        margin-top: calc(30rem / 16);
    }
}

.product-main--b2c-merchandise .product-main__items {
    @media screen and (max-width: 767px) {
        margin: calc(10rem / 16) 0;
    }
}

/*.product-main__items .slick-list{
  @media screen and (min-width: 768px){
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}*/

.product-main__items .slick-track {
    @media screen and (min-width: 768px) {
        width: calc(100rem / 16);
    }
    @media screen and (min-width: 1400px) {
        width: calc(220rem / 16);
    }
}

.product-main__items .slick-dots {
    display: none !important;
}

/*.product-main__items .slick-arrow.slick-disabled{
  opacity: 0.3;
}*/

@media screen and (max-width: 767px) {
    .product-main__items-wrapper {
        display: block;
        margin-bottom: calc(40rem / 16);
    }

    .product-main--b2c-merchandise .product-main__items-wrapper {
        margin-bottom: calc(20rem / 16);
    }

    .product-main__items .btn.slick-arrow {
        padding: 0 calc(7rem / 16);
        color: var(--color-default);
        transform: none;
    }
}


@media screen and (min-width: 768px) {
    .product-main__items .slick-arrow.slick-prev {
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
        top: calc(-42rem / 16);
        font-size: calc(28rem / 16);
        color: var(--color-default);
    }

    .product-main__items .slick-arrow.slick-next {
        right: 50%;
        transform: translateX(50%) rotate(90deg);
        font-size: calc(28rem / 16);
        top: auto;
        bottom: calc(-38rem / 16);
        color: var(--color-default);
    }
}


.product-main__items__img {
    text-align: center;
    cursor: pointer;
    transition: all 250ms ease;
    position: relative;
    opacity: 0.4;

    @media screen and (min-width: 768px) {
        padding: 12px 0;
    }

    @media screen and (min-width: 992px) {
        padding: 20px 0;
    }

}

.product-main__items__img img {
    display: inline-block;
}

.product-main__items__img:hover,
.product-main__items .slick-current .product-main__items__img {
    opacity: 1;

}

.product-main__items .product-main__items__img:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(5rem / 16);
    background-color: var(--color-primary);
    transform: translateX(-101%);
    transition: all 250ms ease;

    @media screen and (min-width: 992px) {
        width: calc(10rem / 16);
    }
}

@media screen and (min-width: 768px) {
    .product-main__items .slick-current .product-main__items__img:before,
    .product-main__items .product-main__items__img:hover:before {
        transform: translateX(0);
    }
}

.product-main__items.slick-vertical .slick-slide {
    border: none;
}

.product-main__items .slick-slide {
    @media screen and (max-width: 767px) {
        margin: 0 7px;

    }
}


/* b2c */

.product-main--b2c-merchandise .product-main__body {
    @media screen and (min-width: 1400px) {
        padding-top: calc(70rem / 16);
    }

    @media screen and (min-width: 768px) {
        padding-top: calc(40rem / 16);
    }
}
.product-main--b2c-merchandise .product-main__back-link{
    position: relative;
    z-index: 11;
}

.product-main--b2c .product-main__hl {
    word-break: break-word;
    line-height: 1.2;
    font-size: calc(25rem / 16);
    letter-spacing: calc(1rem / 16);

    @media screen and (max-width: 767px) {
        margin-bottom: 0;
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(30rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(40rem / 16);
        letter-spacing: calc(3rem / 16);
    }
}

.product-main--b2c .product-main__body {
    @media screen and (min-width: 1400px) {
        padding: 0 calc(20rem / 16) 0 10%;
    }

    @media screen and (min-width: 768px) {
        padding: 0 calc(20rem / 16) 0 5%;
    }
}

.product-main--b2c-merchandise .product-main__img {
    @media screen and (min-width: 768px) {
        max-width: calc(632rem / 16);
    }
}

.product-main__img-picture{
    @media screen and (min-width: 1900px) {
        object-fit: none;
    }
}

.product-main__back-link{
    font-size: calc(15rem/16);
    font-family: var(--font-black);
    letter-spacing: calc(1rem/16);
    margin-bottom: calc(2rem/16);
    display: inline-block;
    transition: color 150ms ease;
}

.product-main__back-link:hover{
    color: var(--color-primary);
}

.product-main__back-link .icon{
    transform: scaleX(-1);
    font-size: calc(7rem/16);
    vertical-align: calc(2rem/16);
    margin-right: calc(7rem/16);
}

.product-main__category {
    font-size: calc(18rem / 16);
    letter-spacing: calc(5rem / 16);
    color: var(--color-grey);
    margin-top: calc(10rem / 16);
    font-family: var(--font-black);
    text-transform: uppercase;
}

.product-main__infos {
    margin-top: calc(15rem / 16);
    line-height: calc(31 / 18);
}

.product-main .btn-modal-link {
    text-transform: none;
    font-size: calc(16rem / 16);
}

.product-main .product-main__sizes .btn-modal-link{
    font-size: calc(15rem/16);
    margin-top: calc(1rem/16);
}

.product-main__pricing {
    margin: calc(10rem / 16) 0 calc(20rem / 16);
}

.product-main__price {
    font-size: calc(35rem / 16);
    letter-spacing: calc(7rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(45rem / 16);
    }

    @media screen and (min-width: 992px) {
        font-size: calc(50rem / 16);
    }
}

.product-main__old-price {
    font-size: calc(24rem / 16);
    letter-spacing: calc(2.67rem / 16);
}

.product-main__delivery-cost {
    font-size: calc(16rem / 16);
}

.product-main__delivery-cost .btn-tooltip {
    vertical-align: calc(1rem / 16);
}

.product-main__amount {
    width: calc(120rem / 16);
}

.product-main__amount .form-control {
    text-align: center;
    font-family: var(--font-black);
    font-size: calc(18rem / 16);
}

.delivery-status {
    font-size: calc(16rem / 16);
}

.delivery-status__circle {
    width: calc(16rem / 16);
    height: calc(16rem / 16);
    border-radius: 50%;
    display: inline-block;
    background-color: #000;
    vertical-align: calc(-2rem / 16);
    margin-right: calc(5rem / 16);
}

.delivery-status__circle.is-available {
    background-color: #7ED321;
}

.delivery-status__circle.not-available {
    background-color: #c73631;
}

.product-main__description-text {
    margin-top: calc(30rem / 16);
    line-height: 1.5;

    @media screen and (min-width: 768px) {
        margin-top: calc(30rem / 16);
    }
}

.product-main__sizes ul > .list-inline-item:not(:last-child) {
    margin-right: calc(4rem / 16);
}

.product-size {
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    border: 1px solid var(--color-default);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(16rem / 16);
    font-family: var(--font-bold);
    transition: all 200ms ease;
    cursor: pointer;
}


.product-size.is-active,
.product-size:not(.disabled):hover {
    background-color: var(--color-default);
    color: #fff;
}

.product-size.disabled{
    opacity: 0.3;
    cursor: initial;
    pointer-events: none;
}

/* b2c bike */

.product-main--b2c-bike {
    @media screen and (min-width: 768px) {
        padding-top: calc(120rem / 16);
    }

    @media screen and (min-width: 992px) {
        padding-top: calc(170rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding-top: calc(170rem / 16);
    }
}

.product-main__content {
    line-height: calc(31 / 18);
    margin-top: calc(30rem / 16);

    @media screen and (min-width: 992px) {
        margin-top: calc(70rem / 16);
    }
}

.product-main__content__hl {
    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
    }
}

.gender-color-select-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.gender-color-select__title {
    font-size: calc(16rem / 16);
    text-transform: uppercase;
    font-family: var(--font-black);
    letter-spacing: calc(4rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(19rem / 16);
    }
}

.gender-color-select:not(:last-child) {
    margin-right: calc(30rem / 16);
}

.gender-color-select .icon {
    font-size: calc(23rem / 16);
    margin-right: calc(12rem / 16);
}

.product-main__additional-logo {

    position: absolute;
    top: calc(75rem / 16);
    z-index: 1;

    @media screen and (min-width: 768px) {
        top: calc(130rem / 16);
        width: 100%;
    }

    @media screen and (min-width: 768px) and (max-width: 1200px) {
        padding-left: calc(20rem / 16);
    }
}

.product-main__additional-logo .container {
    @media screen and (min-width: 768px) and (max-width: 1399px) {
        margin-right: calc(100rem / 16);
    }
    @media screen and (min-width: 1400px) and (max-width: 1860px) {
        margin-right: calc(220rem / 16);
    }
}

.edition-bike__size{
    border-radius: calc(16rem/16);
    background-color: #000000;
    color: #FFFFFF;
    font-family: var(--font-bold);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(4rem/16) calc(8rem/16);
}
.edition-bike__color{
    min-height: calc(30rem/16);
}
.expandable__content.edition-bike__expandable{
    max-height: calc(75rem/16);
}
.edition-bike__price-label{
    line-height: calc(30rem/16);
}