.overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: calc(590rem/16);
  padding: calc(50rem/16) calc(140rem/16) calc(50rem/16) calc(120rem/16);
  transform: translateX(-100%);
  transition: all 250ms ease;
  overflow-y: auto;
    z-index: 5;

  @media screen and (max-width: 767px){
     position: fixed;
    z-index: 10;
    width: 100%;
    padding: calc(50rem/16) calc(30rem/16);
  }
}

.overlay.is-open{
  transform: translateX(0);
}

.overlay__content{
  text-align: left;
}

.overlay__hl{
  letter-spacing: calc(11.25rem/16);
  margin-top: calc(20rem/16);
  margin-bottom: calc(30rem/16);
  @media screen and (min-width: 768px){
     font-size: calc(35rem/16);
  }
}
.overlay__bottom>.btn+.btn{
  margin-top: calc(20rem/16);
}

.overlay .btn-close{
  position: absolute;
  top: calc(10rem/16);
  font-size: 20px;
  
  @media screen and (min-width: 768px){
    left: calc(50rem/16);
  }
  
  @media screen and (max-width: 767px){
    right: calc(30rem/16);
  }
}

.overlay .btn.btn-close .icon{
  font-size: calc(18rem/16);
}

.overlay-click-item{
  text-transform: uppercase;
  font-size: calc(20rem/16);
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  text-align: left;
    font-family: var(--font-default);
    white-space: normal;
    cursor: pointer;
  
  @media screen and (max-width: 767px){
     text-align: left;
    font-size: calc(16rem/16);
      line-height: 1.4;
  }
}

a.overlay-click-item:hover .icon,
a.overlay-click-item:hover .icon-tech{
  color: var(--color-primary);
}

.overlay-click-item__text{
  position: relative;
  width: 100%;
}

.overlay-click-item__info{
    font-size: 12px;
    text-transform: uppercase;
    font-family: var(--font-black);
    display: block;
    white-space: nowrap;
   /* position: absolute;
    opacity: 0;
    left: 47px;
    bottom: -15px;
    transform: scale(0);
    transform-origin: left;
    transition: all 200ms ease;
    transition-delay: 100ms;*/
}

@media screen and (min-width: 992px){
  .overlay-click-item__text:after {
    content: '';
    position: absolute;
    width: 40px;
    height: 4px;
    background: var(--color-primary);
    transform-origin: left;
    transition: all 200ms ease;
    transform: scale(0);
    bottom: -10px;
    left: 0;
  }

  .overlay-click-item:hover .overlay-click-item__text:after {
    transform: scale(1);
  }

  .overlay-click-item__info{
    font-size: 12px;
    text-transform: uppercase;
    color: var(--color-primary);
    font-family: var(--font-black);
    position: absolute;
    opacity: 0;
    left: 47px;
    bottom: -15px;
    transform: scale(0);
    transform-origin: left;
    transition: all 200ms ease;
    transition-delay: 100ms;
  }
  
  .overlay-click-item:hover .overlay-click-item__info {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 991px){
   .overlay-click-item__text .icon{
     bottom: calc(-10rem/16);
     font-size: calc(7rem/16);
     display: block;
     color: var(--color-primary);
     position: absolute;
   }
}


.overlay-click-item .icon,
.overlay-click-item .icon-tech{
  font-size: calc(32rem/16);
  margin-right: calc(10rem/16);
  transition: all 250ms ease;

 @media screen and (min-width: 768px){
   font-size: calc(45rem/16);
   margin-right: calc(15rem/16);
 }
}

.overlay::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}
.overlay::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: #a3a3a3;
}
.overlay::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 15px;
}

.overlay__title{
    letter-spacing: calc(2rem/16);
}

.overlay--grey{
    background-color: var(--color-grey-100);
    
    @media screen and (min-width: 768px){
        padding: calc(50rem/16) calc(90rem/16) calc(50rem/16) calc(120rem/16);
    }
}

.overlay.overlay--grey .btn-close{
    position: relative;
    left: auto;
    top: auto;
    background-color: #fff;
    border: 1px solid var(--color-default);
    color: var(--color-default);

    @media screen and (min-width: 768px){
        margin-right: calc(20rem/16);
    }

    @media screen and (max-width: 767px){
       margin-top: calc(-25rem/16);
        right: auto;
    }
}

.overlay-click-item--no-content{
    cursor: auto;
}