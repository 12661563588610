.information-feature{
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(50rem/16);
    }
}

.information-feature__show{
    display: none;
}

.collapsed .information-feature__show{
    display: block;
}

.collapsed .information-feature__hide{
    display: none;
}

.information-feature__collapse{
    @media screen and (max-width: 767px){
        padding: 0;
       border: none;
        text-decoration: underline;
    }
}

.btn.information-feature__collapse:active,
.btn.information-feature__collapse:focus,
.btn.information-feature__collapse:hover,
.btn-default.information-feature__collapse:not(:disabled):not(.disabled):active{
    @media screen and (max-width: 767px){
        background-color: transparent;
        color: var(--color-default);
        outline: none;
        border: none;
    }
}