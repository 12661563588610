.lightbox-img{
  position: relative;
  overflow: hidden;
}

.btn.btn-lightbox{
  position: absolute;
  right: calc(-18rem/16);
  bottom: calc(-18rem/16);
  width: calc(60rem/16);
  height: calc(60rem/16);
  border: 2px solid #fff;
  border-radius: 50%;
  content: '';
  opacity: 0;
  transform: scale3d(0.65,0.65,1);
  transform-origin: bottom right;
  transition: opacity 0.35s, transform 0.35s;
  display: block;
  padding: 0;
  color: #fff;
  pointer-events: none;
}
.btn.btn-lightbox--dark{
    color: #000000;
    border-color: #000000;
}

.btn-lightbox .icon{
  position: relative;
  top: calc(-3rem/16);
  left: calc(-3rem/16);
  font-size: calc(9rem/16);
}

.btn.btn-lightbox-play{
    color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    background: rgba(0,0,0,0.3);
    width: calc(50rem/16);
    height: calc(50rem/16);
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 200ms ease-in-out;
}
.lightbox-img:hover .btn.btn-lightbox-play{
    background: rgba(0,0,0,0.6);
}

.lightbox-img:hover .btn.btn-lightbox {
  opacity: 1;
  transform: scale3d(1,1,1);
}

.lg-toolbar .lg-icon{
    background: transparent;
    border: none;
    
    @media screen and (min-width: 768px){
        font-size: 41px;
        width: 60px;
        height: 57px;
    }
}

.lg-toolbar,
.lg-sub-html{
    background-color: rgba(0,0,0,1);
}

.lg-hide-items .lg-toolbar{
    opacity: 1;
}

.lg-outer .lg-object.lg-image {
    max-height: 88%;
}