.img-grid__container{
    max-width: calc(1680rem/16); /* 1650px breite + 30px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
    @media screen and (min-width: 768px){
        padding-left: calc(15rem/16);
        padding-right: calc(15rem/16);
    }
}
.img-grid__row-one{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-auto-rows: 1fr;
    grid-auto-flow: row;
    grid-gap: calc(20rem/16);
    grid-template-areas:
        "imgTwo imgTwo"
        "imgOne imgThree";
    @media screen and (min-width: 768px){
        grid-template-columns: 1fr 1.53fr 1fr;
        grid-gap: calc(31rem/16);
        grid-template-areas:
        "imgOne imgTwo imgThree";
    }
}
.img-grid__row-two{
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    grid-gap: calc(20rem/16);
    margin-top: 0;
    grid-template-areas:
        "imgFive"
        "imgFour";
    @media screen and (min-width: 768px){
        grid-template-rows: auto auto;
        grid-gap: calc(31rem/16);
        margin-top: 0;
        grid-template-columns: 1.34fr 1fr;
        grid-template-areas:
        "imgFour imgFive";
    }
}
.img-grid__img-one{
    grid-area: imgOne;
}
.img-grid__img-two{
    grid-area: imgTwo;
}
.img-grid__img-three{
    grid-area: imgThree;
}
.img-grid__img-four{
    grid-area: imgFour;
}
.img-grid__img-five{
    grid-area: imgFive;
}

.img-grid__row-three{
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: calc(20rem/16);
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px){
        grid-gap: calc(31rem/16);
        margin-top: 0;
    }
}

.img-grid__row-one + .img-grid__row-two{
    @media screen and (max-width: 767px){
       margin-top: calc(20rem/16);
    }
}

.img-grid__title-container{
    background-color: rgba(17,17,17,0.8);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: calc(21rem/16) calc(30rem/16);
    display: flex;
    align-items: center;
    @media screen and (min-width: 768px){
        padding: calc(40rem/16) calc(45rem/16);
    }

    @media screen and (min-width: 1200px){
        padding: calc(40rem/16) calc(90rem/16);

    }
}
.img-grid__title{
    font-size: calc(30rem/16);
    color: #FFFFFF;
    letter-spacing: calc(3.33rem/16);
    line-height: calc(36rem/16);
    font-family: var(--font-black);
    text-transform: uppercase;
    @media screen and (min-width: 768px){
        font-size: calc(35rem/16);
        letter-spacing: calc(6.67rem/16);
        line-height: calc(72/60);
    }

    @media screen and (min-width: 1200px){
        font-size: calc(60rem/16);
    }
}