.tabbing-slide__hl{
  letter-spacing: calc(11.25rem/16);
  margin-top: calc(10rem/16);
  margin-bottom: calc(60rem/16);

  @media screen and (min-width: 768px){
     font-size: calc(35rem/16);
  }
}

.tabbing-overlay-slide{
  padding: calc(35rem/16) 0 calc(50rem/16);
  position: relative;
  overflow: hidden;
  
  @media screen and (min-width: 768px){
/*     min-height: 75vh;*/
    padding: calc(35rem/16) 0 calc(50rem/16);

  }
}

.tabbing-slide__wysiwyg{
  margin-top: calc(-30rem/16);
}
