.female-badge {
    font-size: calc(70rem /16);
    position: absolute;
    z-index: 1;
    top: 0;
    transform: translateY(-50%);
    @media only screen and (min-width: 768px) {
        font-size: calc(90rem /16);
    }
}

.female-badge--right {
    right: 0;
}

.female-badge--left {
    left: 0;
}