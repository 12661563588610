/* consent overlay css */
.consent{
    background: var(--color-grey);
}
.consent__overlay{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: calc(15rem/16);
    font-size: var(--font-size-default);
}
