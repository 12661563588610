.custom-checkbox{
    cursor: pointer;
    display: inline-block;
    position: relative;
}

.custom-checkbox label{
    cursor: pointer;
}
.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}.custom-checkbox:after {
     clear: both;
 }
.custom-checkbox__input {
    position: absolute;
    width: calc(26rem/16);
    height: calc(26rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    position: relative;
    float: left;
    margin-right: calc(13rem/16);
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: calc(26rem/16);
    height: calc(26rem/16);
    margin-top: 0;
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    visibility: hidden;
    font-size: calc(13rem/16);
}

.custom-checkbox__input:checked ~ .custom-checkbox__box {
    background-color: var(--color-primary);
}

.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
}

.checkbox-list--wide-letter .custom-checkbox__text{
    letter-spacing: calc(3rem/16);
}

.custom-checkbox__text.custom-checkbox__text--light{
    font-family: var(--font-default);
    text-transform: none;
    font-size: calc(18rem/16);
    line-height: calc(22/18);
}

.checkbox-list{
    margin-top: calc(-12rem/16);
}

.checkbox-list>li{
    margin-top: calc(12rem/16);
}


.checkbox-list .list-inline-item:not(:last-child) {
    @media screen and (min-width: 768px){
        margin-right: calc(10rem/16);
    }
}

.checkbox-list .custom-checkbox__text .icon{
    position: relative;
    top: 2px;
    font-size: calc(18rem/16);
    margin-right: 5px;
}

.custom-image-checkbox__image{
    position: relative;
    display: flex;
    width: calc(80rem/16);
    height: calc(90rem/16);
    @media screen and (min-width: 768px){
       width: calc(114rem/16);
       height: calc(124rem/16);
    }
}

.custom-image-checkbox__circle{
    width: calc(46rem/16);
    height: calc(46rem/16);
    border-radius: 50%;
    background-color: var(--color-lightgrey);
    font-size: calc(40rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    @media screen and (min-width: 768px){
        width: calc(54rem/16);
        height: calc(54rem/16);
        font-size: calc(45rem/16);
    }

}

@media screen and (max-width: 767px){
    .custom-image-checkbox .custom-checkbox__box,
    .custom-image-checkbox .custom-checkbox__input{
        width: calc(20rem/16);
        height: calc(20rem/16);
    }

    .custom-image-checkbox .custom-checkbox__box{
        margin-right: calc(5rem/16);
        margin-top: 0;
    }

    .custom-image-checkbox .custom-checkbox__text{
        font-size: calc(15rem/16);
    }
}

.custom-checkbox__border-wrapper{
    padding-top: calc(31rem/16);
    margin-top: calc(31rem/16);
    border-top: solid calc(2rem/16) var(--color-lightgrey);
    @media screen and (min-width: 768px){
        padding-top: calc(61rem/16);
        margin-top: calc(61rem/16);
    }
}

.custom-checkbox__label-subtitle{
    font-family: var(--font-default);
    font-size: calc(16rem/16);
    line-height: calc(24/16);
    letter-spacing: calc(1rem/16);
    color: var(--color-grey);
    text-transform: none;
}